<template>
	<div class="clientInfo">
		<div v-if="flowSrc" style="position: fixed;width: 100vw;height: 100vh;
    background: #fff;z-index: 2000;justify-content: center;
    align-items: center;display: flex;">
			<iframe :src="flowSrc" style="height:90%; width:95%;margin: 0 auto;"> </iframe>
		</div>
		<el-dialog top='5vh' class='diytc' :visible.sync="minfo" width="95%">
			<div class="table-dialog-content">

				<div class="table-header clear">
					<p class="table-title">历史诉讼详情</p>
				</div>
				<div class="table table-body">
					<div class="tr">
						<div class="td">案件名称</div>
						<div class="td">{{ sfinfoobj.caseName }}</div>
					</div>
					<div class="tr">
						<div class="td">诉讼人类型</div>
						<div class="td">{{ sfinfoobj.litigationTypeName }}</div>
					</div>
					<div class="tr"
						v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2">
						<div class="td">案号</div>
						<div class="td">{{ sfinfoobj.caseNo }}</div>
					</div>

					<div class="tr">
						<div class="td">案件金额(万元)</div>
						<div class="td">{{ sfinfoobj.caseAmount }}</div>
					</div>
					<div class="tr">
						<div class="td">案件状态</div>
						<div class="td">{{ sfinfoobj.caseStatusName }}</div>
					</div>
					<div class="tr">
						<div class="td">案由</div>
						<div class="td">{{ fn(sfinfoobj.caseAction) }}</div>
					</div>
					<div class="tr">
						<div class="td">案件概述</div>
						<div class="td">{{ fn(sfinfoobj.remark) }}</div>
					</div>
					<div class="tr">
						<div class="td">客户解释</div>
						<div class="td">{{ fn(sfinfoobj.customerExplanation) }}</div>
					</div>
					<div class="tr"
						v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2">
						<div class="td">备注</div>
						<div class="td">{{ fn(sfinfoobj.remarks) }}</div>
					</div>
				</div>
			</div>



			<div style="padding: 10px;"></div>
		</el-dialog>
		<el-dialog top='5vh' class='diytc' :visible.sync="minfo1" width="95%">
			<div class="table-dialog-content diy">

				<div class="table-header clear">
					<p class="table-title">变更记录详情</p>
				</div>
				<div class="table table-body">
					<div class="tr">
						<div class="td">变更时间</div>
						<div class="td">{{ sfinfoobj1.changeDate }}</div>
					</div>
					<div class="tr">
						<div class="td">变更类型</div>
						<div class="td">{{ sfinfoobj1.changeTypeName }}</div>
					</div>
					<div class="tr">
						<div class="td">变更前内容</div>
						<div class="td" v-html="textcolor(sfinfoobj1.changeBeforeContent)"></div>
					</div>
					<div class="tr">
						<div class="td">变更后内容</div>
						<div class="td" v-html="textcolor(sfinfoobj1.changeAfterContent)"></div>
					</div>
					<div class="tr">
						<div class="td">备注</div>
						<div class="td" v-html="fn(sfinfoobj1.remark)"></div>
					</div>
				</div>
			</div>


			<div style="padding: 10px;"></div>
		</el-dialog>
		<el-dialog top='15vh' class='diytc' :visible.sync="qsjl" :width="mobileInfo ? '95%' : '65%'">

			<div class="tcinfobox">

				<div class="table-header clear">
					<p class="table-title" style="padding-left:0">当前欠税记录</p>
				</div>
				<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="swinfo.cur_owing_taxes_detail" border style="width: fit-content">
					<el-table-column :width="flexColumnWidth(swinfo.cur_owing_taxes_detail, 'levy_project_name', 380, 80)"
						prop="levy_project_name" label="征收项目"></el-table-column>
					<el-table-column width="100" prop="begin_date" label="所属时期起"></el-table-column>
					<el-table-column width="100" prop="end_date" label="所属时期止"></el-table-column>
					<el-table-column width="120" prop="supplement_tax_amount" label="应补税额"></el-table-column>
					<el-table-column width="120" prop="payment_limit_date" label="缴款期限"></el-table-column>
				</el-table>
			</div>


		</el-dialog>
		<el-dialog top='15vh' class='diytc' :visible.sync="swcfjl" :width="mobileInfo ? '95%' : '65%'">
			<div class="tcinfobox">
				<div class="table-header clear">
					<p class="table-title" style="padding-left:0">税务处罚记录</p>
				</div>
				<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="swinfo.tax_penalty" border style="width: fit-content">

					<el-table-column width="100" prop="lian_date" label="立案日期"></el-table-column>
					<el-table-column width="120" prop="status" label="违法违章状态"></el-table-column>
					<el-table-column width="120" prop="info" label="违法违章信息"></el-table-column>
					<el-table-column :width="flexColumnWidth(swinfo.tax_penalty, 'detail', 550, 80)" prop="detail"
						label="违法违章事实"></el-table-column>
				</el-table>
			</div>


		</el-dialog>
		<el-dialog top='15vh' class='diytc' :visible.sync="zljjl" :width="mobileInfo ? '95%' : '35%'">

			<div class="tcinfobox">

				<div class="table-header clear">
					<p class="table-title" style="padding-left:0">滞纳金记录</p>
				</div>
				<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="swinfo.overdue_fine" border style="width: fit-content">
					<el-table-column width="220" prop="tax_time" label="滞纳金时间"></el-table-column>
					<el-table-column width="140" prop="tax_paid" label="滞纳金金额（元）"></el-table-column>
				</el-table>
			</div>


		</el-dialog>
		<div id="inner" v-if="navList.length && mobileInfo" ref="myElement" class="sticky-element sticky"
			style="box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 12px 0px;background: #6ea1ff;">
			<div :class="{ 'activenavitem': index == navitemindex }" class="navitem" v-for="(item, index) in navList"
				:key="index" @click="scrollToPosition(index)">{{ item }}
			</div>
			<span @click="sfqFn"
				style="position: absolute;right: 0;left: 0;width: 80px;bottom: -25px;margin: auto;background: rgba(110, 161, 255, 0.6);color: #FFF;padding: 6px 25px;border-radius: 0px 0px 14px 14px;font-size: 13px;">
				<template v-if="zkinfo"><i style="transform: rotate(90deg);" class="el-icon-d-arrow-left"></i> 收起导航
				</template>
				<template v-else><i style="transform: rotate(-90deg);" class="el-icon-d-arrow-left"></i> 展开导航
				</template>
			</span>
		</div>
		<div v-if="mobileInfo" class="sticky-element" style="opacity: 0;">
			<div class="navitem" v-for="(item, index) in navList" :key="index" @click="scrollToPosition(index)">{{ item }}
			</div>
		</div>

		<div v-if="navList.length && !mobileInfo" class="pcsticky">
			<div :class="{ 'c': index == navitemindex }" v-for="(item, index) in navList" :key="index"
				@click="scrollToPosition(index)">{{ item }}
			</div>
		</div>

		<div ref="clientInfo" class="clientInfo-container">
			<img src="@/assets/yrylogo.png" alt="" />
			<div class="mediabox" v-if="!rtnCode">
				<el-card class="box-card"
					v-if="privacyType == 2 && allData.financeRequirementList[0] && allData.financeRequirementList[0].requirementType == 99">

					<div class="clientInfo-text" style="color:red">客户已向您开放联系方式，信息如下:</div>
					<el-form style="padding-left: 10px;" label-position="rigth" label-width="75px">


						<el-form-item class="textinfo" style="margin-bottom: 0;color:red" label="联系人:">

							<div style="margin-left: 10px;" class="info1">
								{{
			allData.clientContactsList[0]
				.clientContactsName
		}}
							</div>
						</el-form-item>
						<el-form-item class="textinfo" style="margin-bottom: 0" label="联系电话:">

							<div style="margin-left: 10px;color:red" class="info1">
								{{
				allData.clientContactsList[0]
					.mobilePhone
			}}
							</div>


						</el-form-item>
					</el-form>

				</el-card>

				<div class="clientInfo-text">客户信息</div>
				<div class="edit" style="padding: 5px; width: 100%">
					<el-card v-if="isData" class="box-card">
						<p style="
										color: #3e31ff;
										font-weight: 800;
										margin: 10px 0;
										font-size: 20px;
									" v-if="allData.clientBase &&
			allData.clientBase.clientName
			">
							{{
			allData.clientPresidentRelatedVO
				.whetherSensitive
				? allData.clientPresidentRelatedVO
					.whetherSensitive == 1
					? allData.clientBase.pdfClientName
					: allData.clientBase.clientName
				: allData.clientBase.pdfClientName
		}}
						</p>
						<div v-if="PresidentCooperateWillingness.length &&
			allData.financeRequirementList &&
			allData.financeRequirementList.length
			">
							<el-form v-if="mobileInfo" label-position="rigth" label-width="75px">
								<el-form-item class="textinfo" style="margin-bottom: 0" label="客户简介:" v-if="allData.financeRequirementList[0]
			.recommendedzReason
			">
									<div style="margin-left: 10px;" class="info1">
										{{
			allData.financeRequirementList[0]
				.recommendedzReason
		}}
									</div>
								</el-form-item>
								<el-form-item class="textinfo" style="margin-bottom: 0" v-if="allData.financeRequirementList[0]
				.clientRequirement
			" label="客户需求:">

									<div style="margin-left: 10px;" class="info1">
										{{
			allData.financeRequirementList[0]
				.clientRequirement
		}}
									</div>

								</el-form-item>
								<el-form-item class="textinfo" style="margin-bottom: 0" v-if="allData.financeRequirementList[0]
				.noticeMatter
			" label="注意事项:">

									<div style="margin-left: 10px;" class="info1">
										{{
			allData.financeRequirementList[0]
				.noticeMatter
		}}
									</div>
								</el-form-item>
								<el-form-item class="textinfo" style="margin-bottom: 0" v-if="allData.financeRequirementList[0]
				.askQuestions
			" label="咨询问题:">

									<div style="margin-left: 10px;" class="info1">
										{{
			allData.financeRequirementList[0]
				.askQuestions
		}}
									</div>


								</el-form-item>
								<el-form-item class="textinfo" style="margin-bottom: 0" v-if="allData.financeRequirementList[0].remark
			" label="备注:">

									<div style="margin-left: 10px;" class="info1">
										{{
			allData.financeRequirementList[0]
				.remark
		}}
									</div>

								</el-form-item>
								<el-form-item v-if="allData.financeRequirementList[0]
				.inquiryAttachment &&
			allData.financeRequirementList[0]
				.inquiryAttachment.length
			" label="附件:">
									<template>
										<div v-for="(
														urlitem1, urlindex1
													) in JSON.parse(
			allData
				.financeRequirementList[0]
				.inquiryAttachment
		)" :key="urlindex1">
											<div style="color: blue;cursor: pointer;" @click="handelOnChange(urlitem1)">{{
			urlitem1.fileName }}</div>
										</div>

									</template>
								</el-form-item>
							</el-form>
							<el-form v-else label-position="rigth" label-width="75px">
								<el-form-item style="margin-bottom: 0" label="客户简介:" v-if="allData.financeRequirementList[0]
			.recommendedzReason
			">
									<div class="w65">{{
			allData.financeRequirementList[0]
				.recommendedzReason
		}}</div>
								</el-form-item>
								<el-form-item style="margin-bottom: 0" v-if="allData.financeRequirementList[0]
				.clientRequirement
			" label="客户需求:">
									<div class="w65">{{
			allData.financeRequirementList[0]
				.clientRequirement
		}}</div>
								</el-form-item>
								<el-form-item style="margin-bottom: 0" v-if="allData.financeRequirementList[0]
				.noticeMatter
			" label="注意事项:">
									<div class="w65">{{
			allData.financeRequirementList[0]
				.noticeMatter
		}}</div>
								</el-form-item>
								<el-form-item style="margin-bottom: 0" v-if="allData.financeRequirementList[0]
				.askQuestions
			" label="咨询问题:">
									<div class="w65">{{
			allData.financeRequirementList[0]
				.askQuestions
		}}</div>
								</el-form-item>
								<el-form-item style="margin-bottom: 0" v-if="allData.financeRequirementList[0].remark
			" label="备注:">
									<div class="w65">{{
			allData.financeRequirementList[0].remark
		}}</div>
								</el-form-item>
								<el-form-item v-if="allData.financeRequirementList[0]
				.inquiryAttachment &&
			allData.financeRequirementList[0]
				.inquiryAttachment.length
			" label="附件:">
									<template>
										<div v-for="(
														urlitem1, urlindex1
													) in JSON.parse(
			allData
				.financeRequirementList[0]
				.inquiryAttachment
		)" :key="urlindex1">
											<div style="color: blue;cursor: pointer;" @click="handelOnChange(urlitem1)">{{
			urlitem1.fileName }}</div>
										</div>

									</template>
								</el-form-item>
							</el-form>
						</div>
						<el-form label-position="left" class="form1" v-if="allData.financeRequirementList">
							<p class="t1" style="
											color: #3e31ff;
											font-weight: 800;
											margin: 10px 0;
											font-size: 20px;
										" v-if="allData.financeRequirementList.length &&
			allData.checkedTitleField.includes(
				'financeRequirement'
			)
			">
								<span class="t1-1">1.</span> <span class="roll">融资需求</span>
							</p>
							<template v-if="allData.financeRequirementList &&
			allData.checkedTitleField.includes(
				'financeRequirement'
			)
			">
								<div v-for="(
												item, index
											) in allData.financeRequirementList" :key="index">
									<p class="t2" v-if="allData.financeRequirementList
			.length > 1
			">
										<span class="t1-1">1.{{ index + 1 }}.</span>
										融资需求
										{{ index + 1 }}
									</p>
									<p class="t3">
										<span class="t1-1-1">{{
			allData.financeRequirementList
				.length > 1
				? '1.'
				: ''
		}}{{ index + 1 }}.1.</span>
										基础信息
									</p>
									<el-form-item label-width="100px" v-if="item.loanMainstay" label="贷款主体: ">
										{{ item.loanMainstay }}</el-form-item>
									<div v-if="mobileInfo">
										<el-form-item label-width="100px"
											v-if="item.minExpectRate || item.maxExpectRate || item.requirementRateSelectName"
											label="期望利率(%): ">
											<span v-if="item.minExpectRate">
												{{ item.minExpectRate }}
											</span>
											<span v-if="item.minExpectRate && item.minExpectRate">
												至
											</span>
											<span v-if="item.maxExpectRate">
												{{
			item.maxExpectRate
		}}
											</span>
											{{ item.requirementRateSelectName }}
										</el-form-item>
										<el-form-item label-width="100px" v-if="item.minFinancing || item.requirementAmountSelectName"
											label="需求金额: ">
											<span v-if="item.minFinancing">
												{{ item.minFinancing }} 万元
											</span>
											<span v-if="item.minFinancing && item.maxFinancing">
												至
											</span>
											<span v-if="item.maxFinancing">
												{{
			item.maxFinancing
		}}万元
											</span>
											{{ item.requirementAmountSelectName }}


										</el-form-item>
										<el-form-item label-width="100px" v-if="item.loanTypeName" label="有无抵押物: ">
											{{ item.loanTypeName }}</el-form-item>
										<el-form-item label-width="100px" v-if="item.minLoanTerm || item.requirementTermSelectName"
											label="贷款期限(月): ">
											<span v-if="item.minLoanTerm">{{ item.minLoanTerm }}至{{
			item.maxLoanTerm
		}} </span> {{ item.requirementTermSelectName }}</el-form-item>

									</div>
									<div v-else class="running-info">
										<el-form-item label-width="100px" v-if="item.maxExpectRate || item.requirementRateSelectName"
											label="期望利率(%): ">
											<span v-if="item.minExpectRate">
												{{ item.minExpectRate }}
											</span>
											<span v-if="item.minExpectRate && item.minExpectRate">
												至
											</span>
											<span v-if="item.maxExpectRate">
												{{
			item.maxExpectRate
		}}
											</span>
											{{ item.requirementRateSelectName }}
										</el-form-item>
										<el-form-item label-width="100px" v-if="item.minFinancing || item.requirementAmountSelectName"
											label="需求金额: ">

											<span v-if="item.minFinancing">
												{{ item.minFinancing }}万元
											</span>
											<span v-if="item.minFinancing && item.maxFinancing">
												至
											</span>
											<span v-if="item.maxFinancing">
												{{
			item.maxFinancing
		}}万元
											</span>
											{{ item.requirementAmountSelectName }}

										</el-form-item>
										<el-form-item label-width="100px" v-if="item.loanTypeName" label="有无抵押物: ">
											{{ item.loanTypeName }}</el-form-item>
										<el-form-item label-width="100px" v-if="item.minLoanTerm || item.requirementTermSelectName"
											label="贷款期限(月): ">
											<span v-if="item.minLoanTerm">{{ item.minLoanTerm }}至{{
			item.maxLoanTerm
		}} </span> {{ item.requirementTermSelectName }}</el-form-item>

									</div>
									<el-form-item label-width="100px" v-if="item.paymentPurpose" label="贷款用途: ">
										{{ item.paymentPurpose }}</el-form-item>
									<div v-if="mobileInfo">
										<el-form-item class="textinfo" label-width="100px" v-if="item.otherRemark" label="补充说明: ">
											<div class="info1" v-html="fn(item.otherRemark || '')"></div>
										</el-form-item>
										<el-form-item v-if="item.availableBankBusinessName" label="可提供的银行价值业务: ">
											{{
			item.availableBankBusinessName
		}}</el-form-item>
										<el-form-item v-if="item.availableOtherControlMeasures
			" label="可提供的其他风控措施: ">
											<div class="info1" v-html="fn(
			item.availableOtherControlMeasures ||
			''
		)
			"></div>
										</el-form-item>

									</div>
									<div v-else>
										<el-form-item label-width="100px" v-if="item.otherRemark" label="补充说明: ">
											<div class=" w65" v-html="fn(item.otherRemark || '')"></div>
										</el-form-item>
										<el-form-item label-width="160px" v-if="item.availableBankBusinessName" label="可提供的银行价值业务: ">
											{{
			item.availableBankBusinessName
		}}</el-form-item>
										<el-form-item label-width="160px" v-if="item.availableOtherControlMeasures
			" label="可提供的其他风控措施: ">
											<div class=" w65" v-html="fn(
			item.availableOtherControlMeasures ||
			''
		)
			"></div>
										</el-form-item>

									</div>
									<el-form-item label-width="100px" v-if="item.remarks &&
			privacyType == 2" label="备注: ">
										<div class=" w65" v-html="fn(item.remarks || '')"></div>
									</el-form-item>
									<el-form-item label-width="73px" v-if="(item.reqRelevantAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'financeRequirement_reqRelevantAttachment'
			) &&
				privacyType == 1)
			" label="附件:">
										<el-upload style="
														line-height: 0;
														
													" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="item.reqRelevantAttachment
			">
										</el-upload>
									</el-form-item>
									<p class="t3" v-if="item.personalGuaranteeChecked ||
			item.companyGuaranteeChecked ||
			item.powerPledgeChecked ||
			item.equipmentMortgageChecked ||
			(item.otherChecked && privacyType == 2) ||
			item.houseMortgageChecked
			">
										<span class="t1-1-1">{{
			allData.financeRequirementList
				.length > 1
				? '1.'
				: ''
		}}{{ index + 1 }}.2.</span>担保方式
									</p>
									<template v-if="mobileInfo">
										<template v-if="item.houseMortgageChecked">
											<div style="margin-left: 10px" v-for="(
														hitem, hindex
													) in item.houseMortgageList" :key="'h' + hindex">
												<p class="t2">
													房产抵押
													<span v-if="item.houseMortgageList
			.length > 1
			">{{ hindex + 1 }}</span>
												</p>
												<div class="running-info" v-if="item.houseMortgageChecked">
													<el-form-item v-if="hitem.assetName" label="资产名称: ">
														{{
			hitem.assetName
		}}</el-form-item>


													<el-form-item v-if="hitem.borrowerRelationshipHouseMortgageName
			">
														<span style="
																	font-weight: 400;
																	position: relative;
																	z-index: 1;
																" slot="label">与借款人关系
															<el-tooltip effect="dark" placement="right">

																<span v-html="'1类关系人</br>&nbsp;&nbsp;借款企业本身、借款人本身、法人、股东、以上自然人的直系亲属</br>2类关系人</br>'"
																	slot="content"></span>



																<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
														{{
			hitem.borrowerRelationshipHouseMortgageName
		}}
													</el-form-item>

													<div style="display: flex;">
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.realEstateTypesName
			" label="不动产类型: ">
															{{
			hitem.realEstateTypesName
		}}</el-form-item>
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.measureArea" label="面积(㎡): ">
															{{
			hitem.measureArea
		}}</el-form-item>
													</div>
													<div style="display: flex;">
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.marketReferencePrice
			" label="市场参考价: ">
															{{
			hitem.marketReferencePrice
		}}万元</el-form-item>
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.guidancePrice" label="指导价: ">
															{{
			hitem.guidancePrice
		}}万元</el-form-item>
													</div>
													<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.propertyOwner" label="权利人份额: ">
														{{
			hitem.propertyOwner
		}}</el-form-item>
													<div style="display: flex;">

														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.housePropertyCardDate
			" label="房产证日期: ">
															{{
			hitem.housePropertyCardDate
		}}</el-form-item>
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.usefulLife" label="使用期限: ">
															{{
			hitem.usefulLife
		}}年</el-form-item>
													</div>
													<el-form-item label-width="86px" v-if="hitem.loanBank" label="贷款银行: ">
														{{
			hitem.loanBank
		}}</el-form-item>
													<div style="display: flex;">
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.loanBalance" label="贷款余额: ">
															{{
			hitem.loanBalance
		}}万元</el-form-item>
														<el-form-item label-width="86px" style="flex: 1;" v-if="hitem.lendingRate" label="贷款利率: ">
															{{
			hitem.lendingRate
		}}%</el-form-item>

													</div>


													<el-form-item class="textinfo" label-width="86px" v-if="hitem.remarks
			&& allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="备注: ">
														<div class="info1" v-html="fn(
			hitem.remarks ||
			''
		)
			"></div>
													</el-form-item>
													<el-form-item style="width: 100%;" label-width="86px"
														v-if="privacyType == 2 && hitem.assetAttachment" label="附件:">
														<template>
															<div v-for="(
																urlitem, urlindexs
															) in JSON.parse(hitem.assetAttachment)" :key="urlindexs" style="color: blue;cursor: pointer;"
																@click="handelOnChange(urlitem)">{{
			urlitem.fileName }}</div>
														</template>
													</el-form-item>
													<el-table v-if="hitem.personalAssetsMortgateAssessmentStr.length && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="JSON.parse(hitem.personalAssetsMortgateAssessmentStr)" border style="width: fit-content">
														<el-table-column
															:width="flexColumnWidth(JSON.parse(hitem.personalAssetsMortgateAssessmentStr), 'assessCompanyName', 220, 80)"
															prop="assessCompanyName" label="评估公司">
														</el-table-column>
														<el-table-column
															:width="flexColumnWidth(JSON.parse(hitem.personalAssetsMortgateAssessmentStr), 'financialOrganizationsName', 200, 80)"
															prop="financialOrganizationsName" label="评估银行">
														</el-table-column>
														<el-table-column width="120" prop="totalValuation" label="评估总值(万元)">
														</el-table-column>

														<el-table-column width="130" prop="netValuation1" label="评估净值1(万元)">
														</el-table-column>
														<el-table-column width="130" prop="netValuation2" label="评估净值2(万元)">
														</el-table-column>
													</el-table>

												</div>
											</div>
										</template>
										<template v-if="item.personalGuaranteeChecked">
											<div style="margin-left: 10px" v-for="(
														pitem, pindex
													) in JSON.parse(
			item.personalGuaranteeStr
		)" :key="'p' + pindex">
												<p class="t2">
													个人保证
													<span v-if="hideIndex(
			item.personalGuaranteeStr
		)
			">{{ pindex + 1 }}</span>
												</p>
												<div v-if="item.personalGuaranteeChecked
			">
													<div style="display: flex;">
														<el-form-item style="flex: 0.4;" v-if="pitem.namePersonalGuarantee
			" label="姓名: ">{{
			pitem.namePersonalGuarantee
		}}
														</el-form-item>
														<el-form-item style="flex: 0.6;" v-if="pitem.borrowerRelationshipPersonalGuaranteeName
			" label="与借款人关系: ">
															{{
			pitem.borrowerRelationshipPersonalGuaranteeName
		}}</el-form-item>
													</div>
													<el-form-item v-if="pitem.guaranteeAnalysisPersonalGuarantee
			" label="担保能力分析: ">
														<div class="info1" v-html="fn(
			pitem.guaranteeAnalysisPersonalGuarantee ||
			''
		)
			"></div>
													</el-form-item>
												</div>
											</div>
										</template>
										<template v-if="item.companyGuaranteeChecked">
											<div style="margin-left: 10px" v-for="(
														citem, cindex
													) in JSON.parse(
			item.companyGuaranteeStr
		)" :key="'c' + cindex">
												<p class="t2">
													公司保证
													<span v-if="hideIndex(
			item.companyGuaranteeStr
		)
			">{{ cindex + 1 }}</span>
												</p>
												<div v-if="item.companyGuaranteeChecked
			">
													<el-form-item label-width="100px" v-if="citem.nameCompanyGuarantee
			" label="公司名称: ">
														{{
			citem.nameCompanyGuarantee
		}}</el-form-item>
													<el-form-item label-width="100px" v-if="citem.numberCompanyGuarantee
			" label="公司信用代码: ">
														{{
			citem.numberCompanyGuarantee
		}}</el-form-item>
													<el-form-item label-width="100px" v-if="citem.borrowerRelationshipCompanyGuaranteeName
			" label="与借款人关系: ">
														{{
			citem.borrowerRelationshipCompanyGuaranteeName
		}}</el-form-item>
													<el-form-item class="textinfo" label-width="100px" v-if="item.guaranteeAnalysisCompanyGuarantee
			" label="担保能力分析: ">
														<div class="info1" v-html="fn(
			item.guaranteeAnalysisCompanyGuarantee ||
			''
		)
			"></div>
													</el-form-item>
												</div>
											</div>
										</template>
										<template v-if="item.powerPledgeChecked">
											<div style="margin-left: 10px" v-for="(
														psitem, pcindex
													) in JSON.parse(
			item.powerPledgeStr
		)" :key="'pc' + pcindex">
												<p class="t2">
													权利质押
													<span v-if="hideIndex(
			item.powerPledgeStr
		)
			">{{ pcindex + 1 }}</span>
												</p>
												<div v-if="item.powerPledgeChecked">
													<el-form-item class="textinfo" label-width="75px" v-if="psitem.powerContentPowerPledge
			" label="权利内容: ">
														<div class="info1" v-html="fn(
			psitem.powerContentPowerPledge ||
			''
		)
			"></div>
													</el-form-item>

													<el-form-item class="textinfo" label-width="75px" v-if="psitem.remarkPowerPledge
			" label="备注: ">
														<div class="info1" v-html="fn(
			psitem.remarkPowerPledge ||
			''
		)
			"></div>
													</el-form-item>
												</div>
											</div>
										</template>
										<template v-if="item.equipmentMortgageChecked">
											<div style="margin-left: 10px" v-for="(
														eitem, eindex
													) in JSON.parse(
			item.equipmentMortgageStr
		)" :key="'e' + eindex">
												<p class="t2">
													设备抵押
													<span v-if="hideIndex(
			item.equipmentMortgageStr
		)
			">{{ eindex + 1 }}</span>
												</p>
												<el-form-item class="textinfo" label-width="75px" v-if="eitem.deviceInformationEquipmentMortgage
			" label="设备信息: ">
													<div class="info1" v-html="fn(
			eitem.deviceInformationEquipmentMortgage ||
			''
		)
			"></div>
												</el-form-item>

												<el-form-item class="textinfo" label-width="75px" v-if="eitem.remarkEquipmentMortgage
			" label="备注: ">
													<div class="info1" v-html="fn(
			eitem.remarkEquipmentMortgage ||
			''
		)
			"></div>
												</el-form-item>
											</div>
										</template>
										<template v-if="item.otherChecked">
											<div style="margin-left: 10px" v-for="(
															oitem, oindex
														) in JSON.parse(item.othersStr)" :key="'o' + oindex">
												<p class="t2">其他</p>
												<el-form-item v-if="oitem.otherDescOthers" label="其他描述: ">
													<div class="info1" v-html="fn(
			oitem.otherDescOthers ||
			''
		)
			"></div>
												</el-form-item>

												<el-form-item v-if="oitem.remarkOthers" label="备注: ">
													<div class="info1" v-html="fn(
			oitem.remarkOthers ||
			''
		)
			"></div>
												</el-form-item>
											</div>
										</template>
									</template>
									<template v-else>

										<template v-if="item.houseMortgageChecked">
											<div style="margin-left: 30px" v-for="(
														hitem, hindex
													) in item.houseMortgageList" :key="'h' + hindex">
												<p class="t2">
													房产抵押
													<span v-if="item.houseMortgageList
			.length > 1
			">{{ hindex + 1 }}</span>
												</p>
												<div class="running-info" v-if="item.houseMortgageChecked">
													<el-form-item label-width="125px" v-if="hitem.assetName" label="资产名称: ">
														{{
			hitem.assetName
		}}</el-form-item>

													<el-form-item label-width="125px" v-if="hitem.borrowerRelationshipHouseMortgageName
			">
														<span style="
																	font-weight: 400;
																	position: relative;
																	z-index: 1;
																" slot="label">与借款人关系
															<el-tooltip effect="dark" placement="right">

																<span v-html="'1类关系人</br>&nbsp;&nbsp;借款企业本身、借款人本身、法人、股东、以上自然人的直系亲属</br>2类关系人</br>'"
																	slot="content"></span>



																<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
														{{
			hitem.borrowerRelationshipHouseMortgageName
		}}
													</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.realEstateTypesName
			" label="不动产类型: ">
														{{
			hitem.realEstateTypesName
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.measureArea" label="面积(㎡): ">
														{{
			hitem.measureArea
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.marketReferencePrice
			" label="市场参考价(万元): ">
														{{
			hitem.marketReferencePrice
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.guidancePrice" label="指导价(万元): ">
														{{
			hitem.guidancePrice
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.propertyOwner" label="权利人(份额): ">
														{{
			hitem.propertyOwner
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.housePropertyCardDate
			" label="房产证日期: ">
														{{
			hitem.housePropertyCardDate
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.usefulLife" label="使用期限(年): ">
														{{
			hitem.usefulLife
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.loanBank" label="贷款银行: ">
														{{
			hitem.loanBank
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.loanBalance" label="贷款余额(万元): ">
														{{
			hitem.loanBalance
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.lendingRate" label="贷款利率(%): ">
														{{
			hitem.lendingRate
		}}</el-form-item>
													<el-form-item label-width="125px" v-if="hitem.assetStatusName" label="资产状况: ">
														{{
			hitem.assetStatusName
		}}</el-form-item>

												</div>
												<el-form-item label-width="125px" v-if="hitem.remarks && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2
			" label="备注: ">
													<div class=" w65" v-html="fn(
			hitem.remarks ||
			''
		)
			"></div>
												</el-form-item>
												<el-form-item style="width: 100%;" label-width="125px"
													v-if="privacyType == 2 && hitem.assetAttachment" label="附件:">
													<template>
														<div v-for="(
																urlitem, urlindexs
															) in JSON.parse(hitem.assetAttachment)" :key="urlindexs" style="color: blue;cursor: pointer;"
															@click="handelOnChange(urlitem)">{{
			urlitem.fileName }}</div>
													</template>
												</el-form-item>

												<el-table v-if="hitem.personalAssetsMortgateAssessmentStr.length && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="JSON.parse(hitem.personalAssetsMortgateAssessmentStr)" border
													style="    margin-left: 10px;width: fit-content">
													<el-table-column
														:width="flexColumnWidth(JSON.parse(hitem.personalAssetsMortgateAssessmentStr), 'assessCompanyName', 220, 80)"
														prop="assessCompanyName" label="评估公司">
													</el-table-column>
													<el-table-column
														:width="flexColumnWidth(JSON.parse(hitem.personalAssetsMortgateAssessmentStr), 'financialOrganizationsName', 200, 80)"
														prop="financialOrganizationsName" label="评估银行">
													</el-table-column>
													<el-table-column width="120" prop="totalValuation" label="评估总值(万元)">
													</el-table-column>

													<el-table-column width="130" prop="netValuation1" label="评估净值1(万元)">
													</el-table-column>
													<el-table-column width="130" prop="netValuation2" label="评估净值2(万元)">
													</el-table-column>
												</el-table>
											</div>
										</template>
										<template v-if="item.personalGuaranteeChecked">
											<div style="margin-left: 30px" v-for="(
														pitem, pindex
													) in JSON.parse(
			item.personalGuaranteeStr
		)" :key="'p' + pindex">
												<p class="t2">
													个人保证
													<span v-if="hideIndex(
			item.personalGuaranteeStr
		)
			">{{ pindex + 1 }}</span>
												</p>
												<div v-if="item.personalGuaranteeChecked
			">
													<div style="display: flex;">
														<el-form-item label-width="100px" style="flex: 0.337;" v-if="pitem.namePersonalGuarantee
			" label="姓名: ">{{
			pitem.namePersonalGuarantee
		}}
														</el-form-item>
														<el-form-item style="flex: 0.6;" v-if="pitem.borrowerRelationshipPersonalGuaranteeName
			" label="与借款人关系: ">
															{{
			pitem.borrowerRelationshipPersonalGuaranteeName
		}}</el-form-item>
													</div>
													<el-form-item label-width="100px" v-if="pitem.guaranteeAnalysisPersonalGuarantee
			" label="担保能力分析: ">
														<div class=" w65" v-html="fn(
			pitem.guaranteeAnalysisPersonalGuarantee ||
			''
		)
			"></div>
													</el-form-item>
												</div>
											</div>
										</template>
										<template v-if="item.companyGuaranteeChecked">
											<div style="margin-left: 30px" v-for="(
														citem, cindex
													) in JSON.parse(
			item.companyGuaranteeStr
		)" :key="'c' + cindex">
												<p class="t2">
													公司保证
													<span v-if="hideIndex(
			item.companyGuaranteeStr
		)
			">{{ cindex + 1 }}</span>
												</p>
												<div v-if="item.companyGuaranteeChecked
			">
													<el-form-item label-width="110px" v-if="citem.nameCompanyGuarantee
			" label="公司名称: ">
														{{
			citem.nameCompanyGuarantee
		}}</el-form-item>
													<el-form-item label-width="110px" v-if="citem.numberCompanyGuarantee
			" label="公司信用代码: ">
														{{
			citem.numberCompanyGuarantee
		}}</el-form-item>
													<el-form-item label-width="110px" v-if="citem.borrowerRelationshipCompanyGuaranteeName
			" label="与借款人关系: ">
														{{
			citem.borrowerRelationshipCompanyGuaranteeName
		}}</el-form-item>
													<el-form-item label-width="110px" v-if="item.guaranteeAnalysisCompanyGuarantee
			" label="担保能力分析: ">
														<div class=" w65" v-html="fn(
			item.guaranteeAnalysisCompanyGuarantee ||
			''
		)
			"></div>
													</el-form-item>
												</div>
											</div>
										</template>
										<template v-if="item.powerPledgeChecked">
											<div style="margin-left: 30px" v-for="(
														psitem, pcindex
													) in JSON.parse(
			item.powerPledgeStr
		)" :key="'pc' + pcindex">
												<p class="t2">
													权利质押
													<span v-if="hideIndex(
			item.powerPledgeStr
		)
			">{{ pcindex + 1 }}</span>
												</p>
												<div v-if="item.powerPledgeChecked">
													<el-form-item label-width="86px" v-if="psitem.powerContentPowerPledge
			" label="权利内容: ">
														<div class=" w65" v-html="fn(
			psitem.powerContentPowerPledge ||
			''
		)
			"></div>
													</el-form-item>

													<el-form-item label-width="86px" v-if="psitem.remarkPowerPledge
			" label="备注: ">
														<div class=" w65" v-html="fn(
			psitem.remarkPowerPledge ||
			''
		)
			"></div>
													</el-form-item>
												</div>
											</div>
										</template>
										<template v-if="item.equipmentMortgageChecked">
											<div style="margin-left: 30px" v-for="(
														eitem, eindex
													) in JSON.parse(
			item.equipmentMortgageStr
		)" :key="'e' + eindex">
												<p class="t2">
													设备抵押
													<span v-if="hideIndex(
			item.equipmentMortgageStr
		)
			">{{ eindex + 1 }}</span>
												</p>
												<el-form-item label-width="86px" v-if="eitem.deviceInformationEquipmentMortgage
			" label="设备信息: ">
													<div class=" w65" v-html="fn(
			eitem.deviceInformationEquipmentMortgage ||
			''
		)
			"></div>
												</el-form-item>

												<el-form-item label-width="86px" v-if="eitem.remarkEquipmentMortgage
			" label="备注: ">
													<div class=" w65" v-html="fn(
			eitem.remarkEquipmentMortgage ||
			''
		)
			"></div>
												</el-form-item>
											</div>
										</template>
										<template v-if="item.otherChecked && privacyType == 2">
											<div style="margin-left: 30px" v-for="(
														oitem, oindex
													) in JSON.parse(item.othersStr)" :key="'o' + oindex">
												<p class="t2">其他</p>
												<el-form-item label-width="86px" v-if="oitem.otherDescOthers" label="其他描述: ">
													<div class=" w65" v-html="fn(
			oitem.otherDescOthers ||
			''
		)
			"></div>
												</el-form-item>

												<el-form-item label-width="86px" v-if="oitem.remarkOthers" label="备注: ">
													<div class=" w65" v-html="fn(
			oitem.remarkOthers ||
			''
		)
			"></div>
												</el-form-item>
											</div>
										</template>
									</template>

								</div>
							</template>

							<template v-if="allData.checkedTitleField.includes(
			'clientBase'
		)
			">
								<p class="t1  c-1">
									<span class="t1-2">2.</span> <span class="roll">客户基本信息</span>
								</p>
								<template v-if="allData.checkedTitleField.includes(
			'clientBase_businessInfo'
		)
			">
									<p class="t2  c-1-1">
										<span class="t1-2">2.1.</span> 工商信息
									</p>
									<div class="c-1-1-body" v-if="mobileInfo">
										<div style="display: flex;">

											<el-form-item label-width="72px" style="flex:1" v-if="allData.clientBase.companyNatureName
			" label="企业性质: ">{{
			allData.clientBase &&
			allData.clientBase.companyNatureName
		}}
											</el-form-item>

											<el-form-item label-width="72px" style="flex:1" v-if="allData.clientBase.businessModelName
			" label="商业模式: ">{{
			allData.clientBase &&
			allData.clientBase.businessModelName
		}}
											</el-form-item>
										</div>

										<el-form-item label-width="72px" v-if="allData.clientBase.hasOwnProperty(
				'registeredCapital'
			)
			" label="注册资本: ">{{
			allData.clientBase &&
			allData.clientBase.registeredCapital
		}}万元
										</el-form-item>

										<el-form-item label-width="72px" v-if="allData.clientBase
				.belongIndustryName
			" label="所属行业: ">{{
			allData.clientBase &&
			allData.clientBase
				.belongIndustryName
		}}
										</el-form-item>

										<div style="display: flex;">

											<el-form-item label-width="72px" style="flex: 1;" v-if="allData.clientBase.startDate"
												label="成立日期: ">{{
			allData.clientBase &&
			allData.clientBase.startDate
		}}
											</el-form-item>


											<el-form-item label-width="72px" style="flex: 1;" v-if="allData.otherKeyInfo.hasOwnProperty(
				'employeeNumber'
			)
			" label="社保人数: ">
												{{
			allData.otherKeyInfo.employeeNumber
		}}人
											</el-form-item>
										</div>



										<el-form-item label-width="72px" v-if="allData.clientBase &&
			allData.clientBase.registeredAddress && privacyType == 2" label="注册地址: ">{{

			allData.clientBase.registeredAddress
		}}
										</el-form-item>

										<el-form-item label-width="72px" v-if="allData.clientBase &&
			allData.clientBase.companyUrl && privacyType == 2" label="官网: ">{{
			allData.clientBase.companyUrl
		}}
										</el-form-item>
										<el-form-item label-width="72px" v-if="allData.clientBase.staffSize" label="人员规模: ">{{
			allData.clientBase &&
			allData.clientBase.staffSize
		}}
										</el-form-item>


										<el-form-item label-width="72px" v-if="allData.clientBase.capitalMarketName
			" label="资本市场状况: ">{{
			allData.clientBase &&
			allData.clientBase.capitalMarketName
		}}
										</el-form-item>
										<el-form-item class="textinfo" label-width="72px" v-if="allData.clientBase.businessScope"
											label="经营范围: ">
											<div class="info1">{{
			allData.clientBase &&
			allData.clientBase.businessScope
		}}</div>

										</el-form-item>
										<el-form-item class="textinfo" label-width="72px" v-if="allData.clientBusinessVO
				.productIntroduction
			" label="主营业务: ">
											<div class="info1">{{
			allData.clientBase &&
			allData.clientBusinessVO
				.productIntroduction
		}}</div>
										</el-form-item>
										<el-form-item class='textinfo' label-width="72px" v-if="allData.clientBase.remarks && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="备注: ">
											<div class="info1 w65" v-html="fn(allData.clientBase.remarks || '')"></div>
										</el-form-item>
										<el-form-item v-if="allData.clientBase &&
			allData.clientBase
				.capitalizeAttachment &&
			privacyType == 2
			" label="附件:">
											<el-upload disabled style="
														line-height: 0;
														margin-left: 50px;
													" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.capitalizeAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="allData.clientBase
			.businessInformationAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2
			" label="营业执照附件:">
											<el-upload disabled style="
														line-height: 0;
														margin-left: 90px;
													" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.businessInformationAttachment
			">
											</el-upload>
										</el-form-item>
									</div>
									<div class="c-1-1-body" v-else>
										<div class="running-info">

											<el-form-item label-width="72px" v-if="allData.clientBase.companyNatureName
			" label="企业性质: ">{{
			allData.clientBase &&
			allData.clientBase.companyNatureName
		}}
											</el-form-item>

											<el-form-item label-width="72px" v-if="allData.clientBase.businessModelName
			" label="商业模式: ">{{
			allData.clientBase &&
			allData.clientBase.businessModelName
		}}
											</el-form-item>

											<el-form-item label-width="72px" v-if="allData.clientBase.hasOwnProperty(
				'registeredCapital'
			)
			" label="注册资本: ">{{
			allData.clientBase &&
			allData.clientBase.registeredCapital
		}}万元
											</el-form-item>

											<el-form-item label-width="72px" v-if="allData.clientBase
				.belongIndustryName
			" label="所属行业: ">{{
			allData.clientBase &&
			allData.clientBase
				.belongIndustryName
		}}
											</el-form-item>


											<el-form-item label-width="72px" v-if="allData.clientBase.startDate" label="成立日期: ">{{
			allData.clientBase &&
			allData.clientBase.startDate
		}}
											</el-form-item>
											<el-form-item label-width="72px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'employeeNumber'
			)
			" label="社保人数: ">
												{{
			allData.otherKeyInfo.employeeNumber
		}}人
											</el-form-item>


											<el-form-item label-width="72px" v-if="allData.clientBase &&
			allData.clientBase.registeredAddress && privacyType == 2" label="注册地址: ">{{

			allData.clientBase.registeredAddress
		}}
											</el-form-item>

											<el-form-item label-width="72px" v-if="allData.clientBase &&
			allData.clientBase.companyUrl && privacyType == 2" label="官网: ">{{
			allData.clientBase.companyUrl
		}}
											</el-form-item>
											<el-form-item label-width="72px" v-if="allData.clientBase.staffSize" label="人员规模: ">{{
			allData.clientBase &&
			allData.clientBase.staffSize
		}}
											</el-form-item>


											<el-form-item label-width="72px" v-if="allData.clientBase.capitalMarketName
			" label="资本市场状况: ">{{
			allData.clientBase &&
			allData.clientBase.capitalMarketName
		}}
											</el-form-item>

										</div>
										<el-form-item label-width="72px" v-if="allData.clientBase.businessScope" label="经营范围: ">
											<div class=" w65">{{
			allData.clientBase &&
			allData.clientBase.businessScope
		}}</div>

										</el-form-item>
										<el-form-item label-width="72px" v-if="allData.clientBusinessVO
				.productIntroduction
			" label="主营业务: ">
											<div class=" w65">{{
			allData.clientBase &&
			allData.clientBusinessVO
				.productIntroduction
		}}</div>
										</el-form-item>
										<el-form-item label-width="72px" v-if="allData.clientBase.remarks && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="备注: ">
											<div class=" w65" v-html="fn(allData.clientBase.remarks || '')"></div>
										</el-form-item>
										<el-form-item v-if="allData.clientBase &&
			allData.clientBase
				.capitalizeAttachment &&
			privacyType == 2
			" label="附件:">
											<el-upload disabled style="
														line-height: 0;
														margin-left: 50px;
													" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.capitalizeAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="allData.clientBase
			.businessInformationAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2
			" label="营业执照附件:">
											<el-upload disabled style="
														line-height: 0;
														margin-left: 90px;
													" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.businessInformationAttachment
			">
											</el-upload>
										</el-form-item>
									</div>




								</template>
								<template v-if="allData.checkedTitleField.includes(
			'clientBase_historyLitigation'
		)
			">
									<p class="t2  c-1-2">
										<span class="t1-2">2.2.</span>
										历史诉讼信息
									</p>
									<div class="c-1-2-body" v-if="mobileInfo">
										<el-table v-if="allData.litigationsList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.litigationsList" border style="width: fit-content">
											<el-table-column :width="flexColumnWidth(allData.litigationsList, 'caseName', 220, 80)"
												prop="caseName" label="案件名称">
											</el-table-column>
											<el-table-column prop="litigationTypeName" label="诉讼人类型" width="60">
											</el-table-column>

											<el-table-column align="right" label="案件金额(万元)" width="80">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.caseAmount
			)
		}}
												</template>
											</el-table-column>
											<el-table-column prop="caseStatusName" label="案件状态" width="80">
											</el-table-column>

											<el-table-column label="操作">

												<template slot-scope="scope">
													<span @click="lsContent(scope.row)" style="color: #4184ff;">详情</span>

												</template>
											</el-table-column>
											<!-- <el-table-column prop="remark" label="">
										</el-table-column>
										<el-table-column prop="customerExplanation" label="">
										</el-table-column> -->
										</el-table>
									</div>
									<div class="c-1-2-body" v-else>
										<el-table v-if="allData.litigationsList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.litigationsList" border style="width: fit-content">
											<el-table-column :width="flexColumnWidth(allData.litigationsList, 'caseName', 300, 80)"
												prop="caseName" label="案件名称">
											</el-table-column>
											<el-table-column prop="litigationTypeName" label="诉讼人类型" width="100">
											</el-table-column>

											<el-table-column align="right" label="案件金额(万元)" width="116">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.caseAmount
			)
		}}
												</template>
											</el-table-column>
											<el-table-column prop="caseStatusName" label="案件状态" width="80">
											</el-table-column>

											<el-table-column :width="flexColumnWidth(allData.litigationsList, 'caseAction', 300, 60)"
												prop="caseAction" label="案由">
											</el-table-column>
											<el-table-column :width="flexColumnWidth(allData.litigationsList, 'remark', 300, 80)"
												prop="remark" label="案件概述">
											</el-table-column>
											<el-table-column :width="flexColumnWidth(allData.litigationsList, 'customerExplanation', 300, 80)"
												prop="customerExplanation" label="客户解释">
											</el-table-column>
											<el-table-column
												v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
												prop="caseNo" label="案号" :width="flexColumnWidth(allData.litigationsList, 'caseNo', 150, 60)">
											</el-table-column>

											<el-table-column :width="flexColumnWidth(allData.litigationsList, 'remarks', 300, 60)"
												v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
												prop="remarks" label="备注">
											</el-table-column>
										</el-table>
									</div>


								</template>
								<template v-if="allData.checkedTitleField.includes(
			'clientBase_shareholder'
		)
			">
									<p class="t2  c-1-3">
										<span class="t1-2">2.3.</span> 股东信息
									</p>
									<div class="c-1-3-body" v-if="mobileInfo">
										<el-table v-if="allData.shareholders.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.shareholders" border style="width: 100%">
											<el-table-column :width="flexColumnWidth(allData.shareholders, 'shareholder', 220, 140)"
												prop="shareholder" label="股东及出资信息">
											</el-table-column>

											<el-table-column align="right" width="60" label="持股比例(%)">
												<template slot-scope="scope">
													{{
			scope.row.sharesRatio
				? Number(
					scope.row
						.sharesRatio
				).toFixed(2)
				: ''
		}}
												</template>
											</el-table-column>
											<el-table-column width="90" label="认缴出资额(万元)" align="right">
												<template slot-scope="scope">
													{{
				formatCurrency(
					scope.row
						.subscriptionAmount
				)
			}}
												</template>
											</el-table-column>
											<el-table-column width="100" prop="subscriptionDate" label="认缴出资日期">
											</el-table-column>
										</el-table>
									</div>
									<div class="c-1-3-body" v-else>
										<el-table v-if="allData.shareholders.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.shareholders" border style="width: fit-content">
											<el-table-column :width="flexColumnWidth(allData.shareholders, 'shareholder', 320, 140)"
												prop="shareholder" label="股东及出资信息">
											</el-table-column>

											<el-table-column width="100" align="right" label="持股比例(%)">
												<template slot-scope="scope">
													{{
			scope.row.sharesRatio
				? Number(
					scope.row
						.sharesRatio
				).toFixed(2)
				: ''
		}}
												</template>
											</el-table-column>
											<el-table-column width="130" label="认缴出资额(万元)" align="right">
												<template slot-scope="scope">
													{{
				formatCurrency(
					scope.row
						.subscriptionAmount
				)
			}}
												</template>
											</el-table-column>
											<el-table-column width="110" prop="subscriptionDate" label="认缴出资日期">
											</el-table-column>
										</el-table>
									</div>



								</template>
								<template v-if="allData.checkedTitleField.includes(
				'clientBase_historicalCredit'
			)
			">
									<p class="t2  c-1-4">
										<span class="t1-2">2.4.</span>
										历史信用信息
									</p>
									<div class="c-1-4-body" v-if="mobileInfo">
										<el-form-item class='textinfo' label-width="130px" v-if="allData.clientBase.creditRecord"
											label="企业征信记录: ">
											<div v-if="allData.clientBase
			.creditRecord == 1 ||
			allData.clientBase
				.creditRecord == 5
			" class="info1 w65" v-html="fn(
			allData.clientBase
				.creditRecordName || ''
		)
			"></div>
											<div v-else class="info1 w65" v-html="fn(
			allData.clientBase
				.creditRecordDesc || ''
		)
			"></div>
										</el-form-item>
										<el-form-item class='textinfo' label-width="130px" v-if="allData.clientBase.punishRecord"
											label="行政处罚记录: ">
											<div class="info1 w65" v-if="allData.clientBase
			.punishRecord != 2
			" v-html="fn(
			allData.clientBase
				.punishRecordName || ''
		)
			"></div>
											<div v-else class="info1 w65" v-html="fn(
			allData.clientBase
				.punishRecordDesc || ''
		)
			"></div>
										</el-form-item>
										<el-form-item class='textinfo' label-width="130px" v-if="allData.clientBase.otherRecord"
											label="其他不良信用记录: ">
											<div class="info1 w65" v-if="allData.clientBase
			.otherRecord != 2
			" v-html="fn(
			allData.clientBase
				.otherRecordName || ''
		)
			"></div>
											<div v-else class="info1 w65" v-html="fn(
			allData.clientBase
				.otherRecordDesc || ''
		)
			"></div>
										</el-form-item>
										<el-form-item class='textinfo' label-width="130px" v-if="allData.clientBase
			.businessRisksRecord
			" label="重大工商风险记录: ">
											<div class="info1 w65" v-if="allData.clientBase
			.businessRisksRecord != 2
			" v-html="fn(
			allData.clientBase
				.businessRisksRecordName ||
			''
		)
			"></div>
											<div v-else class="info1 w65" v-html="fn(
			allData.clientBase
				.businessRisksRecordDesc ||
			''
		)
			"></div>
										</el-form-item>
										<el-form-item class='textinfo' label-width="130px" v-if="allData.clientBase.historicalCreditRemark && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="备注: ">
											<div class="info1 w65" v-html="fn(
			allData.clientBase
				.historicalCreditRemark || ''
		)
			"></div>
										</el-form-item>
									</div>
									<div class="c-1-4-body" v-else>
										<el-form-item label-width="130px" v-if="allData.clientBase.creditRecord" label="企业征信记录: ">
											<div v-if="allData.clientBase
			.creditRecord == 1 ||
			allData.clientBase
				.creditRecord == 5
			" class=" w65" v-html="fn(
			allData.clientBase
				.creditRecordName || ''
		)
			"></div>
											<div v-else class=" w65" v-html="fn(
			allData.clientBase
				.creditRecordDesc || ''
		)
			"></div>
										</el-form-item>
										<el-form-item label-width="130px" v-if="allData.clientBase.punishRecord" label="行政处罚记录: ">
											<div class=" w65" v-if="allData.clientBase
			.punishRecord != 2
			" v-html="fn(
			allData.clientBase
				.punishRecordName || ''
		)
			"></div>
											<div v-else class=" w65" v-html="fn(
			allData.clientBase
				.punishRecordDesc || ''
		)
			"></div>
										</el-form-item>
										<el-form-item label-width="130px" v-if="allData.clientBase.otherRecord" label="其他不良信用记录: ">
											<div class=" w65" v-if="allData.clientBase
			.otherRecord != 2
			" v-html="fn(
			allData.clientBase
				.otherRecordName || ''
		)
			"></div>
											<div v-else class=" w65" v-html="fn(
			allData.clientBase
				.otherRecordDesc || ''
		)
			"></div>
										</el-form-item>
										<el-form-item label-width="130px" v-if="allData.clientBase
			.businessRisksRecord
			" label="重大工商风险记录: ">
											<div class=" w65" v-if="allData.clientBase
			.businessRisksRecord != 2
			" v-html="fn(
			allData.clientBase
				.businessRisksRecordName ||
			''
		)
			"></div>
											<div v-else class=" w65" v-html="fn(
			allData.clientBase
				.businessRisksRecordDesc ||
			''
		)
			"></div>
										</el-form-item>
										<el-form-item label-width="130px" v-if="allData.clientBase
			.historicalCreditRemark && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2
			" label="备注: ">
											<div class=" w65" v-html="fn(
			allData.clientBase
				.historicalCreditRemark ||
			''
		)
			"></div>
										</el-form-item>
									</div>
									<el-form-item v-if="(allData.clientBase.capitalizeEnterpriseCreditAttachment && privacyType == 2)
			||
			(allData.checkedTitleField.includes('capitalizeEnterpriseCreditAttachment_attachment') && privacyType == 1)
			" label="企业征信附件:">
										<el-upload style="
															line-height: 0;
															margin-left: 103px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.clientBase.capitalizeEnterpriseCreditAttachment
			">
										</el-upload>
									</el-form-item>
									<el-form-item v-if="allData.clientBase.historyCreditInfoAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="附件:">
										<el-upload style="
															line-height: 0;
															margin-left: 103px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.clientBase.historyCreditInfoAttachment
			">
										</el-upload>
									</el-form-item>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'clientBase_cooperationBank'
		)
			">
									<p class="t2  c-1-5">
										<span class="t1-2">2.5.</span>
										当前合作银行信息
									</p>
									<div class="c-1-5-body">
										<el-form-item label-width="100px" v-if="allData.clientBase.baseBankName" label="基本账户行: ">
											{{ allData.clientBase.baseBankName }}
										</el-form-item>
										<el-form-item label-width="100px" v-if="allData.clientBase.settleBankName" label="主要结算银行: ">{{
			allData.clientBase &&
			allData.clientBase.settleBankName
		}}
										</el-form-item>
										<el-form-item label-width="100px" v-if="allData.clientBase.payBankName" label="代发薪银行: ">{{
			allData.clientBase &&
			allData.clientBase.payBankName
		}}
										</el-form-item>
										<el-form-item label-width="100px" v-if="allData.clientBase.monthPayAmount" label="月代发薪金额: ">{{
			allData.clientBase &&
			allData.clientBase.monthPayAmount
		}}<span v-if="allData.clientBase
				.monthPayAmount
			">(万元)</span>
										</el-form-item>
									</div>
								</template>
								<template
									v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2">
									<p class="t2  c-1-6">
										<span class="t1-2">2.6.</span>
										股东及法人变更记录
									</p>
									<div v-if="mobileInfo" class="c-1-6-body">

										<el-table v-if="allData.clientShareholderChangeRecordList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.clientShareholderChangeRecordList" border style="width: fit-content">
											<el-table-column width="120" prop="changeDate" label="变更时间">
											</el-table-column>
											<el-table-column width="120" prop="changeTypeName" label="变更类型">
											</el-table-column>
											<el-table-column label="操作">
												<template slot-scope="scope">
													<span @click="lsContent(scope.row, 1)" style="color: #4184ff;">详情</span>

												</template>
											</el-table-column>

										</el-table>
									</div>
									<div v-else class="c-1-6-body">

										<el-table v-if="allData.clientShareholderChangeRecordList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.clientShareholderChangeRecordList" border style="width: fit-content">
											<el-table-column width="120" prop="changeDate" label="变更时间">
											</el-table-column>
											<el-table-column width="120" prop="changeTypeName" label="变更类型">
											</el-table-column>
											<el-table-column
												:width="flexColumnWidth(allData.clientShareholderChangeRecordList, 'changeBeforeContent', 480, 100)"
												label="变更前内容">
												<template slot-scope="scope">
													<div v-html="textcolor(scope.row.changeBeforeContent)"></div>
												</template>
											</el-table-column>
											<el-table-column
												:width="flexColumnWidth(allData.clientShareholderChangeRecordList, 'changeAfterContent', 480, 100)"
												label="变更后内容" >
												<template slot-scope="scope">
													<div v-html="textcolor(scope.row.changeAfterContent)"></div>
												</template>
											</el-table-column>
											<el-table-column
												:width="flexColumnWidth(allData.clientShareholderChangeRecordList, 'remark', 480, 60)"
												label="备注">
												<template slot-scope="scope">
													<div v-html="fn(scope.row.remark)"></div>
												</template>
											</el-table-column>

										</el-table>
									</div>
								</template>
								<template
									v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2">
									<p class="t2  c-1-7">
										<span class="t1-2">2.7.</span>
										关联公司情况
									</p>
									<div class="c-1-7-body">

										<el-table v-if="allData.clientAssociationCompanyList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.clientAssociationCompanyList" border style="width: fit-content">
											<el-table-column
												:width="flexColumnWidth(allData.clientAssociationCompanyList, 'companyName', 220, 80)"
												prop="companyName" label="公司名称">
											</el-table-column>
											<el-table-column
												:width="flexColumnWidth(allData.clientAssociationCompanyList, 'companyCode', 200, 160)"
												prop="companyCode" label="统一社会信用代码">
											</el-table-column>
											<el-table-column width="120" prop="associationCategoryName" label="关联类别">
											</el-table-column>

											<el-table-column :width="flexColumnWidth(allData.clientAssociationCompanyList, 'remark', 60, 60)"
												v-if="mobileInfo" label="备注">
												<template slot-scope="scope">
													<span @click="showContent(scope.row.remark)"
														v-if="scope.row.remark && scope.row.remark.length > 50" style="color: #4184ff;">详情</span>
													<span v-else>{{ scope.row.remark }}</span>

												</template>
											</el-table-column>
											<el-table-column v-else
												:width="flexColumnWidth(allData.clientAssociationCompanyList, 'remark', 600, 60)" prop="remark"
												label="备注">

											</el-table-column>
										</el-table>
									</div>
								</template>
							</template>

							<template v-if="allData.checkedTitleField.includes(
			'clientKeyInfo'
		)
			">
								<p class="t1 c-2">
									<span class="t1-3">3.</span> <span class="roll">客户重点信息</span>
								</p>
								<div v-if="mobileInfo" class="running-info c-2-body">
									<div class="add-list">
										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
			.mainBusinessIncomeTwoAmount
			" label="主营业务收入(元): ">
											{{
			allData.otherKeyInfo
				.mainBusinessIncomeTwoYear
		}}
											年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.mainBusinessIncomeTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.mainBusinessIncomeOneAmount
			" label="主营业务收入(元): ">
											{{
			allData.otherKeyInfo
				.mainBusinessIncomeOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.mainBusinessIncomeOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.mainBusinessIncomeThisAmount
			" label="主营业务收入(元): ">{{
			allData.otherKeyInfo
				.mainBusinessIncomeThisYear
		}}年
											{{
				allData.otherKeyInfo
					.mainBusinessIncomeThisYearMonthName
			}}
											{{
				formatCurrency(
					allData.otherKeyInfo
						.mainBusinessIncomeThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list">
										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeTwoAmount
			" label="纳税申报收入(元): ">
											{{
			allData.otherKeyInfo
				.taxDeclarationIncomeTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeOneAmount
			" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeThisAmount
			" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeThisYear
		}}年
											{{
				allData.otherKeyInfo
					.taxDeclarationIncomeThisYearMonthName
			}}
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list"></div>
									<div class="add-list">
										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.totalTaxPaymentTwoAmount
			" label="纳税总额(元): ">
											{{
			allData.otherKeyInfo
				.totalTaxPaymentTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.totalTaxPaymentOneAmount
			" label="纳税总额(元):">{{
			allData.otherKeyInfo
				.totalTaxPaymentOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="140px" v-if="allData.otherKeyInfo
				.totalTaxPaymentThisAmount
			" label="纳税总额(元):">{{
			allData.otherKeyInfo
				.totalTaxPaymentThisYear
		}}年
											{{
				allData.otherKeyInfo
					.totalTaxPaymentThisYearMonthName
			}}
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentThisAmount
				)
			}}
										</el-form-item>
									</div>


									<el-form-item label-width="100px" v-if="allData.otherKeyInfo
				.capitalMarketName
			" label="资本市场状况: ">
										{{
			allData.otherKeyInfo
				.capitalMarketName
		}}
									</el-form-item>

									<el-form-item label-width="100px" v-if="allData.otherKeyInfo
				.highTechEnterpriseQualificationName
			" label="高新技术资质: ">
										{{
			allData.otherKeyInfo
				.highTechEnterpriseQualificationName
		}}
									</el-form-item>
									<el-form-item label-width="100px" v-if="allData.otherKeyInfo
				.specializedNewQualificationName
			" label="专精特新资质: ">
										{{
			allData.otherKeyInfo
				.specializedNewQualificationName
		}}
									</el-form-item>
									<div style="display:flex">
										<el-form-item label-width="100px" style="flex:1" v-if="allData.otherKeyInfo
				.taxCreditRatingName
			" label="纳税信用等级: ">
											{{
			allData.otherKeyInfo
				.taxCreditRatingName
		}}
										</el-form-item>
										<el-form-item label-width="100px" style="flex:1" v-if="allData.otherKeyInfo.hasOwnProperty(
				'employeeNumber'
			)
			" label="社保人数: ">
											{{
			allData.otherKeyInfo.employeeNumber
		}}人
										</el-form-item>

									</div>

									<div style="display:flex">
										<el-form-item label-width="100px" style="flex:1" v-if="allData.otherKeyInfo.hasOwnProperty(
				'inventionPatent'
			)
			" label="发明专利: ">
											{{
			allData.otherKeyInfo
				.inventionPatent
		}}项
										</el-form-item>
										<el-form-item label-width="100px" style="flex:1" v-if="allData.otherKeyInfo.hasOwnProperty(
				'utilityModel'
			)
			" label="实用新型: ">
											{{
			allData.otherKeyInfo.utilityModel
		}}项
										</el-form-item>
									</div>
									<div style="display:flex">
										<el-form-item label-width="100px" style="flex:1" v-if="allData.otherKeyInfo.hasOwnProperty(
				'appearanceDesign'
			)
			" label="外观设计: ">
											{{
			allData.otherKeyInfo
				.appearanceDesign
		}}项
										</el-form-item>
										<el-form-item label-width="100px" style="flex:1" v-if="allData.otherKeyInfo.hasOwnProperty(
				'softwareCopyright'
			)
			" label="软件著作权: ">
											{{
			allData.otherKeyInfo
				.softwareCopyright
		}}项
										</el-form-item>
									</div>

									<el-form-item label-width="100px" v-if="allData.otherKeyInfo
				.otherEnterpriseIntelligence
			" label="其他企业资质: ">
										{{
			allData.otherKeyInfo
				.otherEnterpriseIntelligence
		}}
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo
				.anyMajorLitigationName
			" label="是否有未结重大诉讼: ">
										{{
			allData.otherKeyInfo
				.anyMajorLitigationName
		}}
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo
				.enterpriseCreditRecordName
			" label="企业征信记录: ">
										{{
			allData.otherKeyInfo
				.enterpriseCreditRecordName
		}}
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo
				.actualControllerScoreName
			" label="实控人综合评分: ">
										{{
			allData.otherKeyInfo
				.actualControllerScoreName
		}}
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'enterpriseCurrentBankLiabilities'
			)
			" label="企业当前银行负债: ">
										{{
			allData.otherKeyInfo
				.enterpriseCurrentBankLiabilities
		}}万元
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo
				.actualControllerCreditRecordName
			" label="实控人征信记录: ">
										{{
			allData.otherKeyInfo
				.actualControllerCreditRecordName
		}}
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo
				.actualControllerFamilyEstateName
			" label="实控人家庭房产情况: ">
										{{
			allData.otherKeyInfo
				.actualControllerFamilyEstateName
		}}
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'actualControllerOperatingLiabilities'
			)
			" label="实控人个人经营类负债: ">
										{{
			allData.otherKeyInfo
				.actualControllerOperatingLiabilities
		}}万元
									</el-form-item>
									<el-form-item label-width="155px" v-if="allData.otherKeyInfo
				.accountsReceivableName
			" label="应收账款质押情况: ">
										<div class="info1" v-html="fn(
			allData.otherKeyInfo
				.accountsReceivableName ||
			''
		)
			"></div>
									</el-form-item>

									<el-form-item class="textinfo" label-width="100px" style="width: 100%"
										v-if="allData.otherKeyInfo.remark" label="备注: ">
										<div class="info1" v-html="fn(
			allData.otherKeyInfo
				.remark || ''
		)
			"></div>
									</el-form-item>
									<el-form-item class="textinfo" label-width="100px" style="width: 100%" v-if="allData.otherKeyInfo.otherDesc && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="其他补充说明: ">
										<div class="info1" v-html="fn(
			allData.otherKeyInfo
				.otherDesc || ''
		)
			"></div>
									</el-form-item>

									<el-form-item v-if="allData.otherKeyInfo && allData.otherKeyInfo.keyInfoAttachment && allData.otherKeyInfo.keyInfoAttachment.length && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="附件	:">
										<el-upload style="
															line-height: 0;
															margin-left: 75px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.otherKeyInfo.keyInfoAttachment
			">
										</el-upload>
									</el-form-item>
								</div>
								<div v-if="!mobileInfo" class="running-info c-2-body">
									<div class="add-list">
										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
			.mainBusinessIncomeTwoAmount
			" label="主营业务收入(元): ">
											{{
			allData.otherKeyInfo
				.mainBusinessIncomeTwoYear
		}}
											年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.mainBusinessIncomeTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.mainBusinessIncomeOneAmount
			" label="主营业务收入(元): ">
											{{
			allData.otherKeyInfo
				.mainBusinessIncomeOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.mainBusinessIncomeOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.mainBusinessIncomeThisAmount
			" label="主营业务收入(元): ">{{
			allData.otherKeyInfo
				.mainBusinessIncomeThisYear
		}}年度
											{{
				allData.otherKeyInfo
					.mainBusinessIncomeThisYearMonthName
			}}
											{{
				formatCurrency(
					allData.otherKeyInfo
						.mainBusinessIncomeThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list">
										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeTwoAmount
			" label="纳税申报收入(元): ">
											{{
			allData.otherKeyInfo
				.taxDeclarationIncomeTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeOneAmount
			" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeThisAmount
			" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeThisYear
		}}年度
											{{
				allData.otherKeyInfo
					.taxDeclarationIncomeThisYearMonthName
			}}
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list"></div>
									<div class="add-list">
										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.totalTaxPaymentTwoAmount
			" label="纳税总额(元): ">
											{{
			allData.otherKeyInfo
				.totalTaxPaymentTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.totalTaxPaymentOneAmount
			" label="纳税总额(元):">{{
			allData.otherKeyInfo
				.totalTaxPaymentOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.totalTaxPaymentThisAmount
			" label="纳税总额(元):">{{
			allData.otherKeyInfo
				.totalTaxPaymentThisYear
		}}年度
											{{
				allData.otherKeyInfo
					.totalTaxPaymentThisYearMonthName
			}}
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentThisAmount
				)
			}}
										</el-form-item>
									</div>

									<el-form-item label-width="130px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'employeeNumber'
			)
			" label="社保人数（人）: ">
										{{
			allData.otherKeyInfo.employeeNumber
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.capitalMarketName
			" label="资本市场状况: ">
										{{
			allData.otherKeyInfo
				.capitalMarketName
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.highTechEnterpriseQualificationName
			" label="高新技术企业资质: ">
										{{
			allData.otherKeyInfo
				.highTechEnterpriseQualificationName
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.specializedNewQualificationName
			" label="专精特新资质: ">
										{{
			allData.otherKeyInfo
				.specializedNewQualificationName
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.taxCreditRatingName
			" label="纳税信用等级: ">
										{{
			allData.otherKeyInfo
				.taxCreditRatingName
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'inventionPatent'
			)
			" label="发明专利: ">
										{{
			allData.otherKeyInfo
				.inventionPatent
		}}项
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'utilityModel'
			)
			" label="实用新型: ">
										{{
			allData.otherKeyInfo.utilityModel
		}}项
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'appearanceDesign'
			)
			" label="外观设计: ">
										{{
			allData.otherKeyInfo
				.appearanceDesign
		}}项
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'softwareCopyright'
			)
			" label="软件著作权: ">
										{{
			allData.otherKeyInfo
				.softwareCopyright
		}}项
									</el-form-item>
									<el-form-item style="width: 100%;" label-width="130px" v-if="allData.otherKeyInfo
				.otherEnterpriseIntelligence
			" label="其他企业资质: ">
										{{
			allData.otherKeyInfo
				.otherEnterpriseIntelligence
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.actualControllerCreditRecordName
			" label="实控人征信记录: ">
										{{
			allData.otherKeyInfo
				.actualControllerCreditRecordName
		}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="allData.otherKeyInfo
				.enterpriseCreditRecordName
			" label="企业征信记录: ">
										{{
			allData.otherKeyInfo
				.enterpriseCreditRecordName
		}}
									</el-form-item>
									<el-form-item label-width="160px" v-if="allData.otherKeyInfo
				.actualControllerScoreName
			" label="实控人综合评分: ">
										{{
			allData.otherKeyInfo
				.actualControllerScoreName
		}}
									</el-form-item>
									<el-form-item label-width="160px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'enterpriseCurrentBankLiabilities'
			)
			" label="企业当前银行负债: ">
										{{
			allData.otherKeyInfo
				.enterpriseCurrentBankLiabilities
		}}万元
									</el-form-item>
									<el-form-item label-width="160px" v-if="allData.otherKeyInfo
				.anyMajorLitigationName
			" label="是否有未结重大诉讼: ">
										{{
			allData.otherKeyInfo
				.anyMajorLitigationName
		}}
									</el-form-item>



									<el-form-item label-width="160px" v-if="allData.otherKeyInfo
				.actualControllerFamilyEstateName
			" label="实控人家庭房产情况: ">
										{{
			allData.otherKeyInfo
				.actualControllerFamilyEstateName
		}}
									</el-form-item>
									<el-form-item label-width="160px" v-if="allData.otherKeyInfo.hasOwnProperty(
				'actualControllerOperatingLiabilities'
			)
			" label="实控人个人经营类负债: ">
										{{
			allData.otherKeyInfo
				.actualControllerOperatingLiabilities
		}}万元
									</el-form-item>
									<el-form-item label-width="160px" v-if="allData.otherKeyInfo
				.accountsReceivableName
			" label="应收账款质押情况: ">
										<div v-html="fn(
			allData.otherKeyInfo
				.accountsReceivableName ||
			''
		)
			"></div>
									</el-form-item>

									<el-form-item label-width="105px" style="width: 100%" v-if="allData.otherKeyInfo.remark" label="备注: ">
										<div class="w65" v-html="fn(
			allData.otherKeyInfo
				.remark || ''
		)
			"></div>
									</el-form-item>
									<el-form-item label-width="105px" style="width: 100%" v-if="allData.otherKeyInfo.otherDesc && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="其他补充说明: ">
										<div class="w65" v-html="fn(
			allData.otherKeyInfo
				.otherDesc || ''
		)
			"></div>
									</el-form-item>
									<el-form-item v-if="allData.otherKeyInfo && allData.otherKeyInfo.keyInfoAttachment && allData.otherKeyInfo.keyInfoAttachment.length && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="附件:">
										<el-upload style="
															line-height: 0;
															margin-left: 75px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.otherKeyInfo.keyInfoAttachment
			">
										</el-upload>
									</el-form-item>
								</div>
							</template>
							<template v-if="allData.checkedTitleField.includes(
			'controlPerson'
		)
			">
								<p class="t1" v-if="allData.personsList.length">
									<span class="t1-4">4.</span> <span class="roll">关键人信息</span>
								</p>
								<template v-if="allData.personsList && allData.personsList.length && mobileInfo">
									<div v-for="(
													gitem, index
												) in allData.personsList" :key="'gjr' + index">
										<p class="t2" v-if="gitem.personName">

											<span class="t1-4">4.1.</span>
											关键人-{{ gitem.personName }}
										</p>
										<el-form-item label-width="72px" v-if="gitem.controlPersonTypeName" label="类别: ">{{
			gitem.controlPersonTypeName }}
										</el-form-item>
										<div style="display: flex;">
											<el-form-item style="flex: 4;" label-width="72px" v-if="gitem.sexFlagName" label="性别: ">{{
			gitem.sexFlagName }}
											</el-form-item>
											<el-form-item label-width="72px" style="flex: 6;" v-if="gitem.nativePlace" label="籍贯: ">{{
			gitem.nativePlace }}
											</el-form-item>
										</div>
										<div style="display: flex;">
											<el-form-item style="flex: 4;" label-width="72px" v-if="gitem.maritalFlagName" label="婚姻状况: ">{{
			gitem.maritalFlagName
		}}
											</el-form-item>
											<el-form-item label-width="72px" style="flex: 6;" v-if="gitem.hasOwnProperty('age')"
												label="年龄: ">{{
			gitem.age }}岁
											</el-form-item>
										</div>
										<el-form-item label-width="72px" v-if="gitem.holdOfficeName" label="房产情况: ">{{
			gitem.actualControllerFamilyEstateName }}
										</el-form-item>
										<el-form-item label-width="72px" v-if="gitem.holdOfficeName" label="担任职务: ">{{ gitem.holdOfficeName
											}}
										</el-form-item>
										<el-form-item label-width="72px" v-if="gitem.educationFlagName" label="学历: ">{{
			gitem.educationFlagName }}
										</el-form-item>
										<el-form-item label-width="72px" v-if="gitem.graduateSchool" label="毕业院校: ">{{ gitem.graduateSchool
											}}
										</el-form-item>
										<el-form-item label-width="72px" v-if="gitem.educationMajor" label="所学专业: ">{{ gitem.educationMajor
											}}
										</el-form-item>
										<el-form-item label-width="72px" v-if="gitem.personCreditRecordName" label="征信记录: ">{{
			gitem.personCreditRecordName }}
										</el-form-item>
										<el-form-item v-if="gitem.personCreditRecordDesc" label="征信记录描述: ">
											<div class="info1">{{ gitem.personCreditRecordDesc }}</div>

										</el-form-item>

										<el-form-item label-width="95px" v-if="gitem.controllerGlory" label="主要荣誉: ">{{
			gitem.controllerGlory
		}}
										</el-form-item>
										<el-form-item v-if="gitem.workExperience" label="主要工作经历: ">
											<div class="info1">{{ gitem.workExperience }}</div>

										</el-form-item>
										<el-form-item v-if="gitem.workGrade" label="主要工作成绩: ">
											<div class="info1">{{ gitem.workGrade }}</div>

										</el-form-item>
										<el-form-item v-if="(gitem.controlPersonAttachment && gitem.controlPersonAttachment.length && privacyType == 2)
			||
			(allData.checkedTitleField.includes('controlPersonAttachment_attachment') && privacyType == 1)
			" label="身份证附件	:">
											<el-upload style="
															line-height: 0;
															margin-left: 70px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="gitem.controlPersonAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="(gitem.personalCreditAttachment && privacyType == 2)
			||
			(allData.checkedTitleField.includes('personalCreditAttachment_attachment') && privacyType == 1)
			" label="个人征信附件:">
											<el-upload style="
															line-height: 0;
															margin-left: 70px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="gitem.personalCreditAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="(gitem.otherAttachment && privacyType == 2)
			||
			(allData.checkedTitleField.includes('otherAttachment_attachment') && privacyType == 1)
			" label="其他附件:">
											<el-upload style="
															line-height: 0;
															margin-left: 70px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="gitem.otherAttachment
			">
											</el-upload>
										</el-form-item>
									</div>
								</template>
								<template v-if="allData.personsList && allData.personsList.length && !mobileInfo">
									<div v-for="(
													gitem, index
												) in allData.personsList" :key="'gjr' + index">
										<p class="t2" v-if="gitem.personName">
											<span class="t1-4">4.1.</span>
											关键人-{{ gitem.personName }}
										</p>
										<div class="running-info">
											<el-form-item label-width="86px" v-if="gitem.controlPersonTypeName" label="关键人类别: ">{{
			gitem.controlPersonTypeName }}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.sexFlagName" label="性别: ">{{ gitem.sexFlagName }}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.nativePlace" label="籍贯: ">{{ gitem.nativePlace }}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.maritalFlagName" label="婚姻状况: ">{{
			gitem.maritalFlagName
		}}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.maritalFlagName" label="房产情况: ">{{
			gitem.actualControllerFamilyEstateName
		}}
											</el-form-item>

											<el-form-item label-width="86px" v-if="gitem.hasOwnProperty('age')" label="年龄: ">{{ gitem.age }}
											</el-form-item>

											<el-form-item label-width="86px" v-if="gitem.holdOfficeName" label="担任职务: ">{{
			gitem.holdOfficeName
		}}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.educationFlagName" label="学历: ">{{
			gitem.educationFlagName }}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.graduateSchool" label="毕业院校: ">{{
			gitem.graduateSchool
		}}
											</el-form-item>
											<el-form-item label-width="86px" v-if="gitem.educationMajor" label="所学专业: ">{{
			gitem.educationMajor
		}}
											</el-form-item>
										</div>
										<el-form-item label-width="86px" v-if="gitem.personCreditRecordName" label="征信记录: ">{{
			gitem.personCreditRecordName }}
										</el-form-item>
										<el-form-item label-width="100px" v-if="gitem.personCreditRecordDesc" label="征信记录描述: ">
											<div class="w65">{{ gitem.personCreditRecordDesc }}</div>
										</el-form-item>

										<el-form-item label-width="100px" v-if="gitem.controllerGlory" label="主要荣誉: ">{{
			gitem.controllerGlory
		}}
										</el-form-item>
										<el-form-item label-width="100px" v-if="gitem.workExperience" label="主要工作经历: ">
											<div class="w65">{{ gitem.workExperience }}</div>
										</el-form-item>
										<el-form-item label-width="100px" v-if="gitem.workGrade" label="主要工作成绩: ">
											<div class="w65">{{ gitem.workGrade }}</div>
										</el-form-item>
										<el-form-item v-if="(gitem.controlPersonAttachment && gitem.controlPersonAttachment.length && privacyType == 2)
			||
			(allData.checkedTitleField.includes('controlPersonAttachment_attachment') && privacyType == 1)
			" label="身份证附件	:">
											<el-upload style="
															line-height: 0;
															margin-left: 75px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="gitem.controlPersonAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="(gitem.personalCreditAttachment && privacyType == 2)
			||
			(allData.checkedTitleField.includes('personalCreditAttachment_attachment') && privacyType == 1)
			" label="个人征信附件:">
											<el-upload style="
															line-height: 0;
															margin-left: 75px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="gitem.personalCreditAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="(gitem.otherAttachment && privacyType == 2)
			||
			(allData.checkedTitleField.includes('otherAttachment_attachment') && privacyType == 1)
			" label="其他附件:">
											<el-upload style="
															line-height: 0;
															margin-left: 75px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="gitem.otherAttachment
			">
											</el-upload>
										</el-form-item>
									</div>
								</template>
							</template>

							<template v-if="allData.checkedTitleField.includes(
			'clientPersonalAssets'
		)
			">
								<p class="t1" v-if="allData.personalAssetsList.length || (allData.clientBase
			.assetListAttachment || allData.clientBase
				.assetOtherAttachment || allData.clientBase
				.assetRemark) && (allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
					.cooperationPresidentType == 2)">
									<span class="t1-5">5.</span> <span class="roll">资产信息</span>
								</p>
								<template v-if="allData.personalAssetsList.length && mobileInfo">
									<div v-for="(
													zzitem, index
												) in allData.personalAssetsList" :key="'zzitem' + index" class="propertyinfo running-info">
										<p class="t2">
											<span class="t1-5">5.1.</span>
											资产名称-{{ zzitem.assetName }}
										</p>
										<div style="display: flex;">
											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.realEstateTypesName"
												label="不动产类型: ">{{
			zzitem.realEstateTypesName }}
											</el-form-item>
											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.measureArea" label="面积: ">{{
			zzitem.measureArea }}㎡
											</el-form-item>
										</div>
										<div style="display: flex;">

											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.usefulLife" label="使用期限: ">{{
			zzitem.usefulLife }}年
											</el-form-item>
											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.assetStatusName" label="资产状况: ">{{
			zzitem.assetStatusName
		}}
											</el-form-item>
										</div>
										<div style="display: flex;">

											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.marketReferencePrice"
												label="市场参考价: ">{{
			formatCurrency(
				zzitem.marketReferencePrice
			)
		}}万元
											</el-form-item>
											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.guidancePrice" label="指导价: ">{{
			formatCurrency(
				zzitem.guidancePrice
			)
		}}万元
											</el-form-item>
										</div>
										<div style="display: flex;">

											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.loanBalance" label="贷款余额: ">{{
			formatCurrency(
				zzitem.loanBalance
			)
		}}万元
											</el-form-item>
											<el-form-item label-width="86px" style="flex:1" v-if="zzitem.lendingRate" label="贷款利率: ">{{
			Number(
				zzitem.lendingRate || 0
			).toFixed(2)
		}}%
											</el-form-item>
										</div>

										<el-form-item label-width="86px" v-if="zzitem.loanBank" label="贷款银行: ">{{ zzitem.loanBank }}
										</el-form-item>

										<el-form-item label-width="86px" v-if="zzitem.collateralAddressName" label="抵押物地址: ">{{
			zzitem.collateralAddressName }}
										</el-form-item>
										<div>

											<el-form-item label-width="95px" style="flex:1" v-if="zzitem.housePropertyCardDate"
												label="房产证日期: ">{{
			zzitem.housePropertyCardDate }}
											</el-form-item>
											<el-form-item label-width="95px" style="flex:1" v-if="zzitem.transferDate" label="过户日期: ">{{
			zzitem.transferDate }}
											</el-form-item>
											<el-form-item label-width="95px" style="flex:1" v-if="zzitem.housePropertyCertificateNo && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="房产证编号: ">{{
			zzitem.housePropertyCertificateNo }}
											</el-form-item>
											<el-form-item label-width="95px" style="flex:1" v-if="zzitem.propertyOwner && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="产权人(份额): ">{{
			zzitem.propertyOwner }}
											</el-form-item>
											<el-form-item label-width="95px" style="flex:1" v-if="zzitem.assetTypeName && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="资产所属类型: ">{{
			zzitem.assetTypeName }}
											</el-form-item>
											<el-form-item label-width="95px" style="flex:1" v-if="zzitem.borrowerName && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="借款人名称: ">{{
			zzitem.borrowerName }}
											</el-form-item>

										</div>

										<el-form-item label-width="45px" class="textinfo" style="width: 100%" v-if="zzitem.remarks"
											label="备注: ">
											<div class="info1" v-html="fn(
			zzitem.remarks ||
			''
		)
			"></div>
										</el-form-item>
										<el-form-item style="width: 100%" v-if="privacyType == 2" label="附件:">
											<template>
												<div style=" margin-left: 45px;">
													<div v-for="(
																urlitem, urlindex
															) in zzitem.assetAttachment" :key="urlindex" style="color: blue;cursor: pointer;"
														@click="handelOnChange(urlitem)">{{
			urlitem.name }}</div>
												</div>
											</template>
										</el-form-item>
									</div>
								</template>
								<template v-if="allData.personalAssetsList.length && !mobileInfo">
									<div v-for="(
													zzitem, index
												) in allData.personalAssetsList" :key="'zzitem' + index" class="propertyinfo running-info">
										<p class="t2">
											<span class="t1-5">5.1.</span>
											资产名称-{{ zzitem.assetName }}
										</p>
										<el-form-item label-width="130px" v-if="zzitem.realEstateTypesName" label="不动产类型: ">{{
			zzitem.realEstateTypesName }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.measureArea" label="面积(平方米): ">{{ zzitem.measureArea
											}}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.usefulLife" label="使用期限(年): ">{{ zzitem.usefulLife }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.assetStatusName" label="资产状况: ">{{
			zzitem.assetStatusName }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.marketReferencePrice" label="市场参考价(万元): ">{{
			formatCurrency(
				zzitem.marketReferencePrice
			)
		}}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.guidancePrice" label="指导价(万元): ">{{
			formatCurrency(
				zzitem.guidancePrice
			)
		}}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.loanBalance" label="贷款余额(万元): ">{{
			formatCurrency(
				zzitem.loanBalance
			)
		}}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.loanBank" label="贷款银行: ">{{ zzitem.loanBank }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.lendingRate" label="贷款利率(%): ">{{
			Number(
				zzitem.lendingRate || 0
			).toFixed(2)
		}}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.collateralAddressName" label="抵押物地址: ">{{
			zzitem.collateralAddressName }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.housePropertyCardDate" label="房产证日期: ">{{
			zzitem.housePropertyCardDate }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.transferDate" label="过户日期: ">{{ zzitem.transferDate
											}}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.housePropertyCertificateNo && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="房产证编号: ">{{
			zzitem.housePropertyCertificateNo }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.propertyOwner && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="产权人(份额): ">{{
			zzitem.propertyOwner }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.assetTypeName && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="资产所属类型: ">{{
			zzitem.assetTypeName }}
										</el-form-item>
										<el-form-item label-width="130px" v-if="zzitem.borrowerName && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="借款人名称: ">{{
			zzitem.borrowerName }}
										</el-form-item>
										<el-form-item style="width: 100%;" label-width="60px" v-if="zzitem.remarks" label="备注: ">
											<div class="w65">{{ fn(zzitem.remarks) }}</div>
										</el-form-item>
										<el-form-item style="width: 100%;" label-width="60px" v-if="privacyType == 2" label="附件:">
											<template>
												<div v-for="(
																urlitem, urlindex
															) in zzitem.assetAttachment" :key="urlindex" style="color: blue;cursor: pointer;"
													@click="handelOnChange(urlitem)">{{
			urlitem.name }}</div>
											</template>
										</el-form-item>
									</div>
								</template>
								<template v-if="mobileInfo">
									<div v-if="(allData.clientBase
			.assetListAttachment || allData.clientBase
				.assetOtherAttachment || allData.clientBase
				.assetRemark) && (allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
					.cooperationPresidentType == 2)">
										<p class="t2">
											<span class="t1-5">5.1.</span>
											其它相关信息
										</p>
										<el-form-item v-if="allData.clientBase
			.assetListAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="资产清单附件:">
											<el-upload style="
															line-height: 0;
															margin-left:70px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.assetListAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="allData.clientBase
			.assetOtherAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="资产其它附件:">
											<el-upload style="
															line-height: 0;
															margin-left:70px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.assetOtherAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item class="textinfo" label-width="100px" v-if="allData.clientBase
			.assetRemark && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" style="width: 100%" label="备注: ">
											<div class="info1" v-html="fn(
			allData.clientBase
				.assetRemark || ''
		)
			"></div>
										</el-form-item>
									</div>
								</template>
								<template v-if="!mobileInfo">
									<div v-if="(allData.clientBase
			.assetListAttachment || allData.clientBase
				.assetOtherAttachment || allData.clientBase
				.assetRemark) && (allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
					.cooperationPresidentType == 2)">
										<p class="t2">
											<span class="t1-5">5.1.</span>
											其它相关信息
										</p>
										<el-form-item v-if="allData.clientBase
			.assetListAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="资产清单附件:">
											<el-upload style="
															line-height: 0;
															margin-left:80px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.assetListAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item v-if="allData.clientBase
			.assetOtherAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="资产其它附件:">
											<el-upload style="
															line-height: 0;
															margin-left:80px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.assetOtherAttachment
			">
											</el-upload>
										</el-form-item>
										<el-form-item label-width="110px" v-if="allData.clientBase
			.assetRemark && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="备注: ">
											<div class="info1" v-html="fn(
			allData.clientBase
				.assetRemark || ''
		)
			"></div>
										</el-form-item>
									</div>
								</template>
							</template>

							<template v-if="allData.checkedTitleField.includes(
			'businessCase'
		)
			">
								<p class="t1 " v-if="allData.areasList.length ||
			allData.downstreams.length ||
			allData.upstreams.length ||
			allData.clientBusinessVO.mainSupplier ||
			allData.clientBusinessVO.downClient ||
			allData.clientBusinessVO
				.productIntroduction ||
			allData.clientBusinessVO.description ||
			allData.clientBusinessVO
				.coreCompetitivenessVista ||
			allData.clientBusinessVO
				.shortBoardProblems
			">
									<span class="t1-8">8.</span><span class="roll">经营情况</span>
								</p>
								<template v-if="mobileInfo">
									<template v-if="allData.checkedTitleField.includes(
			'businessCase_businessArea'
		)
			">
										<p class="t2" v-if="allData.areasList.length">
											<span class="t1-8">8.1.</span>
											生产经营场所情况
										</p>

										<el-table v-if="allData.areasList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.areasList" border style="width: 100%">
											<el-table-column width="220" prop="workAddress" label="办公地址">
											</el-table-column>
											<el-table-column width="40" prop="workNatureName" label="办公性质">
											</el-table-column>
											<el-table-column prop="workArea" width="80" label="办公面积(平方米)">
											</el-table-column>
											<el-table-column prop="placeNatureName" width="40" label="场所性质">
											</el-table-column>
											<el-table-column prop="startDate" label="该场所办公起始日">
											</el-table-column>
											<el-table-column prop="endDate" label="该场所租约到期日">
											</el-table-column>
											<el-table-column width="90" align="right" label="月租金及管理费(万元)">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.rentMonth
			)
		}}
												</template>
											</el-table-column>
											<el-table-column width="100" align="right" label="近六月平均水电费(万元)">
												<template slot-scope="scope">
													{{
				formatCurrency(
					scope.row.averageCost
				)
			}}
												</template>
											</el-table-column>
										</el-table>
									</template>
									<template v-if="allData.checkedTitleField.includes(
				'businessCase_businessDownstream'
			)
			">
										<p v-if="allData.downstreams.length" class="t2">
											<span class="t1-8">8.2.</span>
											上年度主要下游客户分析
										</p>

										<el-table v-if="allData.downstreams.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.downstreams" border style="width: 100%">
											<el-table-column width="220" prop="customerName" label="客户名称">
											</el-table-column>
											<el-table-column width="110" prop="customerNatureName" label="客户性质">
											</el-table-column>
											<el-table-column width="140" prop="saleGoods" label="销售商品名称/类型">
											</el-table-column>
											<el-table-column align="right" label="年度销售金额(万元)">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.saleAmount
			)
		}}
												</template>
											</el-table-column>
											<el-table-column prop="settlementTypeName" label="结算方式">
											</el-table-column>
											<el-table-column prop="settlementStage" label="结算账期">
											</el-table-column>
											<el-table-column prop="cooperationYears" label="合作年限(年)">
											</el-table-column>
											<el-table-column align="right" label="业务量占比(%)">
												<template slot-scope="scope">
													{{
				Number(
					scope.row
						.businessProportion ||
					0
				).toFixed(2)
			}}
												</template>
											</el-table-column>
											<el-table-column
												v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
												width="80" label="备注" align="left">
												<template slot-scope="scope">
													<span @click="showContent(scope.row.remark)"
														v-if="scope.row.remark && scope.row.remark.length > 50" style="color: #4184ff;">详情</span>
													<span v-else>{{ scope.row.remark }}</span>

												</template>
											</el-table-column>
										</el-table>
									</template>
									<template v-if="allData.checkedTitleField.includes(
			'businessCase_businessUpstream'
		)
			">
										<p v-if="allData.upstreams.length" class="t2">
											<span class="t1-8">8.3.</span>
											上年度主要上游客户分析
										</p>

										<el-table v-if="allData.upstreams.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.upstreams" border style="width: 100%">
											<el-table-column width="220" prop="supplierName" label="供应商名称">
											</el-table-column>
											<el-table-column width="110" prop="supplierNatureName" label="供应商性质">
											</el-table-column>
											<el-table-column width="140" prop="purchaseGoods" label="采购商品名称/类型">
											</el-table-column>
											<el-table-column label="年度采购金额(万元)" align="right">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.purchaseAmount
			)
		}}
												</template>
											</el-table-column>
											<el-table-column prop="settlementTypeName" label="结算方式">
											</el-table-column>
											<el-table-column prop="settlementStage" label="结算账期">
											</el-table-column>
											<el-table-column prop="cooperationYears" label="合作年限(年)">
											</el-table-column>
											<el-table-column label="业务量占比(%)" align="right">
												<template slot-scope="scope">
													{{
				Number(
					scope.row
						.businessProportion ||
					0
				).toFixed(2)
			}}
												</template>
											</el-table-column>
											<el-table-column
												v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
												width="80" label="备注" align="left">
												<template slot-scope="scope">
													<span @click="showContent(scope.row.remark)"
														v-if="scope.row.remark && scope.row.remark.length > 50" style="color: #4184ff;">详情</span>
													<span v-else>{{ scope.row.remark }}</span>

												</template>
											</el-table-column>
										</el-table>
									</template>
								</template>
								<template v-else>
									<template v-if="allData.checkedTitleField.includes(
			'businessCase_businessArea'
		)
			">
										<p class="t2" v-if="allData.areasList.length">
											<span class="t1-8">8.1.</span>
											生产经营场所情况
										</p>

										<el-table style="width: fit-content" v-if="allData.areasList.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.areasList" border>
											<el-table-column :width="flexColumnWidth(allData.areasList, 'workAddress', 320, 80)"
												prop="workAddress" label="办公地址">
											</el-table-column>
											<el-table-column width="80" prop="workNatureName" label="办公性质">
											</el-table-column>
											<el-table-column width="100" prop="workArea" label="办公面积㎡">
											</el-table-column>
											<el-table-column width="80" prop="placeNatureName" label="场所性质">
											</el-table-column>
											<el-table-column width="135" prop="startDate" label="该场所办公起始日">
											</el-table-column>
											<el-table-column width="135" prop="endDate" label="该场所租约到期日">
											</el-table-column>
											<el-table-column width="160" align="right" label="月租金及管理费(万元)">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.rentMonth
			)
		}}
												</template>
											</el-table-column>
											<el-table-column width="170" align="right" label="近6月平均水电费(万元)">
												<template slot-scope="scope">
													{{
				formatCurrency(
					scope.row.averageCost
				)
			}}
												</template>
											</el-table-column>
										</el-table>
									</template>
									<template v-if="allData.checkedTitleField.includes(
				'businessCase_businessDownstream'
			)
			">
										<p v-if="allData.downstreams.length" class="t2">
											<span class="t1-8">8.2.</span>
											上年度主要下游客户分析
										</p>

										<el-table v-if="allData.downstreams.length" style="width: fit-content" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.downstreams" border>
											<el-table-column :width="flexColumnWidth(allData.downstreams, 'customerName', 320, 80)"
												prop="customerName" label="客户名称">
											</el-table-column>
											<el-table-column width="105" prop="customerNatureName" label="客户性质">
											</el-table-column>
											<el-table-column width="200" prop="saleGoods" label="销售商品名称/类型">
											</el-table-column>
											<el-table-column width="145" align="right" label="年度销售金额(万元)">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.saleAmount
			)
		}}
												</template>
											</el-table-column>
											<el-table-column width="85" prop="settlementTypeName" label="结算方式">
											</el-table-column>
											<el-table-column width="85" prop="settlementStage" label="结算账期">
											</el-table-column>
											<el-table-column width="110" prop="cooperationYears" label="合作年限(年)">
											</el-table-column>
											<el-table-column width="115" align="right" label="业务量占比(%)">
												<template slot-scope="scope">
													{{
				Number(
					scope.row
						.businessProportion ||
					0
				).toFixed(2)
			}}
												</template>
											</el-table-column>
											<el-table-column
												v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
												width="300" label="备注" align="left">
												<template slot-scope="scope">

													<span>{{ scope.row.remark }}</span>

												</template>
											</el-table-column>
										</el-table>
									</template>
									<template v-if="allData.checkedTitleField.includes(
			'businessCase_businessUpstream'
		)
			">
										<p v-if="allData.upstreams.length" class="t2">
											<span class="t1-8">8.3.</span>
											上年度主要上游客户分析
										</p>

										<el-table style="width: fit-content" v-if="allData.upstreams.length" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.upstreams" border>
											<el-table-column :width="flexColumnWidth(allData.upstreams, 'supplierName', 300, 100)"
												prop="supplierName" label="供应商名称">
											</el-table-column>
											<el-table-column width="105" prop="supplierNatureName" label="供应商性质">
											</el-table-column>
											<el-table-column width="200" prop="purchaseGoods" label="采购商品名称/类型">
											</el-table-column>
											<el-table-column width="145" label="年度采购金额(万元)" align="right">
												<template slot-scope="scope">
													{{
			formatCurrency(
				scope.row.purchaseAmount
			)
		}}
												</template>
											</el-table-column>
											<el-table-column width="85" prop="settlementTypeName" label="结算方式">
											</el-table-column>
											<el-table-column width="85" prop="settlementStage" label="结算账期">
											</el-table-column>
											<el-table-column width="110" prop="cooperationYears" label="合作年限(年)">
											</el-table-column>
											<el-table-column width="115" label="业务量占比(%)" align="right">
												<template slot-scope="scope">
													{{
				Number(
					scope.row
						.businessProportion ||
					0
				).toFixed(2)
			}}
												</template>
											</el-table-column>
											<el-table-column
												v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
												width="300" label="备注" align="left">
												<template slot-scope="scope">

													<span>{{ scope.row.remark }}</span>

												</template>
											</el-table-column>
										</el-table>
									</template>

								</template>

								<template v-if="allData.checkedTitleField.includes(
			'businessCase_mainSupplier'
		)
			">
									<p v-if="allData.clientBusinessVO
			.mainSupplier
			" class="t2">
										<span class="t1-8">8.4.</span>
										主要供应商情况分析
									</p>

									<div v-if="allData.clientBusinessVO
			.mainSupplier
			" class="info w65" v-html="fn(
			allData.clientBusinessVO
				.mainSupplier || ''
		)
			"></div>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'businessCase_downClient'
		)
			">
									<p v-if="allData.clientBusinessVO.downClient
			" class="t2">
										<span class="t1-8">8.5.</span>
										主要下游客户情况分析
									</p>

									<div v-if="allData.clientBusinessVO.downClient
			" class="info w65" v-html="fn(
			allData.clientBusinessVO
				.downClient || ''
		)
			"></div>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'businessCase_productIntroduction'
		)
			">
									<p v-if="allData.clientBusinessVO
			.productIntroduction
			" class="t2">
										<span class="t1-8">8.6.</span>
										企业主营产品介绍
									</p>

									<div v-if="allData.clientBusinessVO
			.productIntroduction
			" class="info w65" v-html="fn(
			allData.clientBusinessVO
				.productIntroduction || ''
		)
			"></div>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'businessCase_description'
		)
			">
									<p v-if="allData.clientBusinessVO.description
			" class="t2">
										<span class="t1-8">8.7.</span>
										企业主要生产流程/业务流程描述
									</p>

									<div v-if="allData.clientBusinessVO.description
			" class="info w65" v-html="fn(
			allData.clientBusinessVO
				.description || ''
		)
			"></div>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'businessCase_coreCompetitivenessVista'
		)
			">
									<p v-if="allData.clientBusinessVO
			.coreCompetitivenessVista
			" class="t2">
										<span class="t1-8">8.8.</span>
										主营业务核心竞争力和未来前景展望
									</p>
									<div>
										<div v-if="allData.clientBusinessVO
			.coreCompetitivenessVista
			" class="info w65" v-html="fn(
			allData.clientBusinessVO
				.coreCompetitivenessVista ||
			''
		)
			"></div>
									</div>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'businessCase_shortBoardProblems'
		)
			">
									<p v-if="allData.clientBusinessVO
			.shortBoardProblems
			" class="t2">
										<span class="t1-8">8.9.</span>
										经营短板和存在问题
									</p>
									<div>
										<div v-if="allData.clientBusinessVO
			.shortBoardProblems
			" class="info w65" v-html="fn(
			allData.clientBusinessVO
				.shortBoardProblems ||
			''
		)
			"></div>
									</div>
								</template>
							</template>
							<template v-if="allData.checkedTitleField.includes(
			'clientFinance'
		)
			">
								<p class="t1" v-if="getValues[0].value0 ||
			getIncome[0].value0 ||
			getIndicator[0].value0 ||
			allData.clientFinanceVO.financialAnalyse
			">
									<span class="t1-9">9.</span>
									主要财务信息及指标
								</p>

								<template v-if="allData.checkedTitleField.includes(
			'clientFinance_subject'
		)
			">
									<p class="t2" v-if="getValues[0].value0">
										<span class="t1-9">9.1.</span> 财务信息
									</p>
									<p class="t3" v-if="getValues[0].value0">
										<span class="t1-9-1-1 ">9.1.1</span>
										<span class="roll">资产负债表</span>
									</p>
									<div class="diytable_box diytable_weight" v-if="getValues[0].value0">
										<el-table :row-style="isRed1" class="diytable" :data="getValues" :show-header="false" border>
											<el-table-column v-for="(item, index) in Object.keys(getValues[0])" :key="index" :prop="item"
												:width="item == 'title' ? '190' : '130'" :align="item != 'title' ? 'right' : ''">
											</el-table-column>
										</el-table>
									</div>

									<p class="t3" v-if="getIncome[0].value0">
										<span class="t1-9-1-1 ">9.1.2</span>
										<span class="roll">利润表</span>

									</p>
									<div class="diytable_box diytable_weight1" v-if="getIncome[0].value0">
										<el-table v-if="!mobileInfo" :row-style="isRed1" class="diytable" :data="getIncome"
											:show-header="false" border>
											<el-table-column v-for="(item, index) in Object.keys(getIncome[0])" :key="index" :prop="item"
												:width="item == 'title' ? '360' : '130'" :align="item != 'title' ? 'right' : ''">
											</el-table-column>
										</el-table>
										<el-table v-else="mobileInfo" :row-style="isRed1" class="diytable" :data="getIncome"
											:show-header="false" border>
											<el-table-column v-for="(item, index) in Object.keys(getIncome[0])" :key="index" :prop="item"
												:width="item == 'title' ? '200' : '130'" :align="item != 'title' ? 'right' : ''">
											</el-table-column>
										</el-table>
									</div>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'clientFinance_quota'
		)
			">
									<p v-if="getIndicator[0].value0" class="t2">
										<span class="t1-9 ">9.2.</span> <span class="roll">财务指标</span>
									</p>
									<div v-if="getIndicator[0].value0" class="diytable_box allw" style="width: 45%">
										<el-table class="diytable" :row-style="isRed2" :span-method="arraySpanMethod1" :data="getIndicator"
											:show-header="false" border>
											<el-table-column v-for="(item, index) in Object.keys(getIndicator[0])" :key="index" :prop="item"
												:width="item == 'title' ? '140' : ''" :align="item != 'title' ? 'right' : ''">
											</el-table-column>
										</el-table>
									</div>
								</template>

								<template v-if="allData.checkedTitleField.includes(
			'clientFinance_analyse'
		)
			">
									<p class="t2" v-if="allData.clientFinanceVO
			.financialAnalyse ||
			allData.clientFinanceVO
				.financeNormativeName ||
			allData.clientFinanceVO
				.auditorOfficeQualificationName
			">
										<span class="t1-9 ">9.3.</span><span class="roll">财务数据分析</span>

									</p>
									<el-form-item label-width="130px" v-if="allData.clientFinanceVO
			.financeNormativeName
			" label="财务管理规范性: ">
										{{
			allData.clientFinanceVO
				.financeNormativeName
		}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.clientFinanceVO
				.auditorOfficeQualificationName
			" label="审计师事务所资质: ">
										{{
			allData.clientFinanceVO
				.auditorOfficeQualificationName
		}}
									</el-form-item>
									<el-form-item label-width="130px" class="textinfo" v-if="allData.clientFinanceVO
				.financialAnalyse && mobileInfo
			" label="财务指标分析: ">
										<div class="info1" v-html="fn(
			allData.clientFinanceVO
				.financialAnalyse || ''
		)
			"></div>
									</el-form-item>
									<el-form-item label-width="130px" v-if="allData.clientFinanceVO
			.financialAnalyse && !mobileInfo
			" label="财务指标分析: ">
										<div class=" w65" v-html="fn(
			allData.clientFinanceVO
				.financialAnalyse || ''
		)
			"></div>
									</el-form-item>
								</template>
								<template v-if="allData.checkedTitleField.includes(
			'clientFinance_attachment'
		)
			">
									<p class="t2" v-if="(allData.clientFinanceVO
			.balanceSheetAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'clientFinance_attachment_balanceSheetAttachment'
			) &&
				privacyType == 1) ||
			(allData.clientFinanceVO
				.incomeStatementAttachment &&
				privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'clientFinance_attachment_incomeStatementAttachment'
			) &&
				privacyType == 1) ||
			(allData.clientFinanceVO
				.cashFlowStatementAttachment &&
				privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'clientFinance_attachment_cashFlowStatementAttachment'
			) &&
				privacyType == 1)
			">
										<span class="t1-9">9.4.</span> 附件
									</p>
									<template v-if="(allData.clientFinanceVO.balanceSheetAttachment && privacyType == 2)
			||
			(allData.checkedTitleField.includes('clientFinance_attachment_balanceSheetAttachment') && privacyType == 1)
			">
										<el-form-item label="资产负债表:">
											<el-upload style="
															line-height: 0;
															margin-left:60px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
			.balanceSheetAttachment
			">
											</el-upload>
										</el-form-item>
									</template>
									<template v-if="(allData.clientFinanceVO
			.incomeStatementAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'clientFinance_attachment_incomeStatementAttachment'
			) &&
				privacyType == 1)
			">
										<el-form-item label="利润表:">
											<el-upload style="
															line-height: 0;
															margin-left: 60px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
			.incomeStatementAttachment
			">
											</el-upload>
										</el-form-item>
									</template>
									<template v-if="(allData.clientFinanceVO
			.cashFlowStatementAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'clientFinance_attachment_cashFlowStatementAttachment'
			) &&
				privacyType == 1)
			">
										<el-form-item label="现金流量表:">
											<el-upload style="
															line-height: 0;
															margin-left: 60px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
			.cashFlowStatementAttachment
			">
											</el-upload>
										</el-form-item>
									</template>
									<template v-if="(allData.clientFinanceVO
			.taxElseAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'clientFinance_attachment_taxElseAttachment'
			) &&
				privacyType == 1)
			">
										<el-form-item label="其他附件:">
											<el-upload style="
															line-height: 0;
															margin-left: 60px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
												:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
			.taxElseAttachment
			">
											</el-upload>
										</el-form-item>
									</template>
								</template>
							</template>

							<template v-if="allData.checkedTitleField.includes(
			'taxInfo'
		)
			">
								<p class="t1 c-3">
									<span class="t1-99">3.</span> <span class="roll">税务信息</span>
								</p>
								<div v-if="allData.otherKeyInfo && !isswbg" class="running-info c-3-body">
									<div class="add-list" v-if="allData.otherKeyInfo
			.taxDeclarationIncomeTwoAmount ||
			allData.otherKeyInfo
				.taxDeclarationIncomeOneAmount ||
			allData.otherKeyInfo
				.taxDeclarationIncomeThisAmount
			">
										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
			.taxDeclarationIncomeTwoAmount
			" label="纳税申报收入(元): ">
											{{
			allData.otherKeyInfo
				.taxDeclarationIncomeTwoYear
		}}
											年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeOneAmount
			" label="纳税申报收入(元): ">
											{{
			allData.otherKeyInfo
				.taxDeclarationIncomeOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxDeclarationIncomeThisAmount
			" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeThisYear
		}}年
											{{
				allData.otherKeyInfo
					.taxDeclarationIncomeThisYearMonth
			}}月
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationIncomeThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list" v-if="allData.otherKeyInfo
				.totalTaxPaymentTwoAmount ||
			allData.otherKeyInfo
				.totalTaxPaymentOneAmount ||
			allData.otherKeyInfo
				.totalTaxPaymentThisAmount
			">
										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
			.totalTaxPaymentTwoAmount
			" label="纳税总额(元): ">
											{{
			allData.otherKeyInfo
				.totalTaxPaymentTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.totalTaxPaymentOneAmount
			" label="纳税总额(元): ">{{
			allData.otherKeyInfo
				.totalTaxPaymentOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.totalTaxPaymentThisAmount
			" label="纳税总额(元): ">{{
			allData.otherKeyInfo
				.totalTaxPaymentThisYear
		}}年
											{{
				allData.otherKeyInfo
					.totalTaxPaymentThisYearMonth
			}}月
											{{
				formatCurrency(
					allData.otherKeyInfo
						.totalTaxPaymentThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list" v-if="allData.otherKeyInfo
				.taxDeclarationProfitTwoAmount ||
			allData.otherKeyInfo
				.taxDeclarationProfitOneAmount ||
			allData.otherKeyInfo
				.taxDeclarationProfitThisAmount
			">
										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
			.taxDeclarationProfitTwoAmount
			" label="纳税申报利润(元): ">
											{{
			allData.otherKeyInfo
				.taxDeclarationProfitTwoYear
		}}
											年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationProfitTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxDeclarationProfitOneAmount
			" label="纳税申报利润(元):">{{
			allData.otherKeyInfo
				.taxDeclarationProfitOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationProfitOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxDeclarationProfitThisAmount
			" label="纳税申报利润(元):">{{
			allData.otherKeyInfo
				.taxDeclarationProfitThisYear
		}}年
											{{
				allData.otherKeyInfo
					.taxDeclarationProfitThisYearMonth
			}}月
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxDeclarationProfitThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list" v-if="allData.otherKeyInfo
				.taxAddedTwoAmount ||
			allData.otherKeyInfo
				.taxAddedOneAmount ||
			allData.otherKeyInfo
				.taxAddedThisAmount
			">
										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
			.taxAddedTwoAmount
			" label="增值税(元): ">
											{{
			allData.otherKeyInfo
				.taxAddedTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxAddedTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxAddedOneAmount
			" label="增值税(元):">{{
			allData.otherKeyInfo
				.taxAddedOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxAddedOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxAddedThisAmount
			" label="增值税(元):">{{
			allData.otherKeyInfo
				.taxAddedThisYear
		}}年
											{{
				allData.otherKeyInfo
					.taxAddedThisYearMonth
			}}月
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxAddedThisAmount
				)
			}}
										</el-form-item>
									</div>

									<div class="add-list" v-if="allData.otherKeyInfo
				.taxIncomeTwoAmount ||
			allData.otherKeyInfo
				.taxIncomeOneAmount ||
			allData.otherKeyInfo
				.taxIncomeThisAmount
			">
										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
			.taxIncomeTwoAmount
			" label="所得税(元): ">
											{{
			allData.otherKeyInfo
				.taxIncomeTwoYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxIncomeTwoAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxIncomeOneAmount
			" label="所得税(元):">{{
			allData.otherKeyInfo
				.taxIncomeOneYear
		}}年度
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxIncomeOneAmount
				)
			}}
										</el-form-item>

										<el-form-item label-width="125px" v-if="allData.otherKeyInfo
				.taxIncomeThisAmount
			" label="所得税(元):">{{
			allData.otherKeyInfo
				.taxIncomeThisYear
		}}年
											{{
				allData.otherKeyInfo
					.taxIncomeThisYearMonth
			}}月
											{{
				formatCurrency(
					allData.otherKeyInfo
						.taxIncomeThisAmount
				)
			}}
										</el-form-item>
									</div>


								</div>
								<div v-if="isswbg" class="running-info c-3-body">
									<p class="t2">
										<span class="t1-99">9.2.</span> <span>开票及税务信息</span>
									</p>
									<div v-if="mobileInfo">
										<el-form-item label-width="201px" label="纳税人种类: ">
											{{ swinfo.nsrlx }}
										</el-form-item>
										<el-form-item label-width="201px" label="纳税信用等级: ">
											{{ swinfo.credit_level_prevyr }}
										</el-form-item>
										<el-form-item label-width="201px" label="最早开票时长（月）: ">
											{{ fpinfo.fp_minbilldttd_tmspan }}
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月断票月数（月）: ">
											{{ fpinfo.fp_prev12mths_mths_wo_fp }}
										</el-form-item>
										<el-form-item style="width:100%" label-width="280px" label="近12个月（含2月）最长断票月数（月）: ">
											{{ fpinfo.fp_prev12mths_max_mthsinseries_wo_fp }}
										</el-form-item>
										<el-form-item label-width="201px" label="近3个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev3mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近6个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev6mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev12mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近24个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev24mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="历史有无欠税记录: ">
											{{ swinfo.cnt_of_hist_taxarrearcase && swinfo.cnt_of_hist_taxarrearcase > 0 ? '有' : '无' }}
										</el-form-item>
										<el-form-item label-width="201px" label="当前欠税: ">
											{{ swinfo.cur_owing_taxes_detail && swinfo.cur_owing_taxes_detail.length !== 0 ? '有' : '无' }}
											<span v-if="swinfo.cur_owing_taxes_detail && swinfo.cur_owing_taxes_detail.length !== 0"
												style="color:blue;cursor: pointer;" @click="qsjl = true">详情 ></span>
										</el-form-item>
										<el-form-item label-width="201px" label="税务处罚: ">
											{{ swinfo.tax_penalty && swinfo.tax_penalty.length !== 0 ? '有' : '无' }} <span
												v-if="swinfo.tax_penalty && swinfo.tax_penalty.length !== 0" style="color:blue;cursor: pointer;"
												@click="swcfjl = true">详情
												></span>
										</el-form-item>
										<el-form-item label-width="201px" label="滞纳金: ">
											{{ swinfo.overdue_fine && swinfo.overdue_fine.length !== 0 ? '有' : '无' }} <span
												v-if="swinfo.overdue_fine && swinfo.overdue_fine.length !== 0"
												style="color:blue;cursor: pointer;" @click="zljjl = true">详情
												></span>
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月纳税总额(元): ">
											{{ formatCurrency(swinfo.tax_ppev12mths_tax_paid) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月所得税已缴税额(元): ">
											{{ formatCurrency(swinfo.las_12m_cit_tax_paid) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近24个月纳税总额(元): ">
											{{ formatCurrency(swinfo.tax_ppev24mths_tax_paid) }}
										</el-form-item>
										<el-form-item style="width:100%" label-width="201px" label="近24个月所得税已缴税额(元): ">
											{{ formatCurrency(swinfo.las_24m_cit_tax_paid) }}
										</el-form-item>
										<div class="add-list" v-if="taxAccountingData
			.taxDeclarationIncomeTwoAmount ||
			taxAccountingData
				.taxDeclarationIncomeOneAmount ||
			taxAccountingData.taxDeclarationIncomeThisAmount
			">

											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxDeclarationIncomeTwoAmount
			" label="纳税申报收入(元): ">
												{{
			taxAccountingData
				.taxDeclarationIncomeTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationIncomeTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxDeclarationIncomeOneAmount
			" label="纳税申报收入(元): ">{{
			taxAccountingData
				.taxDeclarationIncomeOneYear
		}}年度

												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationIncomeOneAmount
				)
			}}
											</el-form-item>
											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxDeclarationIncomeThisAmount
			" label="纳税申报收入(元): ">{{
			taxAccountingData
				.taxDeclarationIncomeThisYear
		}}年
												{{
				taxAccountingData
					.taxDeclarationIncomeThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationIncomeThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.totalTaxPaymentTwoAmount ||
			taxAccountingData
				.totalTaxPaymentOneAmount ||
			taxAccountingData
				.totalTaxPaymentThisAmount
			">
											<el-form-item label-width="130px" v-if="taxAccountingData
			.totalTaxPaymentTwoAmount
			" label="纳税总额(元): ">
												{{
			taxAccountingData
				.totalTaxPaymentTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.totalTaxPaymentTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.totalTaxPaymentOneAmount
			" label="纳税总额(元): ">{{
			taxAccountingData
				.totalTaxPaymentOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.totalTaxPaymentOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.totalTaxPaymentThisAmount
			" label="纳税总额(元): ">{{
			taxAccountingData
				.totalTaxPaymentThisYear
		}}年
												{{
				taxAccountingData
					.totalTaxPaymentThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.totalTaxPaymentThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.taxDeclarationProfitOneAmount ||
			taxAccountingData
				.taxDeclarationProfitThisAmount
			">

											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxDeclarationProfitOneAmount
			" label="纳税申报利润(元):">{{
			taxAccountingData
				.taxDeclarationProfitOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationProfitOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxDeclarationProfitThisAmount
			" label="纳税申报利润(元):">{{
			taxAccountingData
				.taxDeclarationProfitThisYear
		}}年
												{{
				taxAccountingData
					.taxDeclarationProfitThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationProfitThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.taxAddedTwoAmount ||
			taxAccountingData
				.taxAddedOneAmount ||
			taxAccountingData
				.taxAddedThisAmount
			">
											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxAddedTwoAmount
			" label="增值税(元): ">
												{{
			taxAccountingData
				.taxAddedTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxAddedTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxAddedOneAmount
			" label="增值税(元):">{{
			taxAccountingData
				.taxAddedOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxAddedOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxAddedThisAmount
			" label="增值税(元):">{{
			taxAccountingData
				.taxAddedThisYear
		}}年
												{{
				taxAccountingData
					.taxAddedThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxAddedThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.taxIncomeTwoAmount ||
			taxAccountingData
				.taxIncomeOneAmount ||
			taxAccountingData
				.taxIncomeThisAmount
			">
											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxIncomeTwoAmount
			" label="所得税(元): ">
												{{
			taxAccountingData
				.taxIncomeTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxIncomeTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxIncomeOneAmount
			" label="所得税(元):">{{
			taxAccountingData
				.taxIncomeOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxIncomeOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxIncomeThisAmount
			" label="所得税(元):">{{
			taxAccountingData
				.taxIncomeThisYear
		}}年
												{{
				taxAccountingData
					.taxIncomeThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxIncomeThisAmount
				)
			}}
											</el-form-item>
										</div>

									</div>
									<div v-else class="running-info" style="width: 100%;">
										<el-form-item label-width="201px" label="纳税人种类: ">
											{{ swinfo.nsrlx }}
										</el-form-item>
										<el-form-item label-width="201px" label="纳税信用等级: ">
											{{ swinfo.credit_level_prevyr }}
										</el-form-item>
										<el-form-item label-width="201px" label="最早开票时长（月）: ">
											{{ fpinfo.fp_minbilldttd_tmspan }}
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月断票月数（月）: ">
											{{ fpinfo.fp_prev12mths_mths_wo_fp }}
										</el-form-item>
										<el-form-item style="width:100%" label-width="280px" label="近12个月（含2月）最长断票月数（月）: ">
											{{ fpinfo.fp_prev12mths_max_mthsinseries_wo_fp }}
										</el-form-item>
										<el-form-item label-width="201px" label="近3个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev3mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近6个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev6mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev12mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近24个月开票金额(元): ">
											{{ formatCurrency(fpinfo.fp_prev24mths_tot_amt) }}
										</el-form-item>
										<el-form-item label-width="201px" label="历史有无欠税记录: ">
											{{ swinfo.cnt_of_hist_taxarrearcase && swinfo.cnt_of_hist_taxarrearcase > 0 ? '有' : '无' }}
										</el-form-item>
										<el-form-item label-width="201px" label="当前欠税: ">
											{{ swinfo.cur_owing_taxes_detail && swinfo.cur_owing_taxes_detail.length !== 0 ? '有' : '无' }}
											<span v-if="swinfo.cur_owing_taxes_detail && swinfo.cur_owing_taxes_detail.length !== 0"
												style="color:blue;cursor: pointer;" @click="qsjl = true">详情 ></span>
										</el-form-item>
										<el-form-item label-width="201px" label="税务处罚: ">
											{{ swinfo.tax_penalty && swinfo.tax_penalty.length !== 0 ? '有' : '无' }} <span
												v-if="swinfo.tax_penalty && swinfo.tax_penalty.length !== 0" style="color:blue;cursor: pointer;"
												@click="swcfjl = true">详情
												></span>
										</el-form-item>
										<el-form-item label-width="201px" label="滞纳金: ">
											{{ swinfo.overdue_fine && swinfo.overdue_fine.length !== 0 ? '有' : '无' }} <span
												v-if="swinfo.overdue_fine && swinfo.overdue_fine.length !== 0"
												style="color:blue;cursor: pointer;" @click="zljjl = true">详情
												></span>
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月纳税总额(元): ">
											{{ formatCurrency(swinfo.tax_ppev12mths_tax_paid) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近12个月所得税已缴税额(元): ">
											{{ formatCurrency(swinfo.las_12m_cit_tax_paid) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近24个月纳税总额(元): ">
											{{ formatCurrency(swinfo.tax_ppev24mths_tax_paid) }}
										</el-form-item>
										<el-form-item label-width="201px" label="近24个月所得税已缴税额(元): ">
											{{ formatCurrency(swinfo.las_24m_cit_tax_paid) }}
										</el-form-item>
										<div class="add-list" v-if="taxAccountingData
			.taxDeclarationIncomeTwoAmount ||
			taxAccountingData
				.taxDeclarationIncomeOneAmount ||
			taxAccountingData.taxDeclarationIncomeThisAmount
			">

											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxDeclarationIncomeTwoAmount
			" label="纳税申报收入(元): ">
												{{
			taxAccountingData
				.taxDeclarationIncomeTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationIncomeTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxDeclarationIncomeOneAmount
			" label="纳税申报收入(元): ">{{
			taxAccountingData
				.taxDeclarationIncomeOneYear
		}}年度

												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationIncomeOneAmount
				)
			}}
											</el-form-item>
											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxDeclarationIncomeThisAmount
			" label="纳税申报收入(元): ">{{
			taxAccountingData
				.taxDeclarationIncomeThisYear
		}}年
												{{
				taxAccountingData
					.taxDeclarationIncomeThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationIncomeThisAmount
				)
			}}
											</el-form-item>
										</div>


										<div class="add-list" v-if="taxAccountingData
				.totalTaxPaymentTwoAmount ||
			taxAccountingData
				.totalTaxPaymentOneAmount ||
			taxAccountingData
				.totalTaxPaymentThisAmount
			">
											<el-form-item label-width="130px" v-if="taxAccountingData
			.totalTaxPaymentTwoAmount
			" label="纳税总额(元): ">
												{{
			taxAccountingData
				.totalTaxPaymentTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.totalTaxPaymentTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.totalTaxPaymentOneAmount
			" label="纳税总额(元): ">{{
			taxAccountingData
				.totalTaxPaymentOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.totalTaxPaymentOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.totalTaxPaymentThisAmount
			" label="纳税总额(元): ">{{
			taxAccountingData
				.totalTaxPaymentThisYear
		}}年
												{{
				taxAccountingData
					.totalTaxPaymentThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.totalTaxPaymentThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.taxDeclarationProfitOneAmount ||
			taxAccountingData
				.taxDeclarationProfitThisAmount
			">

											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxDeclarationProfitOneAmount
			" label="纳税申报利润(元):">{{
			taxAccountingData
				.taxDeclarationProfitOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationProfitOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxDeclarationProfitThisAmount
			" label="纳税申报利润(元):">{{
			taxAccountingData
				.taxDeclarationProfitThisYear
		}}年
												{{
				taxAccountingData
					.taxDeclarationProfitThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxDeclarationProfitThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.taxAddedTwoAmount ||
			taxAccountingData
				.taxAddedOneAmount ||
			taxAccountingData
				.taxAddedThisAmount
			">
											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxAddedTwoAmount
			" label="增值税(元): ">
												{{
			taxAccountingData
				.taxAddedTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxAddedTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxAddedOneAmount
			" label="增值税(元):">{{
			taxAccountingData
				.taxAddedOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxAddedOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxAddedThisAmount
			" label="增值税(元):">{{
			taxAccountingData
				.taxAddedThisYear
		}}年
												{{
				taxAccountingData
					.taxAddedThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxAddedThisAmount
				)
			}}
											</el-form-item>
										</div>

										<div class="add-list" v-if="taxAccountingData
				.taxIncomeTwoAmount ||
			taxAccountingData
				.taxIncomeOneAmount ||
			taxAccountingData
				.taxIncomeThisAmount
			">
											<el-form-item label-width="130px" v-if="taxAccountingData
			.taxIncomeTwoAmount
			" label="所得税(元): ">
												{{
			taxAccountingData
				.taxIncomeTwoYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxIncomeTwoAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxIncomeOneAmount
			" label="所得税(元):">{{
			taxAccountingData
				.taxIncomeOneYear
		}}年度
												{{
				formatCurrency(
					taxAccountingData
						.taxIncomeOneAmount
				)
			}}
											</el-form-item>

											<el-form-item label-width="130px" v-if="taxAccountingData
				.taxIncomeThisAmount
			" label="所得税(元):">{{
			taxAccountingData
				.taxIncomeThisYear
		}}年
												{{
				taxAccountingData
					.taxIncomeThisYearMonthName
			}}
												{{
				formatCurrency(
					taxAccountingData
						.taxIncomeThisAmount
				)
			}}
											</el-form-item>
										</div>
									</div>

								</div>
								<p v-if="swinfo && swinfo.near_3years_tax_amount1" class="t2">
									<span class="t1-99">9.2.</span> <span>近三年纳税信息</span>
								</p>
								<el-table v-if="swinfo && swinfo.near_3years_tax_amount1" :summary-method="getSummaries1" show-summary
									:header-cell-style="{
			background: '#ecf5ff',
		}" :data="swinfo.near_3years_tax_amount1" border style="width: fit-content">

									<el-table-column width="50" prop="month" label="月份"></el-table-column>
									<el-table-column width="130" prop=""
										:label="swinfo.near_3years_tax_amount[swinfo.near_3years_tax_amount.length - 1].year + '(元)'">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.money_count[2]) }}
										</template>
									</el-table-column>
									<el-table-column width="130"
										:label="swinfo.near_3years_tax_amount[swinfo.near_3years_tax_amount.length - 1].year - 1 + '(元)'">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.money_count[1]) }}
										</template>
									</el-table-column>
									<el-table-column width="130"
										:label="swinfo.near_3years_tax_amount[swinfo.near_3years_tax_amount.length - 1].year - 2 + '(元)'">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.money_count[0]) }}
										</template>
									</el-table-column>
								</el-table>
								<p v-if="fpinfo && fpinfo.near_3years_invoice1" class="t2">
									<span class="t1-99">9.2.</span> <span>近三年开票信息</span>
								</p>
								<el-table v-if="fpinfo && fpinfo.near_3years_invoice1" :summary-method="getSummaries2" show-summary
									:header-cell-style="{
			background: '#ecf5ff',
		}" :data="fpinfo.near_3years_invoice1" border style="width: fit-content">

									<el-table-column width="50" prop="month" label="月份"></el-table-column>
									<el-table-column width="130" prop=""
										:label="fpinfo.near_3years_invoice[fpinfo.near_3years_invoice.length - 1].year + '(元)'">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.money_count[2]) }}
										</template>
									</el-table-column>
									<el-table-column width="130"
										:label="fpinfo.near_3years_invoice[fpinfo.near_3years_invoice.length - 1].year - 1 + '(元)'">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.money_count[1]) }}
										</template>
									</el-table-column>
									<el-table-column width="130"
										:label="fpinfo.near_3years_invoice[fpinfo.near_3years_invoice.length - 1].year - 2 + '(元)'">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.money_count[0]) }}
										</template>
									</el-table-column>
								</el-table>
								<p v-if="fpinfo.top10_customer_info" class="t2">
									<span class="t1-99">9.2.</span> <span>近12个月前十大开票客户（不含本月）</span>
								</p>
								<el-table v-if="fpinfo.top10_customer_info" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="fpinfo.top10_customer_info" border show-summary :summary-method="getSummaries3" style="width: fit-content">
									<el-table-column type="index" :index="indexMethod" width="50" label="排名"></el-table-column>
									<el-table-column :width="flexColumnWidth(fpinfo.top10_customer_info, 'name', 260, 80)" prop="name"
										label="客户名称"></el-table-column>
									<el-table-column width="130" label="销售额(元)">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.amount) }}
										</template>
									</el-table-column>
									<el-table-column width="100" prop="amt_proportion" label="金额占比(%)"></el-table-column>
									<el-table-column width="100" label="是否关联方">
										<template slot-scope="scope">
											{{ scope.row.is_related_party ? '是' : '否' }}
										</template>
									</el-table-column>

								</el-table>
								<p v-if="fpinfo.top10_supplier_info" class="t2">
									<span class="t1-99">9.2.</span> <span>近12个月前十大开票供应商（不含本月）</span>
								</p>
								<el-table v-if="fpinfo.top10_supplier_info" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="fpinfo.top10_supplier_info" border show-summary :summary-method="getSummaries3" style="width: fit-content">

									<el-table-column type="index" :index="indexMethod" width="50" label="排名"></el-table-column>

									<el-table-column :width="flexColumnWidth(fpinfo.top10_supplier_info, 'name', 260, 80)" prop="name"
										label="供应商名称	"></el-table-column>
									<el-table-column width="130" label="采购额(元)">
										<template slot-scope="scope">
											{{ formatCurrency(scope.row.amount) }}
										</template>
									</el-table-column>
									<el-table-column width="100" prop="amt_proportion" label="金额占比(%)"></el-table-column>
									<el-table-column width="100" label="是否关联方">
										<template slot-scope="scope">
											{{ scope.row.is_related_party ? '是' : '否' }}
										</template>
									</el-table-column>

								</el-table>
								<template v-if="(allData.otherKeyInfo
			.taxPaymentCertificateAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'taxInfo_taxPaymentCertificateAttachment'
			) &&
				privacyType == 1)
			">
									<el-form-item class="tywidth" style="width: 100%" label="完税证明:">
										<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.otherKeyInfo
			.taxPaymentCertificateAttachment
			">
										</el-upload>
									</el-form-item>
								</template>
								<template v-if="(allData.otherKeyInfo
			.vatReturnAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'taxInfo_vatReturnAttachment'
			) &&
				privacyType == 1)
			">
									<el-form-item class="tywidth" style="width: 100%" label="增值税申报表:">
										<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.otherKeyInfo
			.vatReturnAttachment
			">
										</el-upload>
									</el-form-item>
								</template>
								<template v-if="(allData.otherKeyInfo
			.incomeTaxReturnAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'taxInfo_incomeTaxReturnAttachment'
			) &&
				privacyType == 1)
			">
									<el-form-item class="tywidth" style="width: 100%" label="所得税申报表:">
										<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.otherKeyInfo
			.incomeTaxReturnAttachment
			">
										</el-upload>
									</el-form-item>
								</template>
								<template v-if="(allData.otherKeyInfo
			.otherAttachment &&
			privacyType == 2) ||
			(allData.checkedTitleField.includes(
				'taxInfo_otherAttachment'
			) &&
				privacyType == 1)
			">
									<el-form-item class="tywidth" style="width: 100%" label="其他附件:">
										<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
											:on-preview="handelOnChange" multiple :file-list="allData.otherKeyInfo
			.otherAttachment
			">
										</el-upload>
									</el-form-item>
								</template>
							</template>
							<template v-if="allData.checkedTitleField.includes(
			'bankFlow'
		)
			">
								<p v-if="allData.bankStatement ||
			allData.bankFlowDownstreams.length ||
			allData.bankFlowUpstreams.length ||
			allData.flowIns.length ||
			allData.flowOuts.length ||
			allData.flowInAmounts.length ||
			allData.flowOutAmounts.length
			" class="t1">
									<span class="t1-10">10.</span>
									<span class="roll">银行流水</span>

								</p>
								<template v-if="allData.checkedTitleField.includes(
			'bankFlow_flowInfo'
		)
			">
									<p class="t2" v-if="allData.bankStatement &&
			allData.bankStatement.startTime
			">
										<span class="t1-10">10.1.</span>
										流水信息
									</p>
									<div v-if="mobileInfo" class="running-info">
										<div style="display: flex;">
											<el-form-item style="width:50%" v-if="allData.bankStatement &&
			allData.bankStatement.startTime
			" label="开始年月: ">
												{{
			allData.bankStatement.startTime.split('-')[0] + '-' +
			allData.bankStatement.startTime.split('-')[1]

		}}
											</el-form-item>
											<el-form-item style="width:50%" v-if="allData.bankStatement &&
			allData.bankStatement.endTime
			" label="截止年月: ">
												{{

			allData.bankStatement.endTime.split('-')[0] + '-' + allData.bankStatement.endTime.split('-')[1]
		}}
											</el-form-item>

										</div>

										<el-form-item label-width='110px' v-if="allData.bankStatement &&
			allData.bankStatement.monthNum
			" label="区间月份数: ">
											{{
			allData.bankStatement &&
			allData.bankStatement.monthNum
		}}
											<span v-if="allData.bankStatement &&
			allData.bankStatement
				.monthNum
			">月</span>
										</el-form-item>
										<el-form-item label-width='110px' v-if="allData.bankStatement &&
			allData.bankStatement.bankName
			" label="流水对应银行: ">{{
			allData.bankStatement &&
			allData.bankStatement.bankName
		}}
										</el-form-item>
										<el-form-item label-width='110px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.totalIncome
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">合计总流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'合计总流入：统计所有贷方金额的总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.totalIncome
			)
		}}元
										</el-form-item>
										<el-form-item label-width='110px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.totalExpend
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">合计总流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'合计总流出：统计所有借方金额的总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.totalExpend
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='110px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.sameNameTransferIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">同名划入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'同名划入：统计贷方交易对手是借款人本人的流水的贷方金额总和，交易对手与客户名称同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.sameNameTransferIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='110px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.sameNameTransferOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">同名划出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'同名划出：统计借方交易对手是借款人本人的流水的借方金额总和，交易对手与客户名称同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.sameNameTransferOut
			)
		}}
											元
										</el-form-item>
										<el-form-item v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.relatedFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">关联或疑似关联流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'关联或疑似关联流入：统计贷方交易对手是借款人法人、股东、关联公司的流水的贷方金额总和，</br>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.relatedFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.relatedFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">关联或疑似关联流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'关联或疑似关联流出：统计借方交易对手是借款人法人、股东、关联公司的流水的的借方金额总和，</br>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.relatedFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.financialOrganizationsFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">金融机构流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'金融机构流入：贷方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的贷方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.financialOrganizationsFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.financialOrganizationsFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">金融机构流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'金融机构流出：借方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的借方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.financialOrganizationsFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.privateToPublicFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">私转公流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'私转公流入：贷方交易对手是个人的流水统计，首先排除公司关键字，然后判断字符数介于4个字符到8个字符'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.privateToPublicFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.publicToPrivateFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">公转私流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'公转私流出：借方交易对手是个人的流水统计减去摘要显示含工资、报销的流水，首先排除公司关键字，然后判断字符数介于4个字符到8个字符'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.publicToPrivateFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流入 = 合计总流入 - 同名划入 - 关联或疑似关联流入 - 金融机构流入 - 私转公流入 + 借款人法人、股东贷方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流出 = 合计总流出 - 同名划出 - 关联或疑似关联流出 - 金融机构流出 - 公转私流出 + 借款人法人、股东借方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowInRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流入占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流入占比 = 经营性流入/合计总流入'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowInRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowOutRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流出占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流出占比 = 经营性流出/合计总流出'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowOutRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.privateToPublicRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">私转公占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'私转公占比 = 私转公流入/合计总流入'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.privateToPublicRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width='135px' v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.publicToPrivateRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">公转私占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'公转私占比 = 公转私流出/合计总流出'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.publicToPrivateRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width="150px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.cycleAverageDailyBalance
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">统计周期日均余额
												<el-tooltip effect="dark" placement="right">
													<span v-html="'统计周期日均余额：统计周期日均余额。算法：统计每一天的余额/统计周期天数'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.cycleAverageDailyBalance
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="150px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.monthAverageDailyBalance
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">最近月日均余额
												<el-tooltip effect="dark" placement="right">
													<span v-html="'最近月日均余额，算法同周期日均余额，只是取数为最近一个月'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.monthAverageDailyBalance
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.cycleAverageDailyBalanceRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">统计周期日均余额/日均收入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'统计周期日均余额/日均收入=日均余额/（上一年度主营业务收入/365）'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.cycleAverageDailyBalanceRate
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.monthAverageDailyBalanceRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">最近月日均余额/日均收入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'最近月日均余额/日均收入=日均余额/（上一年度主营业务收入/365）'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.monthAverageDailyBalanceRate
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.cyclicalDailyCashRatio
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">统计周期日均现金比率(%)
												<el-tooltip effect="dark" placement="right">
													<span v-html="'统计周期日均余额(元)/最近月流动负债(取今年最近月数据，今年最近月没有数据，则取上年度12月数据)*100'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.cyclicalDailyCashRatio
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.dailyCashRatioInTheLatestMonth
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">最近月日均现金比率(%)
												<el-tooltip effect="dark" placement="right">
													<span v-html="'最近月日均余额(元)/最近月流动负债(取今年最近月数据，今年最近月没有数据，则取上年度12月数据)*100 '
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.dailyCashRatioInTheLatestMonth
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.loanRecordFlag
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">是否有小额贷款公司往来记录

												<el-tooltip effect="dark" placement="right">
													<span v-html="'是否有小额贷款公司往来记录:交易对手是否包含小额贷款、小贷的银行流水记录'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:
											</span>
											{{
			allData.bankStatement
				.loanRecordFlag
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement && allData.bankStatement.otherRemark && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="对账单其他关键信息补充说明: ">
											<div class="info1" v-html="fn(
			allData.bankStatement.otherRemark ||
			''
		)
			"></div>
										</el-form-item>
										<el-form-item label-width="120px" v-if="allData.bankStatement && allData.bankStatement.bankFlowAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="银行流水附件:">
											<template>
												<div>
													<div v-for="(
																urlitem, urlindex
															) in allData.bankStatement.bankFlowAttachment" :key="urlindex" style="color: blue;cursor: pointer;"
														@click="handelOnChange(urlitem)">{{
			urlitem.name }}</div>
												</div>
											</template>
										</el-form-item>
									</div>

									<div v-if="!mobileInfo" class="running-info">

										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			allData.bankStatement.startTime
			" label="流水开始年月: ">
											{{
			allData.bankStatement.startTime.split('-')[0] + '-' +
			allData.bankStatement.startTime.split('-')[1]

		}}
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			allData.bankStatement.endTime
			" label="流水截止年月: ">
											{{

			allData.bankStatement.endTime.split('-')[0] + '-' + allData.bankStatement.endTime.split('-')[1]
		}}
										</el-form-item>


										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			allData.bankStatement.monthNum
			" label="流水区间月份数: ">
											{{
			allData.bankStatement &&
			allData.bankStatement.monthNum
		}}
											<span v-if="allData.bankStatement &&
			allData.bankStatement
				.monthNum
			">月</span>
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			allData.bankStatement.bankName
			" label="流水对应银行: ">{{
			allData.bankStatement &&
			allData.bankStatement.bankName
		}}
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.totalIncome
			)
			">
											<span style="
				font-weight: 400;
				position: relative;
				z-index: 1;
			" slot="label">合计总流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'合计总流入：统计所有贷方金额的总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.totalIncome
			)
		}}元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.totalExpend
			)
			">
											<span style="
				font-weight: 400;
				position: relative;
				z-index: 1;
			" slot="label">合计总流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'合计总流出：统计所有借方金额的总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.totalExpend
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.sameNameTransferIn
			)
			">
											<span style="
				font-weight: 400;
				position: relative;
				z-index: 1;
			" slot="label">同名划入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'同名划入：统计贷方交易对手是借款人本人的流水的贷方金额总和，交易对手与客户名称同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.sameNameTransferIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.sameNameTransferOut
			)
			">
											<span style="
				font-weight: 400;
				position: relative;
				z-index: 1;
			" slot="label">同名划出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'同名划出：统计借方交易对手是借款人本人的流水的借方金额总和，交易对手与客户名称同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.sameNameTransferOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.relatedFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">关联或疑似关联流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'关联或疑似关联流入：统计贷方交易对手是借款人法人、股东、关联公司的流水的贷方金额总和，</br>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.relatedFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.relatedFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">关联或疑似关联流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'关联或疑似关联流出：统计借方交易对手是借款人法人、股东、关联公司的流水的的借方金额总和，</br>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.relatedFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.financialOrganizationsFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">金融机构流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'金融机构流入：贷方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的贷方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.financialOrganizationsFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.financialOrganizationsFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">金融机构流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'金融机构流出：借方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的借方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.financialOrganizationsFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.privateToPublicFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">私转公流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'私转公流入：贷方交易对手是个人的流水统计，首先排除公司关键字，然后判断字符数介于4个字符到8个字符'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.privateToPublicFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.publicToPrivateFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">公转私流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'公转私流出：借方交易对手是个人的流水统计减去摘要显示含工资、报销的流水，首先排除公司关键字，然后判断字符数介于4个字符到8个字符'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.publicToPrivateFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowIn
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流入 = 合计总流入 - 同名划入 - 关联或疑似关联流入 - 金融机构流入 - 私转公流入 + 借款人法人、股东贷方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowIn
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowOut
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流出
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流出 = 合计总流出 - 同名划出 - 关联或疑似关联流出 - 金融机构流出 - 公转私流出 + 借款人法人、股东借方金额总和'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowOut
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowInRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流入占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流入占比 = 经营性流入/合计总流入'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowInRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.operationalFlowOutRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">经营性流出占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'经营性流出占比 = 经营性流出/合计总流出'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.operationalFlowOutRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.privateToPublicRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">私转公占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'私转公占比 = 私转公流入/合计总流入'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.privateToPublicRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.publicToPrivateRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">公转私占比
												<el-tooltip effect="dark" placement="right">
													<span v-html="'公转私占比 = 公转私流出/合计总流出'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.publicToPrivateRate
			)
		}}
											%
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.cycleAverageDailyBalance
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">统计周期日均余额
												<el-tooltip effect="dark" placement="right">
													<span v-html="'统计周期日均余额：统计周期日均余额。算法：统计每一天的余额/统计周期天数'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.cycleAverageDailyBalance
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="165px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.monthAverageDailyBalance
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">最近月日均余额
												<el-tooltip effect="dark" placement="right">
													<span v-html="'最近月日均余额，算法同周期日均余额，只是取数为最近一个月'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.monthAverageDailyBalance
			)
		}}
											元
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.cycleAverageDailyBalanceRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">统计周期日均余额/日均收入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'统计周期日均余额/日均收入=日均余额/（上一年度主营业务收入/365）'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.cycleAverageDailyBalanceRate
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.monthAverageDailyBalanceRate
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">最近月日均余额/日均收入
												<el-tooltip effect="dark" placement="right">
													<span v-html="'最近月日均余额/日均收入=日均余额/（上一年度主营业务收入/365）'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.monthAverageDailyBalanceRate
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.cyclicalDailyCashRatio
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">统计周期日均现金比率(%)
												<el-tooltip effect="dark" placement="right">
													<span v-html="'统计周期日均余额(元)/最近月流动负债(取今年最近月数据，今年最近月没有数据，则取上年度12月数据)*100'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.cyclicalDailyCashRatio
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.dailyCashRatioInTheLatestMonth
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">最近月日均现金比率(%)
												<el-tooltip effect="dark" placement="right">
													<span v-html="'最近月日均余额(元)/最近月流动负债(取今年最近月数据，今年最近月没有数据，则取上年度12月数据)*100 '
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
											{{
			formatCurrency(
				allData.bankStatement
					.dailyCashRatioInTheLatestMonth
			)
		}}
										</el-form-item>
										<el-form-item label-width="220px" v-if="allData.bankStatement &&
			isEmpty(
				allData.bankStatement
					.loanRecordFlag
			)
			">
											<span style="
															font-weight: 400;
															position: relative;
															z-index: 1;
														" slot="label">是否有小额贷款公司往来记录

												<el-tooltip effect="dark" placement="right">
													<span v-html="'是否有小额贷款公司往来记录:交易对手是否包含小额贷款、小贷的银行流水记录'
			" slot="content"></span>
													<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:
											</span>
											{{
			allData.bankStatement
				.loanRecordFlag
		}}
										</el-form-item>
										<div style="width: 100%;">
											<el-form-item label-width="220px" v-if="allData.bankStatement && allData.bankStatement.otherRemark && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="对账单其他关键信息补充说明: ">
												<div class="w65">{{ fn(allData.bankStatement.otherRemark) }}</div>
											</el-form-item>
											<el-form-item label-width="220px" v-if="allData.bankStatement && allData.bankStatement.bankFlowAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
			.cooperationPresidentType == 2" label="银行流水附件:">
												<template>
													<div v-for="(
																urlitem, urlindex1
															) in allData.bankStatement.bankFlowAttachment" :key="urlindex1" style="color: blue;cursor: pointer;"
														@click="handelOnChange(urlitem)">{{
			urlitem.name }}</div>
												</template>
											</el-form-item>
										</div>
									</div>
								</template>
								<p v-if="allData.bankFlowDownstreams.length &&
			!isShowRunning
			" class="t2">
									<span class="t1-10">10.2.</span>
									银行流水主要下游客户
								</p>
								<el-table v-if="allData.bankFlowDownstreams.length &&
			!isShowRunning
			" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.bankFlowDownstreams" border style="width: 100%">
									<el-table-column type="index" width="50" label="序号">
									</el-table-column>
									<el-table-column prop="debitClientName" label="客户名称">
									</el-table-column>
									<el-table-column prop="amount" align="right" label="金额(万元/人民币)">
										<template slot-scope="scope">
											{{
			formatCurrency(scope.row.amount)
		}}
										</template>
									</el-table-column>
									<el-table-column align="right" label="占比(%)">
										<template slot-scope="scope">
											{{
				Number(
					scope.row.ratio || 0
				).toFixed(2)
			}}
										</template>
									</el-table-column>
									<el-table-column prop="clientNatureName" label="客户性质">
									</el-table-column>
								</el-table>
								<p v-if="allData.bankFlowUpstreams.length &&
			!isShowRunning
			" class="t2">
									<span class="t1-10">10.3.</span>
									银行流水主要上游客户
								</p>
								<el-table v-if="allData.bankFlowUpstreams.length &&
			!isShowRunning
			" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.bankFlowUpstreams" border style="width: 100%">
									<el-table-column type="index" width="50" label="序号">
									</el-table-column>
									<el-table-column prop="creditClientName" label="供应商名称">
									</el-table-column>
									<el-table-column label="金额(万元/人民币)" align="right">
										<template slot-scope="scope">
											{{
			formatCurrency(scope.row.amount)
		}}
										</template>
									</el-table-column>
									<el-table-column align="right" label="占比(%)">
										<template slot-scope="scope">
											{{
				Number(
					scope.row.ratio || 0
				).toFixed(2)
			}}
										</template>
									</el-table-column>
									<el-table-column prop="supplierNatureName" label="供应商性质">
									</el-table-column>
								</el-table>
								<template v-if="allData.checkedTitleField.includes(
				'bankFlow_topTen'
			)
			">
									<p class="t2" v-if="(allData.flowIns.length ||
			allData.flowOuts.length ||
			allData.flowInAmounts.length ||
			allData.flowOutAmounts
				.length) &&
			isShowRunning
			">
										<span class="t1-10">10.4.</span>
										银行流水前十大
									</p>
									<template v-if="mobileInfo">

										<el-tabs v-if="isShowRunning" v-model="activeName">
											<el-tab-pane v-if="allData.flowIns.length" label="流入客户" name="1">
												<div class="allw" style="width: 65%">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowIns" border style="width: 100%">
														<el-table-column prop="transactionOpponent" label="交易对手">
														</el-table-column>
														<el-table-column width="110" align="right" label="金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>
														<el-table-column width="60" align="right" label="占比(%)">
															<template slot-scope="scope">
																{{
				Number(
					scope.row
						.proportion ||
					0
				).toFixed(2)
			}}
															</template>
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
											<el-tab-pane v-if="allData.flowOuts.length" label="流出客户" name="2">
												<div class="allw" style="width: 65%">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowOuts" border style="width: 100%">
														<el-table-column prop="transactionOpponent" label="交易对手">
														</el-table-column>
														<el-table-column width="110" align="right" label=" 金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>
														<el-table-column align="right" width="60" label="占比(%)">
															<template slot-scope="scope">
																{{
				Number(
					scope.row
						.proportion ||
					0
				).toFixed(2)
			}}
															</template>
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
											<el-tab-pane v-if="allData.flowInAmounts.length" label="大额流入" name="3">
												<div class="allw" style="width: 65%">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowInAmounts
			" border style="width: 100%">
														<el-table-column width="90" prop="date" label="日期">
														</el-table-column>
														<el-table-column min-width="225" prop="transactionOpponent" label="交易对手">
														</el-table-column>
														<el-table-column width="125" align="right" label="金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>

														<el-table-column min-width="300" prop="summary" label="摘要">
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
											<el-tab-pane v-if="allData.flowOutAmounts.length" label="大额流出" name="4">
												<div class="allw" style="width: 65%">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowOutAmounts" border fit>
														<el-table-column width="90" prop="date" label="日期">
														</el-table-column>
														<el-table-column min-width="225" prop="transactionOpponent" label="交易对手">
														</el-table-column>

														<el-table-column align="right" width="125" label="金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>
														<el-table-column min-width="300" prop="summary" label="摘要">
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
										</el-tabs>
									</template>
									<template v-else>
										<el-tabs v-if="isShowRunning" v-model="activeName">
											<el-tab-pane v-if="allData.flowIns.length" label="流入客户" name="1">
												<div class="allw">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowIns" border>
														<el-table-column :width="flexColumnWidth(allData.flowIns, 'transactionOpponent', 360, 80)"
															prop="transactionOpponent" label="交易对手">
														</el-table-column>
														<el-table-column width="150" align="right" label="金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>
														<el-table-column width="90" align="right" label="占比(%)">
															<template slot-scope="scope">
																{{
				Number(
					scope.row
						.proportion ||
					0
				).toFixed(2)
			}}
															</template>
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
											<el-tab-pane v-if="allData.flowOuts.length" label="流出客户" name="2">
												<div class="allw">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowOuts" border style="width: fit-content">
														<el-table-column :width="flexColumnWidth(allData.flowOuts, 'transactionOpponent', 360, 80)"
															prop="transactionOpponent" label="交易对手">
														</el-table-column>
														<el-table-column width="150" align="right" label=" 金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>
														<el-table-column width="90" align="right" label="占比(%)">
															<template slot-scope="scope">
																{{
				Number(
					scope.row
						.proportion ||
					0
				).toFixed(2)
			}}
															</template>
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
											<el-tab-pane v-if="allData.flowInAmounts.length" label="大额流入" name="3">
												<div class="allw">
													<el-table :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowInAmounts
			" border style="width:fit-content">
														<el-table-column width="100" prop="date" label="日期">
														</el-table-column>
														<el-table-column
															:width="flexColumnWidth(allData.flowInAmounts, 'transactionOpponent', 360, 80)"
															prop="transactionOpponent" label="交易对手">
														</el-table-column>
														<el-table-column width="150" align="right" label="金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>

														<el-table-column :width="flexColumnWidth(allData.flowInAmounts, 'summary', 400, 60)"
															prop="summary" label="摘要">
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
											<el-tab-pane v-if="allData.flowOutAmounts.length" label="大额流出" name="4">
												<div class="allw">
													<el-table style="width:fit-content" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.flowOutAmounts
			" border fit>
														<el-table-column width="100" prop="date" label="日期">
														</el-table-column>
														<el-table-column
															:width="flexColumnWidth(allData.flowOutAmounts, 'transactionOpponent', 360, 80)"
															prop="transactionOpponent" label="交易对手">
														</el-table-column>

														<el-table-column align="right" width="150" label="金额(元)">
															<template slot-scope="scope">
																{{
			formatCurrency(
				scope.row
					.amount
			)
		}}
															</template>
														</el-table-column>
														<el-table-column :width="flexColumnWidth(allData.flowOutAmounts, 'summary', 400, 60)"
															prop="summary" label="摘要">
														</el-table-column>
													</el-table>
												</div>
											</el-tab-pane>
										</el-tabs>


									</template>
								</template>
							</template>

							<template v-if="allData.checkedTitleField.includes(
			'clientCapitalize'
		)
			">
								<p class="t1" v-if="allData.capitalizesList.length">
									<span class="t1-7">7.</span>
									<span class="roll">当前融资情况</span>
									{{ allMoney }}
								</p>
								<el-table v-if="allData.capitalizesList.length && mobileInfo" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.capitalizesList" border style="width: 100%">

									<el-table-column :width="flexColumnWidth(allData.capitalizesList, 'borrowerName', 210, 100)"
										prop="borrowerName" label="借款人名称">
									</el-table-column>
									<el-table-column width="70" label="融资机构">
										<template slot-scope="scope">
											{{
			scope.row.capitalizeOrg ||
			scope.row.capitalizeOrgName
		}}
										</template>
									</el-table-column>
									<el-table-column width="70" align="right" label="融资金额(万元)">
										<template slot-scope="scope">
											{{
				formatCurrency(
					scope.row.capitalizeAmount
				)
			}}
										</template>
									</el-table-column>
									<el-table-column width="70" prop="capitalizeDeadline" label="融资期限(月)">
									</el-table-column>
									<el-table-column width="70" align="right" label="贷款余额(万元)">
										<template slot-scope="scope">
											{{
				formatCurrency(
					scope.row.loanBalance
				)
			}}
										</template>
									</el-table-column>
									<el-table-column width="50" align="right" label="利率(%)">
										<template slot-scope="scope">
											{{
				scope.row.interestRate
					? Number(
						scope.row
							.interestRate
					).toFixed(2)
					: ''
			}}
										</template>
									</el-table-column>
									<el-table-column width="70" prop="capitalizeTypeName" label="融资类型">
									</el-table-column>
									<el-table-column width="100" prop="guaranteeModelName" label="担保方式">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="loanStatusName" label="贷款状态">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="startDate" label="起始年月">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="endDate" label="到期年月">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="cooperationNetworkName" label="合作网点">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="capitalizeCurrencyName" label="融资币别">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										label="担保方式详述">

										<template slot-scope="scope">
											<span @click="showContent(scope.row.guaranteeRemark)"
												v-if="scope.row.guaranteeRemark && scope.row.guaranteeRemark.length > 50"
												style="color: #4184ff;">详情</span>
											<span v-else>{{ scope.row.guaranteeRemark }}</span>

										</template>
									</el-table-column>
									<el-table-column width="50"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										label="备注">
										<template slot-scope="scope">
											<span @click="showContent(scope.row.remark)"
												v-if="scope.row.remark && scope.row.remark.length > 50" style="color: #4184ff;">详情</span>
											<span v-else>{{ scope.row.remark }}</span>

										</template>
									</el-table-column>
									<el-table-column width="200" v-if="privacyType == 2" label="附件">
										<template slot-scope="scope">
											<div v-for="(
															urlitem, urlindex
														) in scope.row
				.guaranteeAttachment" :key="urlindex" style="color: blue;cursor: pointer;" @click="handelOnChange(urlitem)">{{
			urlitem.name
		}}</div>
										</template>
									</el-table-column>


								</el-table>
								<el-table v-if="allData.capitalizesList.length && !mobileInfo" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.capitalizesList" border style="width:fit-content">


									<el-table-column :width="flexColumnWidth(allData.capitalizesList, 'borrowerName', 300, 100)"
										prop="borrowerName" label="借款人名称">
									</el-table-column>
									<el-table-column width="80" label="融资机构">
										<template slot-scope="scope">
											{{
			scope.row.capitalizeOrg ||
			scope.row.capitalizeOrgName
		}}
										</template>
									</el-table-column>
									<el-table-column width="120" align="right" label="融资金额(万元)">
										<template slot-scope="scope">
											{{
				formatCurrency(
					scope.row.capitalizeAmount
				)
			}}
										</template>
									</el-table-column>
									<el-table-column width="110" prop="capitalizeDeadline" label="融资期限(月)">
									</el-table-column>
									<el-table-column width="120" align="right" label="贷款余额(万元)">
										<template slot-scope="scope">
											{{
				formatCurrency(
					scope.row.loanBalance
				)
			}}
										</template>
									</el-table-column>
									<el-table-column width="80" align="right" label="利率(%)">
										<template slot-scope="scope">
											{{
				scope.row.interestRate
					? Number(
						scope.row
							.interestRate
					).toFixed(2)
					: ''
			}}
										</template>
									</el-table-column>
									<el-table-column width="80" prop="capitalizeTypeName" label="融资类型">
									</el-table-column>
									<el-table-column width="100" prop="guaranteeModelName" label="担保方式">
									</el-table-column>
									<el-table-column width="80"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="loanStatusName" label="贷款状态">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="startDate" label="起始年月">
									</el-table-column>
									<el-table-column width="100"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="endDate" label="到期年月">
									</el-table-column>
									<el-table-column width="150"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="cooperationNetworkName" label="合作网点">
									</el-table-column>
									<el-table-column width="80"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										prop="capitalizeCurrencyName" label="融资币别">
									</el-table-column>
									<el-table-column width="200"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										label="担保方式详述">
										<template slot-scope="scope">
											<span @click="showContent(scope.row.guaranteeRemark)"
												v-if="scope.row.guaranteeRemark && scope.row.guaranteeRemark.length > 50"
												style="color: #4184ff;">详情</span>
											<span v-else>{{ scope.row.guaranteeRemark }}</span>

										</template>
									</el-table-column>
									<el-table-column width="200"
										v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 2"
										label="备注">
										<template slot-scope="scope">
											<span @click="showContent(scope.row.remark)"
												v-if="scope.row.remark && scope.row.remark.length > 50" style="color: #4184ff;">详情</span>
											<span v-else>{{ scope.row.remark }}</span>

										</template>
									</el-table-column>
									<el-table-column width="190" v-if="privacyType == 2" label="附件">
										<template slot-scope="scope">
											<div v-for="(
															urlitem, urlindex
														) in scope.row
				.guaranteeAttachment" :key="urlindex" style="color: blue;cursor: pointer;" @click="handelOnChange(urlitem)">{{
			urlitem.name
		}}</div>
										</template>
									</el-table-column>
								</el-table>
								<el-form-item v-if="allData.clientBase
			.capitalizeOthersAttachment && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2" label="其它附件:">
									<el-upload style="
															line-height: 0;
															margin-left:80px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
										:on-preview="handelOnChange" multiple :file-list="allData.clientBase
			.capitalizeOthersAttachment
			">
									</el-upload>
								</el-form-item>
								<el-form-item :class="{ 'textinfo': mobileInfo }" label-width="110px" v-if="(allData.clientBase
			.clientCapitalizeRemark || allData.clientBase
				.cooperativeBankRemark) && allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO
					.cooperationPresidentType == 2" label="备注: ">
									<div :class="[{ info1: mobileInfo }, { w65: !mobileInfo }]" v-html="fn(
			allData.clientBase
				.cooperativeBankRemark || ''
		)
			"></div>
									<div :class="[{ info1: mobileInfo }, { w65: !mobileInfo }]" v-html="fn(
			allData.clientBase
				.clientCapitalizeRemark || ''
		)
			"></div>
								</el-form-item>

								<!-- <el-form-item v-if="allData.clientBase &&
									allData.clientBase
										.capitalizeAttachment &&
									privacyType == 2
									" label="附件:">
									<el-upload style="
													line-height: 0;
													margin-left: 50px;
												" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
										:on-preview="handelOnChange" multiple :file-list="allData.clientBase
											.capitalizeAttachment
											">
									</el-upload>
								</el-form-item> -->
							</template>
						</el-form>
					</el-card>
					<el-empty v-else slot="empty" description="暂无客户详细信息"></el-empty>

					<el-card v-if="allData.riskScreeningInformationList &&
			allData.riskScreeningInformationList.length
			" class="box-card">
						<p class="roll" style="
										font-size: 20px;
										color: #3e31ff;
										font-weight: 800;
										margin: 10px 0;
									">
							风险筛查
						</p>

						<el-table :span-method="objectSpanMethod1" v-if="mobileInfo" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.riskScreeningInformationList" border style="width: 100%">
							<el-table-column :width="flexColumnWidth(allData.riskScreeningInformationList, 'tryingToStudy', 150, 60)"
								prop="tryingToStudy" label="排查项">
							</el-table-column>
							<el-table-column prop="subjects"
								:width="flexColumnWidth(allData.riskScreeningInformationList, 'subjects', 105, 60)" label="科目">
							</el-table-column>
							<el-table-column label="详情">
								<template slot-scope="scope">
									<span @click="showContent(scope.row.content)"
										v-if="scope.row.content && scope.row.content.length > 50" style="color: #4184ff;">详情</span>
									<span v-else>{{ scope.row.content.replace('，点击查看详情', '').replace('，点击查看', '').replace('，详情', '')
										}}</span>

								</template>

							</el-table-column>
							<el-table-column width="40" prop="levelName" label="等级">
							</el-table-column>
						</el-table>
						<el-table :span-method="objectSpanMethod1" v-else :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.riskScreeningInformationList" border style="width: fit-content">

							<el-table-column :width="flexColumnWidth(allData.riskScreeningInformationList, 'tryingToStudy', 150, 60)"
								prop="tryingToStudy" label="排查项">
							</el-table-column>
							<el-table-column prop="subjects"
								:width="flexColumnWidth(allData.riskScreeningInformationList, 'subjects', 200, 60)" label="科目">
							</el-table-column>
							<el-table-column :width="flexColumnWidth(allData.riskScreeningInformationList, 'content', 780, 60)"
								label="详情">
								<template slot-scope="scope">
									<span>{{ scope.row.content.replace('，点击查看详情', '').replace('，点击查看', '').replace('，详情', '') }}</span>


								</template>

							</el-table-column>

							<el-table-column prop="levelName" width="50" label="等级">
							</el-table-column>
						</el-table>
					</el-card>
					<el-card v-if="allData.competitivenessScreeningInformationList &&
			allData.competitivenessScreeningInformationList
				.length
			" class="box-card">
						<p class="roll" style="
										font-size: 20px;
										color: #3e31ff;
										font-weight: 800;
										margin: 10px 0;
									">
							竞争力筛查
						</p>
						<el-table :span-method="objectSpanMethod2" v-if="mobileInfo" :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.competitivenessScreeningInformationList
			" border style="width: 100%">
							<el-table-column
								:width="flexColumnWidth(allData.competitivenessScreeningInformationList, 'tryingToStudy', 150, 60)"
								prop="tryingToStudy" label="排查项">
							</el-table-column>
							<el-table-column
								:width="flexColumnWidth(allData.competitivenessScreeningInformationList, 'subjects', 105, 60)"
								prop="subjects" label="科目">
							</el-table-column>
							<el-table-column label="详情">
								<template slot-scope="scope">
									<span @click="showContent(scope.row.content)"
										v-if="scope.row.content && scope.row.content.length > 50" style="color: #4184ff;">详情</span>
									<span v-else>{{ scope.row.content }}</span>

								</template>

							</el-table-column>
							<el-table-column prop="levelName" width="40" label="等级">
							</el-table-column>
						</el-table>
						<el-table :span-method="objectSpanMethod2" v-else :header-cell-style="{
			background: '#ecf5ff',
		}" :data="allData.competitivenessScreeningInformationList
			" border style="width: fit-content">
							<el-table-column
								:width="flexColumnWidth(allData.competitivenessScreeningInformationList, 'tryingToStudy', 150, 60)"
								prop="tryingToStudy" label="排查项">
							</el-table-column>
							<el-table-column
								:width="flexColumnWidth(allData.competitivenessScreeningInformationList, 'subjects', 200, 60)"
								prop="subjects" label="科目">
							</el-table-column>
							<el-table-column
								:width="flexColumnWidth(allData.competitivenessScreeningInformationList, 'content', 780, 60)"
								label="详情">
								<template slot-scope="scope">
									<span>{{ scope.row.content }}</span>


								</template>

							</el-table-column>
							<el-table-column prop="levelName" width="50" label="等级">
							</el-table-column>
						</el-table>
					</el-card>
					<el-card class="box-card" v-if="allData.financeRequirementList && false">
						<p style="
										font-size: 20px;
										color: #3e31ff;
										font-weight: 800;
										margin: 10px 0;
									">
							悦融易推荐理由
						</p>
						<template v-if="allData.financeRequirementList.length">
							<div v-for="(
											item, index
										) in allData.financeRequirementList" :key="index" class="text-item">
								<el-form>
									<el-form-item v-if="item.recommendReason" style="margin: 0px" label="推荐原因: ">
										<div class="info" v-html="fn(item.recommendReason || '')
			"></div>
									</el-form-item>
									<el-form-item v-if="item.consultKeyPoint" style="margin: 0px" label="咨询要点: ">
										<div class="info" v-html="fn(item.consultKeyPoint || '')
			"></div>
									</el-form-item>
								</el-form>
							</div>
						</template>
					</el-card>
					<el-card class="box-card" v-if="allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			false
			">
						<p style="
										font-size: 20px;
										color: #3e31ff;
										font-weight: 800;
										margin: 10px 0;
									">
							顾问解决思路及问题
						</p>
						<el-form class="demo-ruleForm" label-position="rigth" :label-width="mobileInfo ? '100px' : '180px'">
							<el-form-item v-if="allData.financeRequirementList &&
			allData.financeRequirementList[0]
				.financingConsultantFinancingSolution
			" label="融资顾问融资解决思路:">
								{{
			allData.financeRequirementList[0]
				.financingConsultantFinancingSolution
		}}
							</el-form-item>
							<el-form-item v-if="allData.financeRequirementList &&
			allData.financeRequirementList[0]
				.productManagerSupport
			" label="须产品总监提供的支持	:">
								{{
			allData.financeRequirementList[0]
				.productManagerSupport
		}}
							</el-form-item>
						</el-form>
					</el-card>
					<el-card class="box-card" v-if="allData.financeRequirementList &&
			(personInfo.alias || personInfo.mobilePhone)
			">
						<p style="
										font-size: 20px;
										color: #3e31ff;
										font-weight: 800;
										margin: 10px 0;
									">
							联系悦融易
						</p>
						<el-form class="demo-ruleForm" label-position="rigth" label-width="90px">
							<el-form-item style="margin-bottom:0;" label="联系人 : ">
								{{ personInfo.alias }}
							</el-form-item>
							<el-form-item style="margin-bottom:0;" label="联系电话:">
								{{ personInfo.mobilePhone }}
							</el-form-item>
						</el-form>
					</el-card>
					<el-card v-if="allData.financeRequirementList" class="box-card feedbackDom">
						<p style="
										font-size: 20px;
										color: #3e31ff;
										font-weight: 800;
										margin-bottom: 20px;
									">
							反馈我的合作意愿及意见
						</p>
						<el-form
							v-if="allData.clientPresidentRelatedVO && allData.clientPresidentRelatedVO.cooperationPresidentType == 1"
							:model="ruleForm" status-icon ref="MyruleForm" class="demo-ruleForm" label-position="rigth"
							label-width="90px" :rules="rules">
							<el-form-item v-if="allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType != 2
			" prop="cooperateWillingness" :rules="[
			{
				required: true,
				message: '合作意愿不能为空',
			},
		]" label="合作意愿:">
								<el-radio v-for="item in PresidentCooperateWillingness" v-model="ruleForm.cooperateWillingness"
									:label="item.value" :key="item.value">{{ item.label }}</el-radio>
							</el-form-item>
							<el-form-item label-width="auto" v-if="ruleForm.cooperateWillingness != 3"
								prop="interestedProvidingFinanceSolutions" :rules="[
			{
				required: true,
				message: '客户提供融资方案不能为空',
			},
		]" label="您是否有兴趣为该客户提供融资方案:">
								<el-radio v-for="item in InterestedProvidingFinanceSolutions" v-model="ruleForm.interestedProvidingFinanceSolutions
			" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
							</el-form-item>

							<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType == 1 &&
				ruleForm.cooperateWillingness != 3)
			" label-width="auto" prop="compliesCreditProductPolicy" :rules="[
			{
				required: true,
				message: '贵行授信产品政策不能为空',
			},
		]" label="该客户需求是否符合贵行授信产品政策:">
								<el-radio v-for="item in CompliesCreditProductPolicy" v-model="ruleForm.compliesCreditProductPolicy
			" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
							</el-form-item>

							<template v-if="allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType != 2
			">
								<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType ==
			2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType ==
				1 &&
				ruleForm.cooperateWillingness != 3)
			" prop="applicableProducts" :rules="[
			{
				required: true,
				message: '适用产品不能为空',
			},
		]" label="适用产品:">
									<el-autocomplete suffix-icon="el-icon-arrow-down" style="width: 100%"
										v-model="ruleForm.applicableProducts" :fetch-suggestions="querySearch"
										placeholder="请输入适用产品"></el-autocomplete>
								</el-form-item>
								<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType ==
			2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType ==
				1 &&
				ruleForm.cooperateWillingness != 3)
			" prop="minFinancing" label="融资金额:">
									<div style="
													display: flex;
													justify-content: space-between;
												">
										<el-input-number style="width: 40%" v-model="ruleForm.minFinancing" controls-position="right"
											:min="1" :max="9999"></el-input-number>
										<span style="width: 50px;text-align: right;">万元</span>
										<span>至</span>
										<el-input-number style="width: 40%" ref="Financing_max" v-model="ruleForm.maxFinancing"
											controls-position="right" :min="1" :max="9999"></el-input-number>
										<span style="width: 50px;text-align: right;">万元</span>
									</div>
								</el-form-item>
								<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType ==
			2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType ==
				1 &&
				ruleForm.cooperateWillingness != 3)
			" prop="interestRateMin" label="利率区间:">
									<div style="
													display: flex;
													justify-content: space-between;
												">
										<el-input-number style="width: 40%" v-model="ruleForm.interestRateMin" controls-position="right"
											:min="1" :max="100"></el-input-number>
										<span><span style="opacity: 0">至</span style="width: 50px;">%</span>
										<span>至</span>
										<el-input-number style="width: 40%" ref="interestRate_max" v-model="ruleForm.interestRateMax"
											controls-position="right" :min="1" :max="100"></el-input-number>
										<span><span style="opacity: 0">至</span style="width: 50px;">%</span>
									</div>
								</el-form-item>
							</template>
							<template v-else>
								<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType ==
			2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType ==
				1 &&
				ruleForm.cooperateWillingness != 3)
			" label="适用产品:">
									<el-autocomplete suffix-icon="el-icon-arrow-down" style="width: 100%"
										v-model="ruleForm.applicableProducts" :fetch-suggestions="querySearch"
										placeholder="请输入适用产品"></el-autocomplete>
								</el-form-item>
								<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType ==
			2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType ==
				1 &&
				ruleForm.cooperateWillingness != 3)
			" label="融资金额:">
									<div style="
													display: flex;
													justify-content: space-between;
												">
										<el-input-number style="width: 40%" v-model="ruleForm.minFinancing" controls-position="right"
											:min="1" :max="9999"></el-input-number>
										<span style="width: 50px;text-align: right;">万元</span>
										<span>至</span>
										<el-input-number style="width: 40%" ref="Financing_max" v-model="ruleForm.maxFinancing"
											controls-position="right" :min="1" :max="9999"></el-input-number>
										<span style="width: 50px;text-align: right;">万元</span>
									</div>
								</el-form-item>
								<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType ==
			2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType ==
				1 &&
				ruleForm.cooperateWillingness != 3)
			" label="利率区间:">
									<div style="
													display: flex;
													justify-content: space-between;
												">
										<el-input-number style="width: 40%" v-model="ruleForm.interestRateMin" controls-position="right"
											:min="1" :max="100"></el-input-number>
										<span><span style="opacity: 0">至</span>%</span>
										<span>至</span>
										<el-input-number style="width: 40%" ref="interestRate_max" v-model="ruleForm.interestRateMax"
											controls-position="right" :min="1" :max="100"></el-input-number>
										<span><span style="opacity: 0">至</span>%</span>
									</div>
								</el-form-item>
							</template>
							<el-form-item label="融资期限:" v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType == 1 &&
				ruleForm.cooperateWillingness != 3)
			" prop="networkSimpleName">
								<div style="display: flex">
									<el-input style="margin-right: 30px" oninput="value=value.replace(/[^0-9.]/g,'')"
										placeholder="请输入融资期限" v-model="ruleForm.loanDeadline"></el-input>
									<span>(月)</span>
								</div>
							</el-form-item>

							<el-form-item label="还款方式:" v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType == 1 &&
				ruleForm.cooperateWillingness != 3)
			" prop="financialOrganizationsName">
								<el-autocomplete suffix-icon="el-icon-arrow-down" style="width: 100%" v-model="ruleForm.repaymentMethod"
									:fetch-suggestions="querySearch1" placeholder="请输入还款方式"></el-autocomplete>
							</el-form-item>

							<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType == 1 &&
				ruleForm.cooperateWillingness != 3)
			" label="担保条件:">
								<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 14 }" placeholder="请输入担保条件" maxlength="500"
									show-word-limit v-model="ruleForm.guaranteeConditions"></el-input>
							</el-form-item>

							<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			ruleForm.interestedProvidingFinanceSolutions ==
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType == 1 &&
				ruleForm.cooperateWillingness == 3)
			" label="放弃原因:">
								<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.abandonReason" maxlength="2000" show-word-limit>
								</el-input>
							</el-form-item>
							<el-form-item v-if="(allData.clientPresidentRelatedVO &&
			allData.clientPresidentRelatedVO
				.cooperationPresidentType == 2 &&
			ruleForm.interestedProvidingFinanceSolutions !=
			4) ||
			(allData.clientPresidentRelatedVO &&
				allData.clientPresidentRelatedVO
					.cooperationPresidentType == 1 &&
				ruleForm.cooperateWillingness != 3)
			" label="备注:">
								<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.creditAccess" maxlength="2000" show-word-limit>
								</el-input>
							</el-form-item>
							<el-form-item v-if="ruleForm.feedbackInformation" label="悦融易反馈内容:">
								<div v-html="fn(ruleForm.feedbackInformation)"></div>
							</el-form-item>
						</el-form>
						<div v-else>
							<el-collapse v-if="replyList.length">
								<el-collapse-item>
									<template slot="title">
										<p @click="iszdzk = !iszdzk" style="font-size: 18px;color: #3e31ff;margin-left: 20px;width: 100%;">
											点击{{ iszdzk ?
											'展开' : '折叠' }}其他方案经理回复信息</p>
									</template>
									<el-card v-for="(item, index) in replyList" :key="index" class="box-card">
										<div style="font-size: 16px;font-weight: 800;margin-left: 20px;">
											<span>{{ item.cooperationPresidentName }}</span>
											<span>{{ item.presidentFeedbackTime }}</span>
										</div>
										<el-form style="margin-left: 30px;" status-icon class="demo-ruleForm"
											:label-position="mobileInfo ? 'top' : 'rigth'" label-width="150px">
											<el-form-item label-width="auto" label="您是否有兴趣为该客户提供融资方案:">
												{{ item.whetherExistsFinancingPlanName }}
											</el-form-item>
											<el-divider v-if="item.whetherExistsFinancingPlan == 1" content-position="left"> <span
													style="display: flex;align-items: center;">
													意向方案描述
												</span>
											</el-divider>
											<el-form-item v-if="item.whetherExistsFinancingPlan == 1"
												v-for="(items, indexs) in item.intentionSchemeDescription" :key="indexs"
												:label="'方案' + (indexs + 1) + ':'">
												<div>
													<div style="display: flex;flex-wrap: wrap;flex:1;">
														<div>{{ items.cooperationPresidentName }}/{{ items.shortName }}</div>
													</div>
													<div v-html="fn(items.planOverview)"></div>
												</div>
											</el-form-item>
											<el-form-item label="需要进一步了解问题:">
												<div v-html="item.neededUnderstandingProblem"></div>
											</el-form-item>
											<el-form-item label="备注:">
												<div v-html="item.creditAccess"></div>
											</el-form-item>
										</el-form>
									</el-card>
								</el-collapse-item>
							</el-collapse>
							<el-form :rules="rules" :model="ruleForm" ref="MyruleForm" status-icon class="demo-ruleForm"
								:label-position="mobileInfo ? 'top' : 'rigth'" label-width="150px">
								<el-form-item prop="whetherExistsFinancingPlan" label-width="auto" label="您是否有兴趣为该客户提供融资方案:">
									<el-radio v-for="item in WhetherExistsFinancingPlan" v-model="ruleForm.whetherExistsFinancingPlan
			" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
								</el-form-item>
								<el-form-item prop="masterProgramManagerIntention" label-width="auto" label="是否有意向成为主方案经理:">
									<el-radio v-for="item in MasterProgramManagerIntention" v-model="ruleForm.masterProgramManagerIntention
			" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
								</el-form-item>
								<el-divider v-if="ruleForm.whetherExistsFinancingPlan == 1" content-position="left"> <span
										style="display: flex;align-items: center;">
										意向方案描述

									</span>
								</el-divider>
								<el-form-item v-if="ruleForm.whetherExistsFinancingPlan == 1" :label="'方案' + (indexs + 1) + ':'"
									v-for="(items, indexs) in ruleForm.intentionSchemeDescription" :key="indexs">
									<div style="display: flex;align-items: center;">
										<div style="display: flex;flex-wrap: wrap;justify-content: space-between;flex:1">
											<el-select @change="(res) => handleSelect(res, items)" v-model="items.cooperationPresidentId"
												ref="selectLabel1" style="width:48%" filterable :filter-method="dataFilter"
												placeholder="请选择合作行长">
												<el-option v-for="item in options" :key="item.cooperationPresidentId"
													:value="item.cooperationPresidentId" :label="item.cooperationPresidentName">
													<div style="display: flex;justify-content: space-between;">
														<span>{{ item.cooperationPresidentName }}</span>
														<span>{{ item.networkSimpleName }}</span>
														<span style="color: #8492a6; font-size: 13px">{{
															item.shortName
															}}</span>
													</div>
												</el-option>
											</el-select>
											<el-select @change="(res) => handleSelect1(res, items)" v-model="items.financialOrganizationsId"
												ref="selectLabel2" style="width:48%" filterable :filter-method="dataFilters"
												placeholder="请选择金融机构">
												<el-option v-for="item in branchList" :key="item.financialOrganizationsId"
													:value="item.financialOrganizationsId" :label="item.shortName">
													<div style="display: flex;justify-content: space-between;">
														<span>{{ item.shortName }}</span>
														<span style="color: #8492a6; font-size: 13px">{{
															item.organizationTypeName
															}}</span>
													</div>
												</el-option>
											</el-select>
											<el-input v-model="items.planOverview" style="margin-top: 10px;" maxlength="2000" type="textarea"
												:autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入方案概述">
											</el-input>
										</div>
										<el-button v-if="indexs != 0" @click="delids(items, indexs)" style="margin-left: 10px;" size="mini"
											type="danger">删除</el-button>

									</div>

								</el-form-item>
								<div v-if="ruleForm.whetherExistsFinancingPlan == 1" style="margin-bottom: 20px;margin-left: 90px;">
									<el-button @click="addisd" style="margin-left: 10px;" size="mini" type="primary">添加一个方案</el-button>
								</div>

								<el-form-item label="需要进一步了解问题:">
									<el-input v-model="ruleForm.neededUnderstandingProblem" maxlength="2000" type="textarea"
										:autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入">
									</el-input>
								</el-form-item>
								<el-form-item label="备注:">
									<el-input v-model="ruleForm.creditAccess" maxlength="2000" type="textarea"
										:autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入">
									</el-input>
								</el-form-item>
							</el-form>
						</div>


					</el-card>
					<div v-if="allData.financeRequirementList" class="demo-drawer__footer">
						<el-button v-if="!Number(showTransfer)" @click="transferEvent" type="primary"
							:style="{ width: [mobileInfo ? '' : '25% '] }">转发给合作行长</el-button>
						<el-button type="primary" @click="postCooperationFeedbackSave">{{
							allData.clientPresidentRelatedVO &&
							allData.clientPresidentRelatedVO
							.cooperationPresidentType == 1
							? '回复悦融易咨询人'
							: '回复融资顾问'
							}}</el-button>
					</div>
				</div>
			</div>
			<el-empty v-else :description="msg"></el-empty>
		</div>
		<el-button style="
						width: 100%;
						position: fixed;
						z-index: 10;
						bottom: 0px;
						right: 0px;
						left: 0px;
						margin: auto;
						border-radius: 0px;
						box-shadow: 0px -5px 12px 0 rgb(0 0 0 / 18%);
					" v-if="isGoFeedback && !rtnCode" @click="goFeedback" type="primary">反馈我的合作意愿</el-button>
		<notice @close="() => {
			centerDialogVisible = false;
			changeProtocolIdentification();
		}
			" :centerDialogVisible.sync="centerDialogVisible" />
		<transfer v-if="allData.financeRequirementList &&
			allData.financeRequirementList.length
			" @closeVisible="() => {
			transferDialogVisible = false;
		}
			" :transferDialogVisible.sync="transferDialogVisible"
			:requirementId="allData.financeRequirementList[0].requirementId" :checkedTitleField="allData.checkedTitleField"
			:recommendReason="allData.financeRequirementList[0].recommendReason" :inquiryAttachment="allData.financeRequirementList[0].inquiryAttachment
			" :recommendedzReason="allData.financeRequirementList[0].recommendedzReason
			" :clientRequirement="allData.financeRequirementList[0].clientRequirement
			" :noticeMatter="allData.financeRequirementList[0].noticeMatter"
			:askQuestions="allData.financeRequirementList[0].askQuestions"
			:remark="allData.financeRequirementList[0].remark" />
	</div>
</template>

<script>
import notice from '../index/model/notice.vue';
import {
	changeProtocolIdentification,
	publicFieldPermissionVerification,
	getNotRecommendPresidentList4Requirement,
	financialOrganizetionList,
	translateTheNanagerRecords
} from '@/api/index.js';
import { mapGetters } from 'vuex';
import {
	readCooperationFeedback,
	cooperationFeedbackSave,
	itemByType,
	getSensitiveClientInfo,
	shortLinkLogin,
	getClientInfoSensitive,
	getClientInfo,
	financialProductList,
	clientBankFlowList,
	readUserInfoOfClient,
	getVfqClinetData,
	getVfqClinetDatas
} from '@/api/index.js';
export default {
	name: 'clientInfo',
	data() {
		const Financing = (rule, value, callback) => {
			const Financing_max = this.$refs.Financing_max.value;
			if (value && Financing_max && value <= Financing_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!Financing_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > Financing_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		const InterestRate = (rule, value, callback) => {
			const interestRate_max = this.$refs.interestRate_max.value;
			if (value && interestRate_max && value <= interestRate_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!interestRate_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > interestRate_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		return {
			zljjl: false,
			swcfjl: false,
			qsjl: false,
			taxAccountingData: {},
			swinfo: {},
			fpinfo: {},
			sfinfo: {},
			gsinfo: {},
			sfsfinfo: {},
			sfinfoobj: {},
			dtinfo: {},
			isswbg: false,
			flowSrc: '',
			navitemindex: 0,
			sfinfoobj: {},
			sfinfoobj1: {},
			navList: [],
			isSticky: false,
			iszdzk: true,
			replyList: [],
			options: [],
			optionsCopy: [],
			branchList: [],
			branchListCopy: [],
			personInfo: {},
			transferDialogVisible: false,
			showTransfer: 1,
			mergeObj: {}, // 用来记录需要合并行的下标
			mergeObj1: {}, // 用来记录需要合并行的下标
			mergeArr: ['tryingToStudy', 'subjects'], // 表格中的列名
			activeName: '1',
			isData: true,
			isGoFeedback: true,
			isShowRunning: '',
			msg: '',
			rtnCode: 0,
			allData: {},
			PresidentCooperateWillingness: [],
			CompliesCreditProductPolicy: [],
			InterestedProvidingFinanceSolutions: [],
			MasterProgramManagerIntention: [],
			WhetherExistsFinancingPlan: [],
			paramsId: {
				clientId: '328',
				clientPresidentRelatedId: '537',
				businessType: 8,
				cooperationPresidentId: '',
			},
			rules: {
				whetherExistsFinancingPlan: [
					{ required: true, message: '请选择融资方案', trigger: ['blur', 'change'] }
				],
				masterProgramManagerIntention: [
					{ required: true, message: '请选择是否有意向成为主方案经理', trigger: ['blur', 'change'] }
				],
				minFinancing: [
					{
						required: true,
						validator: Financing,
						trigger: ['blur', 'change'],
					},
				],
				interestRateMin: [
					{
						required: true,
						validator: InterestRate,
						trigger: ['blur', 'change'],
					},
				],
			},
			ruleForm: {
				cooperateWillingness: '',
				compliesCreditProductPolicy: '',
				interestedProvidingFinanceSolutions: '',
				masterProgramManagerIntention: '',
				applicableProducts: '',
				minFinancing: undefined,
				maxFinancing: undefined,
				interestRateMin: undefined,
				interestRateMax: undefined,
				loanDeadline: '',
				repaymentMethod: '',
				guaranteeConditions: '',
				abandonReason: '',
				creditAccess: '',
				clientPresidentRelatedId: 0,
				whetherExistsFinancingPlan: '',
				intentionSchemeDescription: [
					{
						cooperationPresidentId: '',
						cooperationPresidentName: '',
						financialOrganizationsId: '',
						shortName: '',
						planOverview: '',
					}
				],
				neededUnderstandingProblem: '',
			},
			tableData: [
				{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-08',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-06',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-08',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-06',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
			],
			headers: [
				{
					prop: 'title',
					label: '科目',
				},
				{
					prop: '',
					label: '流动资产：',
				},
				{
					prop: 'monetaryCapitalClosingBalance',
					label: '货币资金(元)',
				},
				{
					prop: 'transactionalFinancialAssetsClosingBalance',
					label: '交易性金融资产(元)',
				},
				{
					prop: 'billReceivableClosingBalance',
					label: '应收票据(元)',
				},
				{
					prop: 'accountsReceivableClosingBalance',
					label: '应收账款(元)',
				},
				{
					prop: 'advancePaymentClosingBalance',
					label: '预付款项(元)',
				},
				{
					prop: 'interestReceivableClosingBalance',
					label: '应收利息(元)',
				},
				{
					prop: 'dividendsReceivableClosingBalance',
					label: '应收股利(元)',
				},
				{
					prop: 'otherReceivableClosingBalance',
					label: '其他应收款(元)',
				},
				{
					prop: 'inventoryClosingBalance',
					label: '存货(元)',
				},
				{
					prop: 'nonCurrentAssetsClosingBalance',
					label: '一年内到期的非流动资产(元)',
				},
				{
					prop: 'otherCurrentAssetsClosingBalance',
					label: '其他流动资产(元)',
				},
				{
					prop: 'currentAssetsTotalClosingBalance',
					label: '流动资产合计(元)',
				},
				{
					prop: '',
					label: '非流动资产：',
				},
				{
					prop: 'saleableFinancialAssetsClosingBalance',
					label: '可供出售金融资产(元)',
				},
				{
					prop: 'matureInvestmentClosingBalance',
					label: '持有至到期投资(元)',
				},
				{
					prop: 'longTermReceivablesClosingBalance',
					label: '长期应收款(元)',
				},
				{
					prop: 'longTermEquityInvestmentClosingBalance',
					label: '长期股权投资(元)',
				},
				{
					prop: 'investmentRealEstateClosingBalance',
					label: '投资性房地产(元)',
				},
				{
					prop: 'capitalAssetsClosingBalance',
					label: '固定资产(元)',
				},
				{
					prop: 'projectProgressClosingBalance',
					label: '在建工程(元)',
				},
				{
					prop: 'projectMaterialClosingBalance',
					label: '工程物资(元)',
				},
				{
					prop: 'capitalAssetsDisposalClosingBalance',
					label: '固定资产清理(元)',
				},
				{
					prop: 'productiveBiologicalAssetsClosingBalance',
					label: '生产性生物资产(元)',
				},
				{
					prop: 'gasAssetsClosingBalance',
					label: '油气资产(元)',
				},
				{
					prop: 'intangibleAssetsClosingBalance',
					label: '无形资产(元)',
				},
				{
					prop: 'developmentExpenditureClosingBalance',
					label: '开发支出(元)',
				},
				{
					prop: 'goodwillClosingBalance',
					label: '商誉(元)',
				},
				{
					prop: 'longTermDeferredExpensesClosingBalance',
					label: '长期待摊费用(元)',
				},
				{
					prop: 'deferredTaxAssetsClosingBalance',
					label: '递延所得税资产(元)',
				},
				{
					prop: 'otherNonCurrentAssetsClosingBalance',
					label: '其他非流动资产(元)',
				},
				{
					prop: 'nonCurrentAssetsTotalClosingBalance',
					label: '非流动资产合计(元)',
				},
				{
					prop: 'assetsTotalClosingBalance',
					label: '资产总计(元)',
				},
				{
					prop: '',
					label: '流动负债：',
				},
				{
					prop: 'shortTermBorrowingsClosingBalance',
					label: '短期借款(元)',
				},
				{
					prop: 'tradingFinancialLiabilitiesClosingBalance',
					label: '交易性金融负债(元)',
				},
				{
					prop: 'billPayableClosingBalance',
					label: '应付票据(元)',
				},
				{
					prop: 'accountsPayableClosingBalance',
					label: '应付账款(元)',
				},
				{
					prop: 'advanceReceiveClosingBalance',
					label: '预收款项(元)',
				},
				{
					prop: 'payrollPayableClosingBalance',
					label: '应付职工薪酬(元)',
				},
				{
					prop: 'taxesPayableClosingBalance',
					label: '应交税费(元)',
				},
				{
					prop: 'interestPayableClosingBalance',
					label: '应付利息(元)',
				},
				{
					prop: 'dividendsPayableClosingBalance',
					label: '应付股利(元)',
				},
				{
					prop: 'otherPayablesClosingBalance',
					label: '其他应付款(元)',
				},
				{
					prop: 'nonCurrentLiabilitiesClosingBalance',
					label: '一年内到期的非流动负债(元)',
				},
				{
					prop: 'otherCurrentLiabilitiesClosingBalance',
					label: '其他流动负债(元)',
				},
				{
					prop: 'currentLiabilitiesTotalClosingBalance',
					label: '流动负债合计(元)',
				},
				{
					prop: '',
					label: '非流动负债：',
				},
				{
					prop: 'longTermBorrowingsClosingBalance',
					label: '长期借款(元)',
				},
				{
					prop: 'bondsPayableClosingBalance',
					label: '应付债券(元)',
				},
				{
					prop: 'longTermPayablesClosingBalance',
					label: '长期应付款(元)',
				},
				{
					prop: 'specialPayablesClosingBalance',
					label: '专项应付款(元)',
				},
				{
					prop: 'estimatedLiabilitiesClosingBalance',
					label: '预计负债(元)',
				},
				{
					prop: 'deferredTaxLiabilityClosingBalance',
					label: '递延所得税负债(元)',
				},
				{
					prop: 'otherNonCurrentLiabilitiesClosingBalance',
					label: '其他非流动负债(元)',
				},
				{
					prop: 'nonCurrentLiabilitiesTotalClosingBalance',
					label: '非流动负债合计(元)',
				},
				{
					prop: 'liabilitiesTotalClosingBalance',
					label: '负债合计(元)',
				},
				{
					prop: 'subscribedCapitalClosingBalance',
					label: '实收资本(元)',
				},
				{
					prop: 'capitalReserveClosingBalance',
					label: '资本公积(元)',
				},
				{
					prop: 'lessTreasuryStockClosingBalance',
					label: '减：库存股(元)',
				},
				{
					prop: 'specialReserveClosingBalance',
					label: '专项储备(元)',
				},
				{
					prop: 'surplusReserveClosingBalance',
					label: '盈余公积(元)',
				},
				{
					prop: 'undistributedProfitsClosingBalance',
					label: '未分配利润(元)',
				},
				{
					prop: 'totalOwnerEquityClosingBalance',
					label: '所有者权益合计(元)',
				},
				{
					prop: 'totalLiabilitiesAndOwnerEquityClosingBalance',
					label: '负债和所有者权益总计(元)	',
				},
			],
			income: [
				{
					prop: 'title',
					label: '科目',
				},
				{
					prop: 'businessIncomeMonthAmount',
					label: '一、营业收入(元)',
				},
				{
					prop: 'operatingCostsMonthAmount',
					label: '减：营业成本(元)',
				},
				{
					prop: 'salesTaxMonthAmount',
					label: '营业税金及附加(元)',
				},
				{
					prop: 'sellingCostsMonthAmount',
					label: '销售费用(元)',
				},
				{
					prop: 'administrativeExpensesMonthAmount',
					label: '管理费用(元)',
				},
				{
					prop: 'financialExpensesMonthAmount',
					label: '财务费用(元)',
				},
				{
					prop: 'assetsImpairmentLossMonthAmount',
					label: '资产减值损失(元)',
				},
				{
					prop: 'variableIncomeMonthAmount',
					label: '加：公允价值变动收益（损失以“-”号填列）(元)',
				},
				{
					prop: 'investmentIncomeMonthAmount',
					label: '投资收益（损失以“-”号填列）(元)',
				},
				{
					prop: 'cooperativeEnterpriseInvestmentIncomeMonthAmount',
					label: '其中：对联营企业和合营企业的投资收益(元)',
				},
				{
					prop: 'operatingProfitMonthAmount',
					label: '二、营业利润（亏损以“－”号填列）(元)',
				},
				{
					prop: 'nonOperatingIncomeMonthAmount',
					label: '加：营业外收入(元)',
				},
				{
					prop: 'nonOperatingExpensesMonthAmount',
					label: '减：营业外支出(元)',
				},
				{
					prop: 'nonCurrentAssetsDisposalLossMonthAmount',
					label: '其中：非流动资产处置损失(元)',
				},
				{
					prop: 'totalProfitMonthAmount',
					label: '三、利润总额（亏损总额以“－”号填列）(元)',
				},
				{
					prop: 'incomeTaxExpenseMonthAmount',
					label: '减：所得税费用(元)',
				},
				{
					prop: 'netProfitMonthAmount',
					label: '四、净利润(元)',
				},
				{
					prop: 'earningsPerShareMonthAmount',
					label: '五、每股收益(元)',
				},
				{
					prop: 'basicEarningsPerShareMonthAmount',
					label: '（一）基本每股收益(元)',
				},
				{
					prop: 'dilutedEarningsPerShareMonthAmount',
					label: '（二）稀释每股收益(元)',
				},
			],
			indicator: [
				{
					prop: 'title',
					label: '财务指标',
				},

				{
					prop: 'xxx',
					label: '一、偿债能力指标',
				},
				{
					prop: 'ratioCurrent',
					label: '流动比率(%)',
				},

				{
					prop: 'ratioQuick',
					label: '速动比率(%) ',
				},
				{
					prop: 'liabRateAssets',
					label: '资产负债率(%) ',
				},
				{
					prop: 'liabRateBank',
					label: '银行负债率(%)',
				},
				{
					prop: 'xxxx',
					label: '二、营运能力指标 ',
				},
				{
					prop: 'receivablesTurnover',
					label: '应收账款周转天数',
				},
				{
					prop: 'inventoryTurnover',
					label: '存货周转天数',
				},
				{
					prop: 'xxxx',
					label: '三、盈利能力指标',
				},
				{
					prop: 'saleGrossMargin',
					label: '销售毛利率(%)',
				},
				{
					prop: 'saleInterestRate',
					label: '销售净利率(%)',
				},
				{
					prop: 'growthRateRevenue',
					label: '销售收入增长率(%) ',
				},
				{
					prop: 'growthRateProfit',
					label: '净利润增长率(%)',
				},
				{
					prop: 'cashRatio',
					label: '现金比率(%)',
				},
			],
			restaurants: [],
			RepaymentMethod: [],
			privacyType: 1,
			centerDialogVisible: false,
			mobileInfo: false,
			zkinfo: true,
			minfo: false,
			minfo1: false
		};
	},
	created() {
		// 禁止刷新的条件
		// history.replaceState(null, null, location.href);
		this.browserRedirect();
		this.shortLinkLogin({ shortLink: this.$route.query.token });
		this.paramsId.cooperationPresidentId =
			this.getUserInfo.userVo &&
			this.getUserInfo.userVo.cooperationPresidentId;
		// this.getItemByType();
		// this.triggerGetClientInfo();
	},
	mounted() {
		document.body.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		document.body.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		indexMethod(index) {
			return index + 1;
		},
		getSummaries1(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计';
					return;
				}
				if (index === 1) {
					sums[index] = this.formatCurrency(this.swinfo.near_3years_tax_amountresult[this.swinfo.near_3years_tax_amount[this.swinfo.near_3years_tax_amount.length
						- 1].year] / 100)
					return;
				}
				if (index === 2) {
					sums[index] = this.formatCurrency(this.swinfo.near_3years_tax_amountresult[this.swinfo.near_3years_tax_amount[this.swinfo.near_3years_tax_amount.length
						- 1].year - 1] / 100)
					return;
				}
				if (index === 3) {
					sums[index] = this.formatCurrency(this.swinfo.near_3years_tax_amountresult[this.swinfo.near_3years_tax_amount[this.swinfo.near_3years_tax_amount.length
						- 1].year - 2] / 100)
					return;
				}
			});

			return sums;
		},
		getSummaries2(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计';
					return;
				}
				if (index === 1) {
					sums[index] = this.formatCurrency(this.fpinfo.near_3years_invoiceresult[this.fpinfo.near_3years_invoice[this.fpinfo.near_3years_invoice.length -
						1].year]['money_count'] / 100)
					return;
				}
				if (index === 2) {
					sums[index] = this.formatCurrency(this.fpinfo.near_3years_invoiceresult[this.fpinfo.near_3years_invoice[this.fpinfo.near_3years_invoice.length -
						1].year - 1]['money_count'] / 100)
					return;
				}
				if (index === 3) {
					sums[index] = this.formatCurrency(this.fpinfo.near_3years_invoiceresult[this.fpinfo.near_3years_invoice[this.fpinfo.near_3years_invoice.length -
						1].year - 2]['money_count'] / 100)
					return;
				}
			});

			return sums;
		},
		getSummaries3(param) {
			const { columns, data } = param;
			console.log(data)

			const sums = [];
			columns.forEach((column, index) => {

				if (index === 0) {
					sums[index] = '合计';
					return;
				}

				if (index === 2) {
					let count = 0
					data.forEach(item => {
						count += (item.amount * 1000)
					})
					sums[index] = this.formatCurrency((count / 1000).toFixed(2));
					return;
				}
				if (index === 3) {
					let count = 0
					data.forEach(item => {
						count += (item.amt_proportion * 1000)
					})
					sums[index] = (count / 1000).toFixed(2);
					return;
				}
			});

			return sums;
		},
		async getVfqClinetDatafn(companyCode) {
			const res = await getVfqClinetDatas({ companyCode })
			res.rtnDataObj.forEach(el => {
				if (el.interfaceType == 6) {
					let repData = JSON.parse(el.repData);
					console.log(repData, '------------------------')
					if (repData.code == 200) {
						if (repData.data.near_3years_tax_amount && repData.data.near_3years_tax_amount.length) {
							let yearMinusThree = repData.data.near_3years_tax_amount[repData.data.near_3years_tax_amount.length - 1].year - 3;

							repData.data.near_3years_tax_amount.forEach((item, index) => {
								if (item.year <= yearMinusThree) {
									delete repData.data.near_3years_tax_amount[index]
								}
							})

							repData.data.near_3years_tax_amount1 = repData.data.near_3years_tax_amount.reduce((acc, curr) => {
								const existingObj = acc.find(obj => obj.month == curr.month);
								if (existingObj) {
									existingObj.money_count.push(curr.money_count);
								} else {
									acc.push({
										month: curr.month,
										money_count: [curr.money_count]
									});
								}
								return acc;
							}, []);


							repData.data.near_3years_tax_amountresult = repData.data.near_3years_tax_amount.reduce((acc, curr) => {
								const year = curr.year;
								const moneyCount = curr.money_count;
								if (year in acc) {
									acc[year] += moneyCount * 100;
								} else {
									acc[year] = moneyCount * 100;
								}
								return acc;
							}, {});

						}

						if (repData.data.overdue_fine.length) {
							repData.data.overdue_fine.sort(function (a, b) {
								var date1 = new Date(a.tax_time.split('--')[0]);
								var date2 = new Date(b.tax_time.split('--')[0]);
								return date2 - date1;
							});
						}


						this.swinfo = repData.data
						this.taxAccountingData = el.taxAccountingData ? JSON.parse(el.taxAccountingData) : ''
						console.log(this.taxAccountingData);
					}
				}
				if (el.interfaceType == 7) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {



						if (repData.data.near_3years_invoice && repData.data.near_3years_invoice.length) {
							repData.data.near_3years_invoice1 = repData.data.near_3years_invoice.reduce((acc, curr) => {
								const existingObj = acc.find(obj => obj.month == curr.month);
								if (existingObj) {
									existingObj.money_count.push(curr.money_count);
									existingObj.invldfp_amt.push(curr.invldfp_amt);
									existingObj.quantity_count.push(curr.quantity_count);
									existingObj.hcfp_amt.push(curr.hcfp_amt);
								} else {
									acc.push({
										month: curr.month,
										money_count: [curr.money_count],
										hcfp_amt: [curr.hcfp_amt],
										quantity_count: [curr.quantity_count],
										invldfp_amt: [curr.invldfp_amt]
									});
								}
								return acc;
							}, []);

							repData.data.near_3years_invoiceresult = repData.data.near_3years_invoice.reduce((acc, curr) => {
								const year = curr.year;
								const moneyCount = curr.money_count;
								const quantityCount = curr.quantity_count;
								const invldfpAmt = curr.invldfp_amt;
								const hcfpAmt = curr.hcfp_amt;

								if (year in acc) {
									acc[year].money_count += moneyCount * 100;
									acc[year].quantity_count += quantityCount * 100;
									acc[year].invldfp_amt += invldfpAmt * 100;
									acc[year].hcfp_amt += hcfpAmt * 100;
								} else {
									acc[year] = {
										money_count: moneyCount * 100,
										quantity_count: quantityCount * 100,
										invldfp_amt: invldfpAmt * 100,
										hcfp_amt: hcfpAmt * 100
									};
								}

								return acc;
							}, {});



						}




						this.fpinfo = repData.data

					}

				}
				if (el.interfaceType == 9) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.sfinfo = repData.data

					}

				}
				if (el.interfaceType == 20) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.gsinfo = repData.data

					}
				}
				if (el.interfaceType == 40) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.dtinfo = repData.data

					}
				}
				if (el.interfaceType == 11) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.sfsfinfo = repData.data

					}
				}
			});
		},
		async getgetVfqClinetData(creditCode) {
			const res = await getVfqClinetData({
				companyCode: creditCode
			})
			if (res.returncode == 0) {
				this.isswbg = res.rtnDataObj
				if (res.rtnDataObj) {
					this.getVfqClinetDatafn(creditCode)
				}
			}
		},
		textcolor(texts) {
			let text = this.fn(texts || '')
			const formattedText = text.replace(/(股东名称|出资额|出资比例):([^、\s]+)/g, (match, p1, p2) => {
				let colorClass = "";
				if (p1 === "股东名称") {
					colorClass = "blue-text";
				} else if (p1 === "出资额" || p1 === "出资比例") {
					colorClass = "red-text";
				}
				return `${p1}:<span class="${colorClass}">${p2}</span>`;
			});
			return formattedText
		},
		showContent(val) {
			this.$confirm(val, '详情', {
				showConfirmButton: false,
				showCancelButton: false,
				center: true,
				customClass: this.mobileInfo ? 'message-logout' : ''
			})
		},
		lsContent(data, index = '') {
			this['sfinfoobj' + index] = data
			this['minfo' + index] = true;

		},
		scrollToPosition(index) {
			this.navitemindex = index
			document.getElementsByClassName('roll')[index].scrollIntoView()
			if (this.mobileInfo) {
				window.scrollBy(0, -document.getElementById("inner").scrollHeight);

			}

		},
		sfqFn() {
			const myElement = this.$refs.myElement;
			const height = myElement.offsetHeight;
			if (this.zkinfo) {
				myElement.style.top = -height + 'px';
			} else {
				myElement.style.top = '0px';

			}
			this.zkinfo = !this.zkinfo
		},
		setnavList() {
			let arr = []
			document.getElementsByClassName('roll').forEach(item => {
				arr.push(item.innerText)
			})
			this.navList = arr
		},
		handleSelect(res, items) {
			let i = this.optionsCopy.filter(item => item.cooperationPresidentId == res)[0]
			let is = this.branchListCopy.filter(item => item.shortName == i.shortName)[0]
			items.cooperationPresidentName = i.cooperationPresidentName;
			items.shortName = is.shortName;
			items.financialOrganizationsId = is.financialOrganizationsId;

		},
		handleSelect1(res, items) {
			let is = this.branchListCopy.filter(item => item.financialOrganizationsId == res)[0]
			items.shortName = is.shortName;
		},
		addisd() {
			this.ruleForm.intentionSchemeDescription.push({
				cooperationPresidentId: '',
				cooperationPresidentName: '',
				financialOrganizationsId: '',
				shortName: '',
				planOverview: '',
			})
		},
		delids(items, indexs) {
			this.ruleForm.intentionSchemeDescription.splice(indexs, 1);
		},
		async getFinancialOrganizetionList() {
			const res = await financialOrganizetionList({
				pageSize: 999,
				pageNo: 1,
				sortFlag: 'recommendedCustomersNumber-desc、organizationType-asc'
			});
			if (res.returncode === 0) {
				this.branchList = res.list;
				this.branchListCopy = res.list;
			}
		},
		async getTranslateTheNanagerRecords(data) {
			const res = await translateTheNanagerRecords(data);
			if (res.list.length) {

				res.list.forEach(item => {
					item.intentionSchemeDescription = JSON.parse(item.intentionSchemeDescription);
					item.creditAccess = this.fn(item.creditAccess);
					item.neededUnderstandingProblem = this.fn(item.neededUnderstandingProblem);
				})
				this.replyList = res.list
			}

		},
		async getNotRecommendPresidentList4RequirementFN() {
			const res = await getNotRecommendPresidentList4Requirement({
				presidentStatus: 1,
				pageNo: 1,
				pageSize: 999,
				sortFlag: 'recommendedCustomersNumber-desc'
			});
			this.options = res.list;
			this.optionsCopy = res.list;
		},
		dataFilter(val) {
			this.value = String(val);
			if (val) {
				//val存在
				this.options = this.optionsCopy.filter(
					(item) =>
						String(item.cooperationPresidentName).includes(val) ||
						String(item.networkSimpleName).includes(val) ||
						String(item.shortName).includes(val)
				);
			} else {
				//val为空时，还原数组
				this.options = this.optionsCopy;
			}
		},
		dataFilters(val) {
			this.value = String(val);
			if (val) {
				//val存在
				this.branchList = this.branchListCopy.filter(
					(item) =>
						String(item.selection).includes(val) ||
						String(item.organizationTypeName).includes(val)
				);
			} else {
				//val为空时，还原数组
				this.branchList = this.branchListCopy;
			}
		},
		async getPublicFieldPermissionVerification(id) {
			const res = await publicFieldPermissionVerification({
				dictionaryCode: 'YeroyeInternalPresident',
				dataStr: id,
			});
			this.showTransfer = res.message;
		},
		getSpanArr(data) {
			this.mergeArr.forEach((key, index1) => {
				let count = 0; // 用来记录需要合并行的起始位置
				this.mergeObj[key] = []; // 记录每一列的合并信息
				data.forEach((item, index) => {
					// index == 0表示数据为第一行，直接 push 一个 1
					if (index === 0) {
						this.mergeObj[key].push(1);
					} else {
						// 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
						if (item[key] === data[index - 1][key]) {
							this.mergeObj[key][count] += 1;
							this.mergeObj[key].push(0);
						} else {
							// 如果当前行和上一行其值不相等
							count = index; // 记录当前位置
							this.mergeObj[key].push(1); // 重新push 一个 1
						}
					}
				});
			});
		},
		getSpanArr1(data) {
			this.mergeArr.forEach((key, index1) => {
				let count = 0; // 用来记录需要合并行的起始位置
				this.mergeObj1[key] = []; // 记录每一列的合并信息
				data.forEach((item, index) => {
					// index == 0表示数据为第一行，直接 push 一个 1
					if (index === 0) {
						this.mergeObj1[key].push(1);
					} else {
						// 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
						if (item[key] === data[index - 1][key]) {
							this.mergeObj1[key][count] += 1;
							this.mergeObj1[key].push(0);
						} else {
							// 如果当前行和上一行其值不相等
							count = index; // 记录当前位置
							this.mergeObj1[key].push(1); // 重新push 一个 1
						}
					}
				});
			});
		},
		// 默认接受四个值 { 当前行的值, 当前列的值, 行的下标, 列的下标 }
		objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
			// 判断列的属性
			if (this.mergeArr.indexOf(column.property) !== -1) {
				// 判断其值是不是为0
				if (this.mergeObj[column.property][rowIndex]) {
					return [this.mergeObj[column.property][rowIndex], 1];
				} else {
					// 如果为0则为需要合并的行
					return [0, 0];
				}
			}
		},
		objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
			// 判断列的属性
			if (this.mergeArr.indexOf(column.property) !== -1) {
				// 判断其值是不是为0
				if (this.mergeObj1[column.property][rowIndex]) {
					return [this.mergeObj1[column.property][rowIndex], 1];
				} else {
					// 如果为0则为需要合并的行
					return [0, 0];
				}
			}
		},
		browserRedirect() {
			var curURL = window.location.href;
			var sUserAgent = navigator.userAgent.toLowerCase();

			var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
			var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
			var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
			var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
			var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
			var bIsAndroid = sUserAgent.match(/android/i) == 'android';
			var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
			var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';

			if (
				bIsIpad ||
				bIsIphoneOs ||
				bIsMidp ||
				bIsUc7 ||
				bIsUc ||
				bIsAndroid ||
				bIsCE ||
				bIsWM
			) {
				this.mobileInfo = true;
			} else {
				this.mobileInfo = false;
			}
		},
		transferEvent() {
			if (this.mobileInfo) {
				this.$router.push(
					`/transferForm?requirementId=${this.allData.financeRequirementList[0].requirementId
					}&checkedTitleField=${this.allData.checkedTitleField
					}&recommendReason=${this.allData.financeRequirementList[0]
						.recommendReason || ''
					}&financeRequirementList=${JSON.stringify(
						this.allData.financeRequirementList[0]
					)}`
				);
			} else {
				this.transferDialogVisible = true;
			}
		},

		isEmpty(str) {
			if (str === null || typeof str === 'undefined' || str === '') {
				return false;
			}
			return true;
		},
		handleScroll() {
			if (document.querySelector('.feedbackDom')) {
				this.isGoFeedback =
					document
						.querySelector('.feedbackDom')
						.getBoundingClientRect().top < window.screen.height
						? false
						: true;

				// const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				// this.isSticky = scrollTop > 180
			}
		},
		goFeedback() {
			document
				.querySelector('.feedbackDom')
				.scrollIntoView({ behavior: 'smooth' });
		},
		async changeProtocolIdentification() {
			await changeProtocolIdentification();
		},
		flexColumnWidth(arr1, name, maxWidth, titeleWidth) {
			// name为该列的字段名(传字符串);arr1为该表格的数据源(传变量);
			let columnContent = "";
			if (!arr1 || !arr1.length || arr1.length === 0 || arr1 === undefined) {
				return titeleWidth + "px"; // 给个默认的
			}

			// 获取该列中最长的数据(内容)
			let index = 0;
			for (let i = 0; i < arr1.length; i++) {
				if (arr1[i][name] === null) {
					return;
				}
				if (arr1[i][name] === undefined) {
					//字段没有时默认数据为空
					arr1[i][name] = "";
				}
				const now_temp = arr1[i][name] + "";
				const max_temp = arr1[index][name] + "";
				if (now_temp.length > max_temp.length) {
					index = i;
				}
			}
			columnContent = arr1[index][name];
			// console.log('该列数据[i]:', columnContent)
			// 以下分配的单位长度可根据实际需求进行调整
			let flexWidth = 0;
			for (const char of columnContent) {
				if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
					// 如果是英文字符，为字符分配8个单位宽度
					flexWidth += 8;
				} else if (char >= "\u4e00" && char <= "\u9fa5") {
					// 如果是中文字符，为字符分配15个单位宽度
					flexWidth += 16;
				} else {
					// 其他种类字符，为字符分配8个单位宽度
					flexWidth += 10;
				}
			}
			// if (flexWidth < 80) {
			// 	// 设置最小宽度
			// 	flexWidth = 80;
			// }
			// if (flexWidth > 250) {
			//   // 设置最大宽度
			//   flexWidth = 250
			// }
			//留边距
			flexWidth += 20;
			if (flexWidth == 20) {
				flexWidth = titeleWidth
			}
			if (flexWidth > maxWidth) {
				flexWidth = maxWidth
			}
			if (flexWidth < titeleWidth) {
				flexWidth = titeleWidth
			}
			return flexWidth;
		},
		handelOnChange(res) {
			if (res.filePath) {
				res.url = res.filePath
			}
			if (res.url.includes('png') || res.url.includes('jpg') || res.url.includes('bmp') || res.url.includes('jpeg') || res.url.includes('xlsx') || res.url.includes('docx') || res.url.includes('pdf')) {

				let routeData = this.$router.resolve({ path: '/public', query: { url: res.url } });
				// console.log(this.$baseUrl, routeData.href)
				// this.flowSrc = location.href.split('#')[0] + routeData.href
				window.open(routeData.href, '_blank');
				return;
			}

			location.href =
				this.$baseUrl +
				'api/file/fileDownloadCommon.do?path=' +
				res.url;
		},
		querySearch(queryString, cb) {
			cb(this.restaurants);
		},
		querySearch1(queryString, cb) {
			cb(this.RepaymentMethod);
		},
		async getFinancialProductList() {
			const res = await financialProductList({
				financialOrganizationsId:
					this.getUserInfo.financialOrganizationsId || -1,
				pageSize: 9999,
				pageNo: 1,
			});
			this.restaurants = res.list.map((item) => ({
				value: item.financialProductName,
			}));
		},
		async getReadCooperationFeedback(data) {
			const res = await readCooperationFeedback(data);
			this.getTranslateTheNanagerRecords({ requirementId: res.requirementId })

			res.cooperateWillingness = res.cooperateWillingness
				? res.cooperateWillingness + ''
				: '';
			res.compliesCreditProductPolicy = res.compliesCreditProductPolicy
				? res.compliesCreditProductPolicy + ''
				: '';
			res.interestedProvidingFinanceSolutions =
				res.interestedProvidingFinanceSolutions
					? res.interestedProvidingFinanceSolutions + ''
					: '';
			res.interestedProvidingFinanceSolutions =
				res.interestedProvidingFinanceSolutions
					? res.interestedProvidingFinanceSolutions + ''
					: '';
			if (res.intentionSchemeDescription) {
				res.intentionSchemeDescription = JSON.parse(res.intentionSchemeDescription)
			} else {
				res.intentionSchemeDescription = [
					{
						cooperationPresidentId: '',
						cooperationPresidentName: '',
						financialOrganizationsId: '',
						shortName: '',
						planOverview: '',
					}
				]
			}
			this.ruleForm = res;
		},
		async getItemByType(name, b = false) {
			const res = await itemByType({
				dictionaryType: name,
			});




			if (b) {
				this[name] = res.data.map((item) => ({
					value: Number(item.itemValue),
					label: item.itemName,
				}));
			} else {
				this[name] = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
			if (name == 'MasterProgramManagerIntention') {


				this[name] = this[name].filter(item => item.value != 3 && item.value != 4)
			}

		},
		async getItemByType1(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			this[name] = res.data.map((item) => ({
				value: item.itemName,
			}));
		},
		async shortLinkLogin(data) {
			const res = await shortLinkLogin(data);
			if (res.rtnCode === 0) {
				this.getNotRecommendPresidentList4RequirementFN()
				this.getFinancialOrganizetionList()

				this.paramsId.clientId = res.clientId;
				this.paramsId.clientPresidentRelatedId =
					res.clientPresidentRelatedId;
				localStorage.setItem('userInfo', JSON.stringify(res.tokenVO));
				this.$store.commit('setUserInfo', res.tokenVO);
				this.getItemByType('PresidentCooperateWillingness');
				this.getItemByType('WhetherExistsFinancingPlan', true);
				this.getItemByType('InterestedProvidingFinanceSolutions');
				this.getItemByType('MasterProgramManagerIntention', true);
				this.getItemByType('CompliesCreditProductPolicy');
				this.getItemByType1('RepaymentMethod');

				this.centerDialogVisible = res.tokenVO.userVo
					.protocolIdentification
					? false
					: true;

				const type = await getClientInfoSensitive({
					cooperationPresidentId:
						res.tokenVO.userVo.cooperationPresidentId,
					clientId: res.clientId,
					clientPresidentRelatedId: res.clientPresidentRelatedId,
				});

				this.getPublicFieldPermissionVerification(
					res.tokenVO.userVo.cooperationPresidentId
				);
				this.privacyType = type;

				this.getReadCooperationFeedback({
					clientPresidentRelatedId: res.clientPresidentRelatedId,
				});
				this.getFinancialProductList();
				let tepRes = {};

				if (type == 1) {
					tepRes = await getSensitiveClientInfo(this.paramsId);
				} else if (type == 2) {
					tepRes = await getClientInfo(this.paramsId);
				}
				if (tepRes.clientBase.creditCode) {
					this.getgetVfqClinetData(tepRes.clientBase.creditCode)

				}

				const personInfo = await readUserInfoOfClient({
					token: res.tokenVO.token,
					userId: tepRes.clientPresidentRelatedVO.addUserId,
				});
				this.personInfo = personInfo;
				if (tepRes.returncode === 1) {
					this.msg = tepRes.message;
					this.rtnCode = tepRes.returncode;
				}
				if (tepRes.clientBase.capitalizeOthersAttachment) {
					tepRes.clientBase.capitalizeOthersAttachment =
						JSON.parse(
							tepRes.clientBase.capitalizeOthersAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientBase.businessInformationAttachment) {
					tepRes.clientBase.businessInformationAttachment =
						JSON.parse(
							tepRes.clientBase.businessInformationAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientBase.capitalizeEnterpriseCreditAttachment) {
					tepRes.clientBase.capitalizeEnterpriseCreditAttachment =
						JSON.parse(
							tepRes.clientBase.capitalizeEnterpriseCreditAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientBase.historyCreditInfoAttachment) {
					tepRes.clientBase.historyCreditInfoAttachment =
						JSON.parse(
							tepRes.clientBase.historyCreditInfoAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.personsList.length) {
					tepRes.personsList.forEach((element) => {
						if (element.controlPersonAttachment) {
							element.controlPersonAttachment = JSON.parse(
								element.controlPersonAttachment
							).map((item) => ({
								name: item.fileName,
								url: item.filePath,
							}));
						}
						if (element.personalCreditAttachment) {
							element.personalCreditAttachment = JSON.parse(
								element.personalCreditAttachment
							).map((item) => ({
								name: item.fileName,
								url: item.filePath,
							}));
						}
						if (element.otherAttachment) {
							element.otherAttachment = JSON.parse(
								element.otherAttachment
							).map((item) => ({
								name: item.fileName,
								url: item.filePath,
							}));
						}

					});
				}
				if (tepRes.clientBase.capitalizeAttachment) {
					tepRes.clientBase.capitalizeAttachment = JSON.parse(
						tepRes.clientBase.capitalizeAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.clientBase.assetListAttachment) {
					tepRes.clientBase.assetListAttachment = JSON.parse(
						tepRes.clientBase.assetListAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.clientBase.assetOtherAttachment) {
					tepRes.clientBase.assetOtherAttachment = JSON.parse(
						tepRes.clientBase.assetOtherAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.personalAssetsList.length) {
					tepRes.personalAssetsList.forEach((element) => {
						if (element.assetAttachment) {
							element.assetAttachment = JSON.parse(
								element.assetAttachment
							).map((item) => ({
								name: item.fileName,
								url: item.filePath,
							}));
						}
					});
				}
				if (tepRes.capitalizesList.length) {
					tepRes.capitalizesList.forEach((element) => {
						if (element.guaranteeAttachment) {
							element.guaranteeAttachment = JSON.parse(
								element.guaranteeAttachment
							).map((item) => ({
								name: item.fileName,
								url: item.filePath,
							}));
						}
					});
				}

				if (tepRes.clientFinanceVO.balanceSheetAttachment) {
					tepRes.clientFinanceVO.balanceSheetAttachment = JSON.parse(
						tepRes.clientFinanceVO.balanceSheetAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.clientFinanceVO.cashFlowStatementAttachment) {
					tepRes.clientFinanceVO.cashFlowStatementAttachment =
						JSON.parse(
							tepRes.clientFinanceVO.cashFlowStatementAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientFinanceVO.internalElseAttachment) {
					tepRes.clientFinanceVO.internalElseAttachment =
						JSON.parse(
							tepRes.clientFinanceVO.internalElseAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientFinanceVO.taxElseAttachment) {
					tepRes.clientFinanceVO.taxElseAttachment =
						JSON.parse(
							tepRes.clientFinanceVO.taxElseAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientFinanceVO.incomeStatementAttachment) {
					tepRes.clientFinanceVO.incomeStatementAttachment =
						JSON.parse(
							tepRes.clientFinanceVO.incomeStatementAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientFinanceVO.taxPaymentCertificateAttachment) {
					tepRes.clientFinanceVO.taxPaymentCertificateAttachment =
						JSON.parse(
							tepRes.clientFinanceVO
								.taxPaymentCertificateAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.clientFinanceVO.taxReturnsAttachment) {
					tepRes.clientFinanceVO.taxReturnsAttachment = JSON.parse(
						tepRes.clientFinanceVO.taxReturnsAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.bankStatement && tepRes.bankStatement.bankFlowAttachment) {
					tepRes.bankStatement.bankFlowAttachment = JSON.parse(
						tepRes.bankStatement.bankFlowAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.financeRequirementList.length) {
					tepRes.financeRequirementList.forEach((element) => {
						if (element.reqRelevantAttachment) {
							element.reqRelevantAttachment = JSON.parse(
								element.reqRelevantAttachment
							).map((item) => ({
								name: item.fileName,
								url: item.filePath,
							}));
						}
						// console.log(element.assetAttachment)
						// if (element.assetAttachment) {
						// 	element.assetAttachment = JSON.parse(
						// 		element.assetAttachment
						// 	).map((item) => ({
						// 		name: item.fileName,
						// 		url: item.filePath,
						// 	}));
						// }
					});
				}

				if (tepRes.otherKeyInfo.taxPaymentCertificateAttachment) {
					tepRes.otherKeyInfo.taxPaymentCertificateAttachment =
						JSON.parse(
							tepRes.otherKeyInfo.taxPaymentCertificateAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.otherKeyInfo.keyInfoAttachment) {
					tepRes.otherKeyInfo.keyInfoAttachment =
						JSON.parse(
							tepRes.otherKeyInfo.keyInfoAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
				}
				if (tepRes.otherKeyInfo.vatReturnAttachment) {
					tepRes.otherKeyInfo.vatReturnAttachment = JSON.parse(
						tepRes.otherKeyInfo.vatReturnAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.otherKeyInfo.incomeTaxReturnAttachment) {
					tepRes.otherKeyInfo.incomeTaxReturnAttachment = JSON.parse(
						tepRes.otherKeyInfo.incomeTaxReturnAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.otherKeyInfo.otherAttachment) {
					tepRes.otherKeyInfo.otherAttachment = JSON.parse(
						tepRes.otherKeyInfo.otherAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				}
				if (tepRes.riskScreeningInformationList.length) {
					tepRes.riskScreeningInformationList.forEach((item) => {
						if (!item.tryingToStudy) {
							item.tryingToStudy = '--';
						}
					});
				}
				if (tepRes.checkedTitleField) {
					tepRes.checkedTitleField =
						tepRes.checkedTitleField.split(',');
				} else {
					tepRes.checkedTitleField = [
						'financeRequirement',
						'clientBase',
						'clientBase_businessInfo',
						'clientBase_historyLitigation',
						'clientBase_shareholder',
						'clientBase_historicalCredit',
						'clientBase_cooperationBank',
						'clientKeyInfo',
						'controlPerson',
						'clientPersonalAssets',
						'businessCase',
						'businessCase_businessArea',
						'businessCase_businessDownstream',
						'businessCase_businessUpstream',
						'businessCase_mainSupplier',
						'businessCase_downClient',
						'businessCase_productIntroduction',
						'businessCase_description',
						'businessCase_coreCompetitivenessVista',
						'businessCase_shortBoardProblems',
						'clientFinance',
						'clientFinance_subject',
						'clientFinance_quota',
						'clientFinance_analyse',
						'taxInfo',
						'bankFlow',
						'bankFlow_flowInfo',
						'bankFlow_topTen',
						'clientCapitalize',
						'capitalizeEnterpriseCreditAttachment_attachment',
						'controlPersonAttachment_attachment',
						'personalCreditAttachment_attachment',
						'otherAttachment_attachment'
					];
				}
				this.allData = tepRes;
				this.getClientBankFlowList();
			} else {
				this.msg = res.rtnErrorMsg;
				this.rtnCode = res.rtnCode;
			}
		},

		formatCurrency(num, b = false) {
			if (num === 0) {
				return '0.00';
			}
			if (num) {
				if (typeof num != 'number' && b) return num;
				// 将num中的$,去掉，将num变成一个纯粹的数据格式字符串
				num = num.toString().replace(/\$|\,/g, '');
				// 如果num不是数字，则将num置0，并返回
				if ('' == num || isNaN(num)) {
					return 'Not a Number ! ';
				}
				// 如果num是负数，则获取她的符号
				var sign = '';
				if (num.indexOf('-') === 0) {
					sign = '-';
					num = num.substring(1);
				}
				// 如果存在小数点，则获取数字的小数部分
				var cents = num.indexOf('.') > 0 ? num.substr(num.indexOf('.')) : '';
				cents = cents.length > 1 ? cents : '.00';
				// 获取数字的整数数部分
				num = num.indexOf('.') > 0 ? num.substring(0, num.indexOf('.')) : num;
				// 针对整数部分进行格式化处理
				for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
					num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
				}
				// 将数据（符号、整数部分、小数部分）整体组合返回
				return sign + num + cents;
			}
		},
		hideIndex(data) {
			if (JSON.parse(data).length > 1) {
				return true;
			} else {
				return false;
			}
		},
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		open() {
			this.getReadCooperationFeedback({
				clientPresidentRelatedId:
					this.paramsId.clientPresidentRelatedId,
			});
		},
		async getClientBankFlowList() {
			const res = await clientBankFlowList(this.paramsId);
			this.isShowRunning = res;
		},
		async getReadCooperationFeedback(data) {
			const res = await readCooperationFeedback(data);
			this.getTranslateTheNanagerRecords({ requirementId: res.requirementId })

			if (res.returncode === 1) {
				this.msg = res.message;
				this.rtnCode = res.returncode;
			}
			res.cooperateWillingness = res.cooperateWillingness
				? res.cooperateWillingness + ''
				: '';
			res.compliesCreditProductPolicy = res.compliesCreditProductPolicy
				? res.compliesCreditProductPolicy + ''
				: '';
			res.interestedProvidingFinanceSolutions =
				res.interestedProvidingFinanceSolutions
					? res.interestedProvidingFinanceSolutions + ''
					: '';
			if (res.intentionSchemeDescription) {
				res.intentionSchemeDescription = JSON.parse(res.intentionSchemeDescription)
			} else {
				res.intentionSchemeDescription = [
					{
						cooperationPresidentId: '',
						cooperationPresidentName: '',
						financialOrganizationsId: '',
						shortName: '',
						planOverview: '',
					}
				]
			}
			this.ruleForm = res;
		},
		arraySpanMethod({ row, column, rowIndex, columnIndex }) {
			if (
				rowIndex == 1 ||
				rowIndex == 21 ||
				rowIndex == 25 ||
				rowIndex == 29
			) {
				return {
					rowspan: 1,
					colspan: this.tableData.length + 1,
				};
			}
		},
		arraySpanMethod1({ row, column, rowIndex, columnIndex }) {
			// console.log(row, column, rowIndex, columnIndex);
			if (rowIndex == 1 || rowIndex == 6 || rowIndex == 9) {
				return {
					rowspan: 1,
					colspan: this.tableData.length + 1,
				};
			}
		},
		isRed1({ row, rowIndex }) {
			if (rowIndex === 0) {
				return {
					'background-color': '#ecf5ff !important',
					position: 'sticky',
					top: '0',
					'z-index': '1',
				};
			}
			// if (
			// 	rowIndex == 1 ||
			// 	rowIndex == 21 ||
			// 	rowIndex == 25 ||
			// 	rowIndex == 29
			// ) {
			// 	return {
			// 		'font-weight': ' 800',
			// 	};
			// }
		},
		isRed2({ row, rowIndex }) {
			if (rowIndex === 0) {
				return {
					'background-color': '#ecf5ff !important',
					position: 'sticky',
					top: '0',
					'z-index': '1',
				};
			}
			if (rowIndex == 1 || rowIndex == 6 || rowIndex == 9) {
				return {
					'font-weight': ' 800',
				};
			}
		},
		postCooperationFeedbackSave() {
			this.$refs['MyruleForm'].validate(async (valid) => {
				if (valid) {
					this.ruleForm.clientPresidentRelatedId =
						this.paramsId.clientPresidentRelatedId;


					let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))


					if (ruleForm.whetherExistsFinancingPlan != 1) {
						ruleForm.intentionSchemeDescription = [{
							cooperationPresidentId: '',
							cooperationPresidentName: '',
							financialOrganizationsId: '',
							shortName: '',
							planOverview: '',
						}]
					} else {
						ruleForm.intentionSchemeDescription = ruleForm.intentionSchemeDescription.filter(item => (item.financialOrganizationsId || item.cooperationPresidentId || item.planOverview))
						if (!ruleForm.intentionSchemeDescription.length) return this.$message({
							message: '方案概述不能为空',
							type: 'error',
						});
					}

					ruleForm.intentionSchemeDescription = JSON.stringify(ruleForm.intentionSchemeDescription)
					const res = await cooperationFeedbackSave(ruleForm);
					if (this.mobileInfo && res.returncode == 0) {
						wx.miniProgram.navigateTo({ url: '/pages/companyres/companyres?flag=' + 2 + '&index=' + 4 })

						this.$message({
							message: '提交成功',
							type: 'success',
						});
						setTimeout(() => {
							window.close()
						}, 1500)
						return;
					}

					if (this.mobileInfo && res.returncode != 0)
						return this.$message.error(res.message);
					if (res.returncode == 0) {

						this.$confirm('提交成功', '提示', {
							confirmButtonText: '确定',
							type: 'success',
							showCancelButton: false,
						}).then(_ => {
							console.log(1);
							window.close()
						})
							.catch(_ => {
								console.log(1);

								window.close()
							})
						this.$emit('closeVisible');

					} else {
						this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							type: 'error',
							showCancelButton: false,
						});
					}
				}
			});
		},
	},
	computed: {
		...mapGetters(['getUserInfo']),
		getHeaders() {
			const subjects = JSON.parse(JSON.stringify(this.allData.subjects));
			while (subjects.length < 4) {
				//2、判断循环条件;
				subjects.push({});
			}
			return subjects.reduce(
				(pre, cur, index) => pre.concat(`value${index}`),
				['title']
			);
		},
		allMoney() {
			if (
				this.allData.capitalizesList &&
				this.allData.capitalizesList.length
			) {
				let capitalizeAmount = 0;
				let loanBalance = 0;
				this.allData.capitalizesList.forEach((element) => {
					capitalizeAmount =
						capitalizeAmount + element.capitalizeAmount * 1000;
					loanBalance = loanBalance + element.loanBalance * 1000;
				});
				return `(融资总额：${capitalizeAmount / 1000}万元，贷款余额
							：${loanBalance / 1000}万元）`;
			} else {
				return ``;
			}
		},
		getValues() {
			return this.headers.map((item) => {
				return this.allData.balanceSheetList.reduce(
					(pre, cur, index) =>
						Object.assign(pre, {
							['value' + index]: this.formatCurrency(
								cur[item.prop],
								true
							),
						}),
					{ title: item.label }
				);
			});
		},
		getIncome() {
			return this.income.map((item) => {
				return this.allData.profitSheetList.reduce(
					(pre, cur, index) =>
						Object.assign(pre, {
							['value' + index]: this.formatCurrency(
								cur[item.prop],
								true
							),
						}),
					{ title: item.label }
				);
			});
		},
		getIndicator() {
			return this.indicator.map((item) => {
				return this.allData.quotas.reduce(
					(pre, cur, index) =>
						Object.assign(pre, {
							['value' + index]: this.formatCurrency(
								cur[item.prop],
								true
							),
						}),
					{ title: item.label }
				);
			});
		},
		getIndicatorHeaders() {
			const quotas = JSON.parse(JSON.stringify(this.allData.quotas));
			while (quotas.length < 4) {
				//2、判断循环条件;
				quotas.push({});
			}
			return quotas.reduce(
				(pre, cur, index) => pre.concat(`value${index}`),
				['title']
			);
		}
	},
	watch: {
		'ruleForm.cooperateWillingness'(val) {
			if (this.$refs['MyruleForm']) {
				this.$refs['MyruleForm'].clearValidate();
			}
			// if ((val = 3)) {
			// 	console.log(val);
			// 	this.$refs['MyruleForm'].resetFields();
			// }
		},
		allData() {
			if (this.allData.financeRequirementList) {
				this.getSpanArr(this.allData.riskScreeningInformationList);
				this.getSpanArr1(
					this.allData.competitivenessScreeningInformationList
				);
				this.$nextTick(() => {

					for (let x = 1; x < 6; x++) {
						let isExistsData = false;
						for (let xy = 1; xy < 10; xy++) {
							if (document.querySelectorAll(`.c-${x}-${xy}-body`).length && !document.querySelectorAll(`.c-${x}-${xy}-body`)[0].innerText) {
								document.querySelectorAll(`.c-${x}-${xy}`)[0].remove()
							} else {
								isExistsData = true;
							}
						}
						if (!isExistsData && document.querySelectorAll(`.c-${x}`).length) {
							document.querySelectorAll(`.c-${x}`)[0].remove()
						}
						if (document.querySelectorAll(`.c-${x}-body`).length && !document.querySelectorAll(`.c-${x}-body`)[0].innerText) {
							document.querySelectorAll(`.c-${x}`)[0].remove()
						}



					}


					if (this.allData.financeRequirementList.length >= 1) {
						this.$nextTick(() => {
							setTimeout(() => {
								if (
									!Array.from(document.querySelectorAll('.t1')).length
								) {
									this.isData = false;
								}
								Array.from(document.querySelectorAll('.t1')).forEach(
									(item, index) => {
										console.log(item)
										console.log('-----------------------000')

										Array.from(
											document.querySelectorAll(`.${item.querySelector('span').className}`)
										).forEach((items, indexs) => {
											if (indexs) {
												items.innerHTML = `${index + 1
													}.${indexs}.`;
											} else {
												items.innerHTML = `${index + 1}.`;
											}
										});
										Array.from(
											document.querySelectorAll(`.${item.querySelector('span').className}-1-1`)
										).forEach((items, indexs) => {
											items.innerHTML = `${index + 1}.1.${indexs + 1}.`;
										});
									}
								);
							}, 1000)
						});
					} else {
						this.$nextTick(() => {
							setTimeout(() => {

								if (
									!Array.from(document.querySelectorAll('.t1')).length
								) {
									this.isData = false;
								}
								Array.from(document.querySelectorAll('.t1')).forEach(
									(item, index) => {
										console.log(item)
										console.log('-----------------------1111')

										Array.from(
											document.querySelectorAll(`.${item.querySelector('span').className}`)
										).forEach((items, indexs) => {
											if (indexs) {
												items.innerHTML = `${index + 1}.${indexs}.`;
											} else {
												items.innerHTML = `${index + 1}.`;
											}
										});
										Array.from(
											document.querySelectorAll(`.${item.querySelector('span').className}-1-1`)
										).forEach((items, indexs) => {
											items.innerHTML = `${index + 1}.1.${indexs + 1}.`;
										});
									}
								);


							}, 1000)

						});
					}

					this.$nextTick(() => {
						this.setnavList()


					});

				});


			}

		},
	},
	components: {
		notice,
		transfer: () => import('@/components/transfer/transfer.vue'),
	},
};
</script>

<style lang="scss" scoped>
.sticky-element {
	padding: 10px;
	background-color: rgb(240, 242, 245);
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	// grid-template-rows: repeat(4, 1fr);
	grid-gap: 5px;
	transition: all 0.4s;
}

::v-deep .tywidth .el-upload-list {
	margin-left: 50px;
}

::v-deep .textinfo .el-form-item__content {
	margin-left: 0 !important;
}

.sticky {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	/* 设置背景颜色，根据你的需求进行修改 */
	z-index: 9;
	/* 可选，用于控制元素在其他元素之上的显示顺序 */
}

@media screen and (max-width: 1200px) {
	.dynamic {
		width: 100% !important;
	}

	.allw {
		width: 100% !important;
	}

	::v-deep .el-table .el-table__cell {
		padding: 5px 0 !important;
	}

	::v-deep .el-table .cell {
		padding: 0 5px !important;
	}

	::v-deep .el-form-item__label {
		padding: 0 6px 0 0 !important;
	}

	.form1 ::v-deep .el-form-item {
		padding-left: 10px !important;
	}


}

.running-info>div {
	width: 100%;
}

.running-info {
	display: flex;
	flex-wrap: wrap;
}

.clientInfo textarea {
	resize: none;
}

.diytable_weight {
	width: 100% !important;
}



@media screen and (min-width:1200px) {
	.mediabox {
		padding: 0 100px;
	}


	.el-card__body {
		padding: 20px !important;
	}

	.composing {
		display: flex;
	}

	.go-feedback {
		display: none;
	}

	.running-info>div {
		width: 34%;
	}

	.dynamic {
		width: 65% !important;
	}

	.propertyinfo .el-form-item {
		float: left;
	}

	.w65 {
		width: 65% !important;
	}

	.propertyinfo {
		overflow: hidden;
	}
}
</style>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
	padding: 0 10px !important;
}

::v-deep .diytable tbody tr[style] td {
	text-align: center !important;
}

.diytable_weight {
	width: 65%;
}

.diytable_box {
	.el-table--border {
		border-top: none;
	}

	.el-table::before {
		width: 0%;
	}

	.el-table--border::after {
		width: 0%;
	}
}

::v-deep .diytable_weight tr td:first-child {
	padding-left: 20px;
}

::v-deep .diytable_weight tr:nth-child(2) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(14) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(15) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(33) td:first-child {
	font-weight: 800;
}

::v-deep .diytable_weight tr:nth-child(34) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(35) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(48) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(49) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(65) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(66) td:first-child {
	font-weight: 800;
	padding-left: 20px;
}

::v-deep .diytable_weight1 tr td:first-child {
	padding-left: 20px;
}

::v-deep .diytable_weight1 tr:nth-child(2) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight1 tr:nth-child(4) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(5) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(6) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(7) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(8) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(10) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(11) td:first-child {
	padding-left: 60px;
}

::v-deep .diytable_weight1 tr:nth-child(12) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .diytable_weight1 tr:nth-child(15) td:first-child {
	padding-left: 60px;
}

::v-deep .diytable_weight1 tr:nth-child(16) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .diytable_weight1 tr:nth-child(18) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .diytable_weight1 tr:nth-child(19) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .add-list>div label {
	opacity: 0;
}

::v-deep .add-list>div:first-child label {
	opacity: 1;
}

.clientInfo {
	width: 100%;
	height: 100%;
	background: #f0f2f5 url(~@/assets/background.svg);

	img {
		margin: 0 auto;
		height: 44px;
		margin-top: 20px;
		margin-bottom: 20px;
		width: 225px;
	}
}

/* reset element-ui css */
.clientInfo-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	background: #f0f2f5 url(~@/assets/background.svg);
}

.clientInfo-text {
	color: #000;
	font-weight: 800;
	cursor: pointer;
	font-size: 16px;
	padding-left: 10px;
	margin-bottom: 15px;
	margin-top: 20px;
}

.box-card {
	margin-bottom: 10px;
}

::v-deep .demo-drawer__footer {
	width: 100%;
	padding-bottom: 70px;
	display: flex;
	justify-content: center;
}

.form1 ::v-deep .el-form-item {
	margin-bottom: 0;
	padding-left: 20px;
}

.t1,
.t2,
.t3 {
	color: #3e31ff;
	font-weight: 800;
	margin: 10px 0;
	width: 100%;
}

.t1 {
	font-size: 20px;
}

.t2 {
	font-size: 18px;
	text-indent: 10px;
}

.t3 {
	font-size: 16px;
	text-indent: 20px;
}

.text-item {
	text-indent: 40px;
	margin: 20px 0;
}

::v-deep .el-input-number__increase {
	display: none;
}

::v-deep .el-input-number__decrease {
	display: none;
}

::v-deep .el-input-number input {
	text-align: left !important;
}

.info {
	line-height: 22px;
	margin: 10px 15px 20px 20px;
	text-indent: 0px;
}

.info1 {
	line-height: 22px;
	margin-top: 10px;
	text-indent: 0px;
}

::v-deep .el-card__body {
	padding: 5px;
}

::v-deep .el-upload-list__item:first-child {
	margin-top: 5px !important;
}

.go-feedback {
	position: fixed;
	width: 50px;
	height: 50px;
	background: #fff;
	border-radius: 50%;
	left: 20px;
	bottom: 20px;
	line-height: 50px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	text-align: center;
	opacity: 0.8;
}

.diytable_box {
	border-right: 1px solid #ebeef5;
}

.el-descriptions {
	margin-bottom: 20px;
}

::v-deep .diytable1 tbody tr:nth-child(2) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(2) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable1 tbody tr:nth-child(22) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(22) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable1 tbody tr:nth-child(26) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(26) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable1 tbody tr:nth-child(30) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(30) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable2 tbody tr:nth-child(2) td {
	opacity: 0 !important;
}

::v-deep .diytable2 tbody tr:nth-child(2) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable2 tbody tr:nth-child(7) td {
	opacity: 0 !important;
}

::v-deep .diytable2 tbody tr:nth-child(7) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable2 tbody tr:nth-child(10) td {
	opacity: 0 !important;
}

::v-deep .diytable2 tbody tr:nth-child(10) td:first-child {
	opacity: 1 !important;
}

::v-deep .el-descriptions th {
	width: 25%;
}

.navitem {
	padding: 6px;
	background: #b8d0fe;
	display: inline-block;
	border-radius: 5px;
	text-align: center;
	height: 26px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
}

.activenavitem {
	color: #4184ff;
	background: #fff;
}

.pcsticky {
	width: 110px;
	cursor: default;
	left: auto;
	right: 0px;
	position: fixed;
	width: 110px;
	top: 20%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	box-sizing: border-box;
	background: #fff;
	z-index: 99;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	padding-top: 10px;
}

.pcsticky div {
	background: #fff;
	position: relative;
	z-index: 99;
	width: 100%;
	display: block;
	font-size: 12px !important;
	color: rgba(0, 0, 0, 0.65);
	text-decoration: none;
	padding: 3px !important;
	margin: 0 !important;
	box-sizing: border-box;
	flex: 1;
	display: flex;
	align-items: center;
	border-left: 1px solid #ece9e9;
	text-indent: 0.5em;
	cursor: pointer;
}

.pcsticky div:hover {
	color: #333;
}

.pcsticky .c {
	color: #1890ff !important;
	border-left: 1px solid #1890ff !important;
}

::v-deep .el-upload-list {
	margin-left: 25px;
}

::v-deep .el-form-item__label {
	color: #7b808bd9 !important;
}

.table-dialog-content {
	padding-top: 2.13333vw;
	padding-right: 4.26667vw;
	padding-bottom: 0;
	padding-left: 4.26667vw;
}

.table-dialog-content .table {
	color: #666;
	overflow: auto;
}

.table-dialog-content .table .tr:first-child {
	border-top-left-radius: 1.33333vw;
	border-top-right-radius: 1.33333vw;
}

.table-dialog-content .table .tr:nth-child(odd) {
	background: #f9faff;
}

.table-dialog-content .table .tr {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: flex-start;
	min-height: 11.73333vw;
}

.table-dialog-content .table .tr .td:first-child {
	width: 28vw;
}

.diy .table .tr .td:first-child {
	width: 22vw;
}

.table-dialog-content .table .tr .td {
	line-height: 6.13333vw;
	padding: 1.06667vw 2.66667vw;
	font-size: 3.2vw;
	word-break: break-all;
}

.table-dialog-content .table .tr .td:last-child {
	flex: 1;
}

::v-deep .diytc .el-dialog__body {
	padding: 0 !important;
}

.table-header {
	text-align: center;
	padding-bottom: 3.2vw;
}

.table-header .table-title {
	font-size: 18px;
	font-weight: 700;
	color: #333;
	padding-top: 2vw;
	padding-left: 4.8vw;
}

.tcinfobox {
	margin-top: -20px;
	padding: 0 10px 40px 10px;
}

.tcinfobox .el-table {
	margin: 0 auto;
}
</style>
